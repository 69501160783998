
import ThickCaretDown from "../icons/thickCaretDown";
import React from "react";
import toggleFooterAccordion from "../../functions/toggleFooterAccordion";

const Accordions = (props) => {

  return (
    <div className="accordions-container">
      <div className="accordions-main-content">

        <a href="#" className='toggle-menu nav-title' onClick={toggleFooterAccordion} aria-label="expand filter set"><h4>Books & More</h4><ThickCaretDown width="15"></ThickCaretDown></a>
        <div className="menu closed">
          <div>
            <ul className="btn-toggle-nav sub-menu">
              <li className="sub-item">
                <a href="https://discover.bklynlibrary.org/" >Books, eBooks &amp; AudioBooks</a>
              </li>
              <li className="sub-item">
                <a href="https://discover.bklynlibrary.org/?catalog=true&materialtype=Board+Game" >Board Games</a>
              </li>
              <li className="sub-item">
                <a href="https://discover.bklynlibrary.org/?catalog=true&amp;materialtype=DVD%7C%7COnline%20Video" >Movies</a>
              </li>
              <li className="sub-item">
                <a href="https://culturepass.nyc/" >Museum Passes (Culture Pass)</a>
              </li>
              <li className="sub-item">
                <a href="https://discover.bklynlibrary.org/?catalog=true&sort=popularity&materialtype=Online+Music%7C%7CSheet+Music%7C%7CMusic+CD%7C%7CMusic+LP" >Music &amp; Recordings</a>
              </li>
              <li className="sub-item">
                <a href="https://discover.bklynlibrary.org/?catalog=true&sort=popularity&materialtype=Musical+Instrument" >Musical Instruments</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/blogs" >Blogs</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/podcasts" >Podcasts</a>
              </li>
            </ul>
          </div>
        </div>
        <a href="#" className='toggle-menu nav-title' onClick={toggleFooterAccordion} aria-label="expand filter set"><h4>Events & Classes</h4><ThickCaretDown width="15"></ThickCaretDown></a>
        <div className="menu closed">
          <div>
            <ul className="btn-toggle-nav sub-menu">
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/bpl-presents" >BPL Presents</a>
              </li>
              <li className="sub-item">
                <a href="https://discover.bklynlibrary.org/?event=true" >Events by Branch</a>
              </li>
              <li className="sub-item">
                <a href="https://discover.bklynlibrary.org/?event=true&eventage=Kids%7C%7CBirth+to+Five+Years" >Events for Kids</a>
              </li>
              <li className="sub-item">
                <a href="https://discover.bklynlibrary.org/?event=true&eventage=Teens+%26+Young+Adults" >Events for Teens</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/exhibitions" >Exhibitions</a>
              </li>
              <li className="sub-item">
                <a href="https://discover.bklynlibrary.org/?event=true" >All events</a>
              </li>
            </ul>
          </div>
        </div>
        <a href="#" className='toggle-menu nav-title' onClick={toggleFooterAccordion} aria-label="expand filter set"><h4>Research</h4><ThickCaretDown width="15"></ThickCaretDown></a>
        <div className="menu closed">
          <div>
            <ul className="btn-toggle-nav sub-menu">
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/center-for-brooklyn-history" >Center for Brooklyn History</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/online-resources/topic/business-entrepreneurship" >Business & Entrepreneurship</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/online-resources/topic/genealogy" >Geneology</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/online-resources/topic/health-wellness" >Health & Wellness</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/online-resources/list" >All Online Resources & Databases</a>
              </li>
            </ul>
          </div>
        </div>
        <a href="#" className='toggle-menu nav-title' onClick={toggleFooterAccordion} aria-label="expand filter set"><h4>Services</h4><ThickCaretDown width="15"></ThickCaretDown></a>
        <div className="menu closed">
          <div>
            <ul className="btn-toggle-nav sub-menu">
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/adult-learning" >HSE (High School Equivalency) Diploma</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/adult-learning/bard-microcollege" >College Degree</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/business" >Career & Business Help</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/bal" >Ask a Librarian</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/bookmatch" >BookMatch</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/idnyc" >ID NYC</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/passport" >Passport Office</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/use-the-library/laptop-loan" >Laptops</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/use-the-library/print" >Printing</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/rooms" >Room Reservations</a>
              </li>
            </ul>
          </div>
        </div>
        <a href="#" className='toggle-menu nav-title' onClick={toggleFooterAccordion} aria-label="expand filter set"><h4>Support the Library</h4><ThickCaretDown width="15"></ThickCaretDown></a>
        <div className="menu closed">
          <div>
            <ul className="btn-toggle-nav sub-menu">
              <li className="sub-item">
                <a href="https://donate.bklynlibrary.org/give/387995/#!/donation/checkout?c_src=website&c_src2=footer" >Donate</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/volunteer" >Volunteer</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/standup" >Advocacy</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/support/corporate-sponsors" >Sponsorship</a>
              </li>
              <li className="sub-item">
                <a href="https://www.bklynlibrary.org/rentals" >Private Event Rentals</a>
              </li>
              <li className="sub-item">
                <a href="https://shop.bklynlibrary.org/" >The Shop at BPL</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  )

};

export default Accordions;
