import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
//import Quagga from "@ericblade/quagga2";
import { BarcodeReader, StrichSDK } from "@pixelverse/strichjs-sdk";
//import checkout from "../../functions/checkout";
import configs from "../../functions/configs";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import postData from "../../functions/postData";
import returnBestImage from "../../functions/returnBestImage";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import detectUserAgent from "../../functions/detectUserAgent";

const SelfCheckoutOverlay = (props) => {

    const conf = configs();

    const strich = {};

    const closeOverlay = (e) => {
        toggleOverlay('#self-checkout-overlay', e);
        try {
            strich.instance.destroy();
        } catch (err) { console.log(err) }
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    const checkout = (code) => {

        const conf = configs();

        toggleLoadingOverlay();

        try {
            document.querySelector('#self-checkout-overlay button').focus(); // move focus out of manual checkout box
        } catch { }

        try {
            strich.instance.stop();
        } catch (err) { console.log(err) }

        postData(conf.baseAPI + "/sierra/sierra-checkout.php", 'code=' + encodeURIComponent(code) + '&agent=' + detectUserAgent()).then(res => {
            console.log(res);
            toggleLoadingOverlay();
            try {
                strich.instance.start();
            } catch (err) { console.log(err) }
            try {
                if (typeof res.dueDate !== 'undefined') {
                    launchSuccessOverlay('', '');
                    let imageURL = returnBestImage(res.solrData);
                    console.log('imageURL var', imageURL);
                    const newDueDate = new Date(res.dueDate)
                    let dueDateString = ""
                    let dueDateDay = newDueDate.getDay()
                    let daysOfWeekSelection = conf.daysOfWeek[dueDateDay]
                    dueDateString = dueDateString + daysOfWeekSelection + ', '
                    let dueDateMonth = newDueDate.getMonth()
                    let monthsOfYearSelection = conf.monthsOfYear[dueDateMonth]
                    dueDateString += monthsOfYearSelection + ' ' + newDueDate.getDate()

                    let listItemHtml = `<div class="self-checked-out-item">
                        <div class="self-checked-out-item-image-container"><img src="${imageURL}" id="checkoutImage"></div>
                        <div>
                            <div class="bold" style="font-size: 18px"> ${res.solrData.title} </div>
                            <div class="dark-gray"> ${res.solrData.author} </div>
                            
                            <div style="margin-top:5px" class="flex">
                                <div style="position:relative;top: 2px" class="bold">DUE DATE:</div><div class="due-date-time bold">${dueDateString}</div>
                            </div>
                        </div> 
                    </div>`;

                    const node = document.createElement('li');
                    node.classList.add('checkoutUl');
                    node.innerHTML = listItemHtml;

                    document.querySelector("#result-strip ul.thumbnails").prepend(node);
                    try {
                        document.querySelector('#item-barcode').value = '';
                    } catch { }
                } else {
                    let responseText = res.name.replace('RuntimeException:', 'Sorry,');
                    launchErrorOverlay(responseText, '');
                    /*
                    const node = document.createElement('li');
                    node.classList.add('red-text');
                    node.classList.add('checkoutUl');
                    node.innerHTML = responseText;
                    document.querySelector("#result-strip ul.thumbnails").prepend(node);
                    */
                }

            } catch { }

        });
    }

    const manualCheckout = (e) => {
        e.preventDefault();
        const barcode = document.querySelector('#item-barcode').value;
        checkout(barcode);
    }

    const launchScan = () => {

        try {
            strich.instance.destroy();
        } catch (err) { console.log(err) }

        try {
            StrichSDK.initialize(conf.strichKey).then(() => {
                console.log('STRICH SDK initialized');

                let config = {
                    // the CSS selector identifying the host element
                    selector: '#scanner',
                    engine: {
                        // restrict to the required symbologies
                        symbologies: ['codabar'],
                        // filter duplicate results
                        duplicateInterval: 750
                    },
                    locator: {
                        regionOfInterest: {
                            // restrict active area to a horizontal bar in the center
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0
                        }
                    },
                    overlay: {
                        customLogoSrc: '/nothing.png',
                        showFlashlight: false
                    },
                    feedback: {
                        // get audio and vibration feedback for scans
                        audio: false,
                        vibration: true
                    }
                };

                const barcodes = [];

                new BarcodeReader(config).initialize().then(barcodeReader => {

                    console.log('success BarcodeReader initialize', barcodeReader);

                    strich.instance = barcodeReader;

                    // register handler for code detections
                    strich.instance.detected = (detections) => {
                        // .. do something with the scanned codes
                        let rawcode = detections[0].data;
                        let code = rawcode.replace(/[A-Z]/g, "");
                        code = code.trim();
                        code = code.replace(' ', '');
                        if (code.match(/^34444\d\d\d\d\d\d\d\d\d$/) !== null) {
                            if (!barcodes.includes(code)) {
                                barcodes.push(code); // prevents rescanning the same item
                                checkout(code);
                            }
                        }
                        //alert(JSON.stringify(detections));
                        console.log('detections', detections);
                    };

                    // start reading barcodes!
                    return strich.instance.start();
                })
                    .catch(error => {
                        console.log(error);
                        // initialization failed (error message displayed in BarcodeReader)
                    });


            });
        } catch { }


    }

    const scannerStyle = { position: "relative", height: "150px", width: "200px", marginLeft: "auto", marginRight: "auto" };

    //const scannerContainerStyle = { display: "flex", flexDirection: "column" };

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="self-checkout-overlay" className='overlay-container'>
            <div className='overlay-body'>
                <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Checkout</h3>

                <div id="camera-scan-label" className="bold">Scan barcode from camera</div>
                <div >
                    <div id="scanner" style={scannerStyle}>
                    </div>
                    <div className="results">
                    </div>
                    <div className="actions">
                    </div>
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                <form onSubmit={manualCheckout} id="manual-checkout">
                    <div className="flex wrap">
                        <div>
                            <label htmlFor="item-barcode">Or manually enter barcode</label>
                            <input id="item-barcode" autoComplete="off" placeholder="34444..." type="text"></input>
                        </div>
                        <div>
                            <button type="submit">Checkout</button>
                        </div>
                    </div>
                </form>

                <div id="result-strip">
                    <h4>Checked out items:</h4>
                    <ul className="thumbnails"></ul>
                </div>
                <button onClick={launchScan} id="launch-barcode-scanner" className="hide">launch scanner</button>
            </div>
        </div>
    )
}

export default SelfCheckoutOverlay;