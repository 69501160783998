import NavBar from "./navBar";
import Footer from "./footer";
import BottomNav from "./bottomNav";

const NotOnlineScreen = (props) => {

 
  return (
    <div className="App search-page">
          <NavBar></NavBar>
          <div className='main-app-container'>
            <header className="app-header">
              <div>You are not online. Connect to the internet to use this application.</div>
            </header>
            <section></section>
          </div>
          <Footer></Footer>
          <BottomNav></BottomNav>
      </div>
  )
  

}

export default NotOnlineScreen;