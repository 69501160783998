import ArrowLeft from "../icons/arrowLeft";
import IconHouse from "../icons/iconHouse"

const BreadCrumb = (props) => {

  try {
    const url = new URL(window.location.href);

    let buttonText = 'Search';
    let linkVal = '/';



    try {

      const referrer = new URL(document.referrer);

      if (referrer.href.indexOf('devopac.bklynlibrary.org') > -1 || referrer.href.indexOf('discover.bklynlibrary.org') > -1) {
        if (url.href.indexOf('bklynlibrary.org/item') > -1) { // if item page
          if (referrer.pathname === '/') { // if referrer goes back to search page
            linkVal = referrer.href;
            buttonText = 'Back to search';
          }
        }
      }

    } catch {}
    

    let classes = 'breadcrumb-container';

    if (url.href.indexOf('/item') > -1) {
      
      return (
        <div className={classes}>
          <a href={linkVal} className='breadcrumb item'>
            <ArrowLeft width='22'></ArrowLeft><div>{buttonText}</div>
          </a>
        </div>
      )
    } else {
      // search page breadcrumb
      /*
      return (
        <div className={classes}>
          <a href="/" className='breadcrumb search'>
            <div><IconHouse></IconHouse> &nbsp; Search Home</div>
          </a>
        </div>
      )
      */
    }

  } catch {}

}

export default BreadCrumb;