import toggleFilterset from "../../../functions/toggleFilterset";
import ThickCaretDown from "../../icons/thickCaretDown";

const CatalogDeweyRange = (props) => { 

    const url = new URL(window.location.href);

    let startDefault = '';
    let endDefault = '';

    try {
        if (url.searchParams.get('callrange') !== null) {
            const pubDateParts = url.searchParams.get('callrange').split(' TO ');
            startDefault = pubDateParts[0];
            endDefault = pubDateParts[1];
        }
    } catch {}

    const applyContainerStyles = {
      display: "flex",
      alignItems: "flex-end"
    }

    const applyStyles = {
      height: "42px",
      color: '#484E80',
      backgroundColor: "#fff",
      border: "1px solid #484E80",
      borderRadius: "5px"
    }

    return (
        <div className={props.catalogFilterGrouping}>
          <a href="#" className="toggle-filterset dewet" onClick={toggleFilterset} aria-label="expand filter set"><h4>Dewey Decimal Range</h4><ThickCaretDown width="15"></ThickCaretDown></a>
          <div className="dewey-filters filterset closed">

            <div><a target="_blank" href="https://www.library.illinois.edu/infosci/research/guides/dewey/">Dewey Guide</a></div>
            <form onSubmit={props.filterProps.functions.updateSearchData} id="dewey-form">
              <div className="flex">
                <div>
                  <label htmlFor="dewey-start">Start Value</label>
                  <input type="text" maxLength="20" id="dewey-start" placeholder="590" defaultValue={startDefault}></input>
                </div>
                <div>
                  <label htmlFor="dewey-end">End Value</label>
                  <input type="text" maxLength="20" id="dewey-end" placeholder="595.9" defaultValue={endDefault}></input>
                </div>
                <div style={applyContainerStyles}>
                  <input style={applyStyles} type="submit" value="Apply"></input>
                </div>
              </div>
            </form>
          </div>
          <hr></hr>
        </div>
    )
}

export default CatalogDeweyRange;