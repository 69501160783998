import { useContext } from 'react';
import { UserContext } from '../..';
import deleteCookie from '../../functions/deleteCookie';
import IconHeartSolid from "../../components/icons/iconHeartSolid";
import IconLocation from "../../components/icons/iconLocation";
import IconGlobe from "../../components/icons/iconGlobe";

const NavBar = (props) => {

  const user = useContext(UserContext);

  const login = (e) => {
    window.location.href = '/my-account';
  }

  const logout = (e) => {
    //deleteCookie('PHPSESSID');
    
    window.location.href = '/logout';
  }

  const LoginSignOut = () => {
    try {
      if (user.data.isSession) {
        return (
          <button onClick={logout} className='login-logout-button'>Logout</button>
        )
      } else {
        return (
          <button onClick={login} className='login-logout-button'>Login</button>
        )
      }
    } catch {
      /*
      return (
        <button onClick={login} className='login-logout-button'>Login</button>
      )
      */
    }
  }

  const GetACard = () => {
    try {
      if (!user.data.isSession) {
        return (
          <a href="https://www.bklynlibrary.org/card/" target='_blank' className='get-a-card-nav-link'>Get a Card</a>
        )
      }
    } catch {
      /*
      return (
        <a href="https://www.bklynlibrary.org/card/" target='_blank' className='get-a-card-nav-link'>Get a Card</a>
      )
      */
    }
  }

  /*
  <div class="gtranslate_wrapper"><i class="fa-regular fa-globe"></i> </div>
            <button className='navbar-translate-button' title="Translate"><IconGlobe></IconGlobe> <span className='navbar-translate-text'>English</span></button>

    */

  return '';
  
  return (
    <div className="nav-bar">
      <div className="logo-container"><a title="Brooklyn Public Library Homepage" className='no-border-bottom' href="https://d9.bklynlibrary.org"><img alt="BPL Logo" src="/blue-bpl-logo.svg"></img><span className='sr-only'>BPL Homepage</span></a></div>
      <div className='navbar-buttons-container'>
        
        <a href="https://donate.bklynlibrary.org/give/387995" target="_blank" title="Donate to Brooklyn Public Library" className='navbar-donate-button'><IconHeartSolid height="14"></IconHeartSolid> Donate</a>
        <GetACard></GetACard>
        <LoginSignOut></LoginSignOut>
      </div>
    </div>
  )
};

export default NavBar;
