const SortSearch = (props) => {
  
  let sortVal = 'relevance';
  const url = new URL(window.location.href);

  if (url.searchParams.get('sort') !== null && url.searchParams.get('sort') !== '') {
    sortVal = url.searchParams.get('sort');
  }

  try { // no sort widget for no results
    if (typeof props.solrData.grouped !== 'undefined') {
      if (props.solrData.grouped.ss_grouping.matches === 0) {
        return '';
      }
    }
    if (typeof props.solrData.response !== 'undefined') {
      if (props.solrData.response.docs.length === 0) {
        return '';
      }
    }
  } catch {}

  if (url.searchParams.get('booklist') !== null) {
    if (url.searchParams.get('sort') === null) {
      sortVal = 'booklistorder';
    }

    return (
      <div className="sort-options">
        <label className="main-color bold" htmlFor="sort-selectbox">Sort By</label>
        <select id="sort-selectbox" onChange={props.filterProps.functions.updateSearchData} value={sortVal}>
          <option value="booklistorder">Booklist Order</option>
          <option value="popularity">Popularity</option>
          <option value="publishdate">Publication Year</option>
          <option value="title">Title</option>
          <option value="author">Author</option>
          <option value="callnumber">Call Number</option>
          <option value="collectionadded">Added to collection</option>
          <option value="random">Random</option>
        </select>
      </div>
    )
  }

  if (url.searchParams.get('rebalancing') === 'true') {
    if (url.searchParams.get('search') === null) {

      if (url.searchParams.get('sort') === null) {
        sortVal = 'newrebalancing';
      }

      return (
        <div className="sort-options">
          <label className="main-color bold" htmlFor="sort-selectbox">Sort By</label>
          <select id="sort-selectbox" onChange={props.filterProps.functions.updateSearchData} value={sortVal}>
            <option value="newrebalancing">New Rebalancing</option>
            <option value="popularity">Popularity</option>
            <option value="publishdate">Publication Year</option>
            <option value="title">Title</option>
            <option value="author">Author</option>
            <option value="callnumber">Call Number</option>
            <option value="collectionadded">Added to collection</option>
            <option value="random">Random</option>
          </select>
        </div>
      )

    } else {

      if (url.searchParams.get('sort') === null) {
        sortVal = 'relevance';
      }

      return (
        <div className="sort-options">
          <label className="main-color bold" htmlFor="sort-selectbox">Sort By</label>
          <select id="sort-selectbox" onChange={props.filterProps.functions.updateSearchData} value={sortVal}>
            <option value="relevance">Relevance</option>
            <option value="newrebalancing">New Rebalancing</option>
            <option value="popularity">Popularity</option>
            <option value="publishdate">Publication Year</option>
            <option value="title">Title</option>
            <option value="author">Author</option>
            <option value="callnumber">Call Number</option>
            <option value="collectionadded">Added to collection</option>
            <option value="random">Random</option>
          </select>
        </div>
      )
    }
  }
  
  if (url.searchParams.get('catalog') === 'true') {
    if (url.searchParams.get('search') === null) {
      if (url.searchParams.get('sort') === null) {
        sortVal = 'popularity';
      }

      return (
        <div className="sort-options">
          <label className="main-color bold" htmlFor="sort-selectbox">Sort By</label>
          <select id="sort-selectbox" onChange={props.filterProps.functions.updateSearchData} value={sortVal}>
            <option value="popularity">Popularity</option>
            <option value="publishdate">Publication Year</option>
            <option value="title">Title</option>
            <option value="author">Author</option>
            <option value="numcopies">Number of Copies</option>
            <option value="callnumber">Call Number</option>
            <option value="collectionadded">Added to collection</option>
            <option value="random">Random</option>
          </select>
        </div>
      )
      
    } else {

      if (url.searchParams.get('sort') === null) {
        sortVal = 'relevance';
      }

      return (
        <div className="sort-options">
          <label className="main-color bold" htmlFor="sort-selectbox">Sort By</label>
          <select id="sort-selectbox" onChange={props.filterProps.functions.updateSearchData} value={sortVal}>
            <option value="relevance">Relevance</option>
            <option value="popularity">Popularity</option>
            <option value="publishdate">Publication Year</option>
            <option value="title">Title</option>
            <option value="author">Author</option>
            <option value="numcopies">Number of Copies</option>
            <option value="callnumber">Call Number</option>
            <option value="collectionadded">Added to collection</option>
            <option value="random">Random</option>
          </select>
        </div>
      )

    }
  }

  if (url.searchParams.get('event') === 'true') {

    if (url.searchParams.get('search') === null) { 
      sortVal = 'eventdate';
      return (
        <div className="sort-options">
          <label className="main-color bold" htmlFor="sort-selectbox">Sort By</label>
          <select id="sort-selectbox" onChange={props.filterProps.functions.updateSearchData} value={sortVal}>
            <option value="eventdate">Event Date</option>
          </select>
        </div>
      )
    } else {

      if (url.searchParams.get('sort') === null) {
        sortVal = 'eventdate';
      }

      return (
        <div className="sort-options">
          <label className="main-color bold" htmlFor="sort-selectbox">Sort By</label>
          <select id="sort-selectbox" onChange={props.filterProps.functions.updateSearchData} value={sortVal}>
            <option value="eventdate">Event Date</option>
            <option value="relevance">Relevance</option>
          </select>
        </div>
      )
    }

  }

  if (url.searchParams.get('booklistfilter') === 'true' || url.searchParams.get('website') === 'true') {

    if (url.searchParams.get('search') === null) { 
      sortVal = 'newwebcontent';
      return (
        <div className="sort-options">
          <label className="main-color bold" htmlFor="sort-selectbox">Sort By</label>
          <select id="sort-selectbox" onChange={props.filterProps.functions.updateSearchData} value={sortVal}>
            <option value="newwebcontent">Newly Published</option>
          </select>
        </div>
      )
    } else {
      
      if (url.searchParams.get('sort') === null) {
        sortVal = 'relevance';
      } 

      return (
        <div className="sort-options">
          <label className="main-color bold" htmlFor="sort-selectbox">Sort By</label>
          <select id="sort-selectbox" onChange={props.filterProps.functions.updateSearchData} value={sortVal}>
            <option value="relevance">Relevance</option>
            <option value="newwebcontent">Newly Published</option>
            
          </select>
        </div>
      )
    }

  }

};

export default SortSearch;