



const scroll = () => {
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
    const behavior = prefersReducedMotion.matches ? 'auto' : 'smooth'
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior
                    });
}
export default scroll;