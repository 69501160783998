const detectUserAgent = () => {
    try {
        let userAgent = 'webapp';

        if (window.navigator.userAgent.indexOf('BKLYN iOS App') > -1) {
            userAgent = 'ios';
        } else if (localStorage.getItem('agent') === 'android') {
            userAgent = 'android';
        } else if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) {
            userAgent = 'pwa';
        }

        try {
            if (navigator.userAgentData.brands[0].brand === "Not/A)Brand") {
                userAgent = 'windows';
            }
        } catch {}

        return userAgent;
    } catch {}
}

export default detectUserAgent