import configs from "../../functions/configs";
import {useContext} from "react";
import {SolrContext} from "../../searchPage";

const NumberOfResults = (props) => {

  const solr = useContext(SolrContext);

  const conf = configs();
  const url = new URL(window.location);

  let start = 1;
  let limit = 0;
  let total = 0;
  let totalFormatted = '0';
  let limitFormatted = '0';
  let startFormatted = '0';

  try {
    if (typeof solr.grouped !== "undefined") {

      total = solr.facets.groupings.numBuckets;

      if (url.searchParams.get("pagination") !== null) {
        // not first page
        limit = parseInt(url.searchParams.get("pagination")) * conf.defaultPageLength;
        start = (limit - conf.defaultPageLength) + 1;
        if (limit > total) {
          limit = total;
        }
        if (start < 1) {
          start = 1
        }
        
      } else {
        // first page
        if (total < 20) {
          limit = total;
        } else {
          limit = 20;
        }
      }

    } else {
      total = solr.response.numFound;
      if (url.searchParams.get("pagination") !== null) {
        // not first page
        limit = parseInt(url.searchParams.get("pagination")) * conf.defaultPageLength;
        start = (limit - conf.defaultPageLength) + 1;
        if (limit > total) {
          limit = total;
        }
        if (start < 1) {
          start = 1
        }
        
      } else {
        // first page
        if (total < 20) {
          limit = total;
        } else {
          limit = 20;
        }
      }

      
    }

    if (total > 0) {
      totalFormatted = total.toLocaleString("en-US");
      limitFormatted = limit.toLocaleString("en-US");
      startFormatted = start.toLocaleString("en-US");
    }
    
  } catch {}

  const numOfResultsStyle = {};

  if (total !== 0) {
    return (<div className='number-of-results'>Showing {startFormatted} to {limitFormatted} of {totalFormatted} results</div>);
  } else {
    numOfResultsStyle.display = 'none';
    return (<div style={numOfResultsStyle} className='number-of-results'></div>);
  }
  
}

export default NumberOfResults;
