const parseVarfields = (v) => {
  const arr = [];

  try {
    for (let i = 0; i < v.length; i++) {
      const obj = {};
      const sections = v[i].split(' || ');
      try {
        obj.tag = sections[0].trim();
        obj.subfields = [];
      } catch {}
      
      try {
        for (let x = 1; x < sections.length; x++) {
          const subfieldsObj = {};
          if (sections[x].match(/{{.}}/) !== null) {
            subfieldsObj.subfieldTag = sections[x].match(/{{.}}/g)[0].replace(/[\{\}]/g, '');
            subfieldsObj.subfieldContent = sections[x].replace(/{{.}}/, '').trim();
          } else {
            subfieldsObj.subfieldContent = sections[x];
          }
          obj.subfields.push(subfieldsObj);
        }
      } catch{}
      
      arr.push(obj);
    }
  } catch {}

  return arr;
};

export default parseVarfields;