import IconBook from "../icons/iconBook";
import IconHeadphones from "../icons/iconHeadphones";
import IconTablet from "../icons/iconTablet";
import IconFont from "../icons/iconFont";
import IconVideo from "../icons/iconVideo";
import IconCamera from "../icons/iconCamera";
import IconCompactDisc from "../icons/iconCompactDisc";
import IconMusic from "../icons/iconMusic";
import IconInternet from "../icons/iconInternet";
import IconMap from "../icons/iconMap";
import IconFilm from "../icons/iconFilm";
import IconBriefcase from "../icons/iconBriefcase";
import IconCube from "../icons/iconCube";
import IconDice from "../icons/iconDice";
import IconShirt from "../icons/iconShirt";
import IconVinyl from "../icons/iconVinyl";
import IconWrench from "../icons/iconWrench";
import IconArchiveBox from "./iconArchiveBox";

const CatalogFormatIcon = (props) => {
    try {
        switch (props.format) {
            case 'Book':
            case 'Manuscript':
            case 'Read-Along Book':
            case 'Journal':
            case 'Braille':
                return (
                    <IconBook></IconBook>
                )
            case 'eAudiobook':
            case 'Book on CD':
                return (
                    <IconHeadphones></IconHeadphones>
                )
            case 'eBook':
            case 'Online Journal':
                return (
                    <IconTablet></IconTablet>
                )
            case 'Large Print Book':
                return (
                    <IconFont></IconFont>
                )
            case 'Online Video':
            case 'VHS Video':
                return (
                    <IconVideo></IconVideo>
                )
            case 'Photograph':
                return (
                    <IconCamera></IconCamera>
                )
            case 'DVD':
            case 'Music CD':
                return (
                    <IconCompactDisc></IconCompactDisc>
                )
            case 'Sheet Music':
            case 'Online Music':
            case 'Musical Instrument':
                return (
                    <IconMusic></IconMusic>
                )
            case 'Internet Resource':
                return (
                    <IconInternet></IconInternet>
                )
            case 'Printed Map':
                return (
                    <IconMap></IconMap>
                )
            case 'Microform':
                return (
                    <IconFilm></IconFilm>
                )
            case 'Kit':
            case 'Mixed Material':
                return (
                    <IconBriefcase></IconBriefcase>
                )
            case '3-D Object':
            case 'MiFi':
            case 'Tablet':
            case 'Laptop':
                return (
                    <IconCube></IconCube>
                )
            case 'Board Game':
                return (
                    <IconDice></IconDice>
                )
            case 'Sewing Machine':
                return (
                    <IconShirt></IconShirt>
                )
            case 'Music LP':
                return (
                    <IconVinyl></IconVinyl>
                )
            case 'Tool':
                return (
                    <IconWrench></IconWrench>
                )
            case 'Archival Material':
            case 'Archival Collection':
                return (
                    <IconArchiveBox />
                )
            default:
                return (
                    <IconCube></IconCube>
                )
        }
    } catch {
        return (
            <IconBook></IconBook>
        )
    }
    
}

export default CatalogFormatIcon;