
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";

const CatalogCollectionFilters = (props) => { 

  const url = new URL(window.location.href);

  let brooklynHistoryActive = 'no';
  if (url.searchParams.get('collection') === 'Center for Brooklyn History') {
    brooklynHistoryActive = 'yes';
  }

  let inclusiveServicesActive = 'no';
  if (url.searchParams.get('collection') === 'Inclusive Services') {
    inclusiveServicesActive = 'yes';
  }

  return (
    <div id="catalog-filterset-fiction" className={props.catalogFilterGrouping}>
      <a href="#" className="toggle-filterset fiction" onClick={toggleFilterset} aria-label="expand filter set"><h4>Collection</h4><ThickCaretDown width="15"></ThickCaretDown></a>
      <div className="fiction-filters filterset closed">
        <button onClick={props.filterProps.functions.updateSearchData} activated={brooklynHistoryActive} filtertype="catalog-collection" filterval="Center for Brooklyn History" className="filter-square-btn">Center for Brooklyn History</button>
        <button onClick={props.filterProps.functions.updateSearchData} activated={inclusiveServicesActive} filtertype="catalog-collection" filterval="Inclusive Services" className="filter-square-btn">Inclusive Services</button>
      </div>
      <hr></hr>
    </div>
  )

}

export default CatalogCollectionFilters;