
const CaretDown = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
      <path d="M8.37531 0.236161L4.4996 4.01416L0.623901 0.236161C0.554655 0.168524 0.461698 0.130658 0.364901 0.130658C0.268104 0.130658 0.175147 0.168524 0.1059 0.236161C0.0723717 0.269012 0.045735 0.308223 0.0275507 0.351498C0.00936641 0.394773 0 0.441242 0 0.488182C0 0.535122 0.00936641 0.58159 0.0275507 0.624865C0.045735 0.66814 0.0723717 0.707352 0.1059 0.740203L4.22897 4.76014C4.30138 4.83072 4.39849 4.87021 4.4996 4.87021C4.60072 4.87021 4.69783 4.83072 4.77024 4.76014L8.89331 0.740979C8.92707 0.708103 8.95391 0.668795 8.97223 0.625377C8.99056 0.581958 9 0.535309 9 0.488182C9 0.441055 8.99056 0.394405 8.97223 0.350987C8.95391 0.307568 8.92707 0.268261 8.89331 0.235385C8.82406 0.167748 8.7311 0.129883 8.63431 0.129883C8.53751 0.129883 8.44455 0.167748 8.37531 0.235385V0.236161Z" fill="#474851"/>
    </svg>
  )
};

export default CaretDown;
