import { SolrContext } from "../../../searchPage";
import React from "react";
import { useState } from "react";
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";

const CatalogLanguageFilters = (props) => { 

  const [hideExtra, updateHideExtra] = useState(true);

  const solrData = React.useContext(SolrContext);

  const url = new URL(window.location.href);

  const updateSearchHandler = props.filterProps.functions.updateSearchData;

  let catalogFilterGrouping = props.catalogFilterGrouping;

  let count = 0;

  let limit = 20;
  
  const showMore = (e) => {
    e.preventDefault();
    let height = window.scrollY;
    if (hideExtra) {
      updateHideExtra(false);
    } else {
      updateHideExtra(true);
      height = window.scrollY + document.querySelector('#catalog-language-filters').getBoundingClientRect().top - 50;
      window.scrollTo({top: height, behavior: 'smooth'});
    }
  }

  let sc = 0; // see counts
    
  try {
      sc = parseInt(localStorage.getItem("seeCountsVisible"));
  } catch {}

  const Option = (props) => {
    count++;
    let containerClasses = 'filter-checkbox-container';
    let idVal = 'catalog-language-' + encodeURIComponent(props.data.val);
    if (count > limit && hideExtra) {
      containerClasses = 'filter-checkbox-container hide';
    }
    let isChecked = false;
    if (url.searchParams.get('language') !== null) {
      const values = url.searchParams.get('language').split('||');
      if (values.indexOf(props.data.val) > -1) {
        isChecked = true;
      }
    }

    let countPretty = props.data.count.toLocaleString();


    if (isChecked) {

      if (sc === 1) {
        return (
          <div className={containerClasses}>
            <input filtertype='catalog-language' checked='checked' value={props.data.val} onChange={updateSearchHandler} id={idVal} type="checkbox"></input><label htmlFor={idVal}>{props.data.val} <span className="filter-toggle-counts">{countPretty}</span></label>
          </div>
        )
      }

      return (
        <div className={containerClasses}>
          <input filtertype='catalog-language' checked='checked' value={props.data.val} onChange={updateSearchHandler} id={idVal} type="checkbox"></input><label htmlFor={idVal}>{props.data.val} <span className="filter-toggle-counts hide">{countPretty}</span></label>
        </div>
      )
    }

    if (sc === 1) {
      return (
        <div className={containerClasses}>
          <input filtertype='catalog-language' value={props.data.val} onChange={updateSearchHandler} id={idVal} type="checkbox"></input><label htmlFor={idVal}>{props.data.val} <span className="filter-toggle-counts">{countPretty}</span></label>
        </div>
      )
    }

    return (
      <div className={containerClasses}>
        <input filtertype='catalog-language' value={props.data.val} onChange={updateSearchHandler} id={idVal} type="checkbox"></input><label htmlFor={idVal}>{props.data.val} <span className="filter-toggle-counts hide">{countPretty}</span></label>
      </div>
    )
  }

  const Options = () => {
    return(
      solrData.facets.language.buckets.map((item) => <Option key={item.val} data={item}></Option>)
      )
  }

  const ShowMoreLink = () => {
    if (count > limit) {
      if (hideExtra) {
        return (<a href="#" className="show-more-filters-link" onClick={showMore}><span className="show-more-text-span">Show more languages</span> <ThickCaretDown width='10px'></ThickCaretDown></a>)
      }
      return (<a href="#" className="show-more-filters-link" onClick={showMore}><span className="show-more-text-span">Show fewer languages</span> <ThickCaretDown width='10px' flip='flip'></ThickCaretDown></a>)
    }
  }

  try {
    if (solrData.facets.language.buckets.length > 0) {
      return (
        <div className={catalogFilterGrouping}>
            <a href="#" id="catalog-language-filters" className="toggle-filterset" onClick={toggleFilterset} aria-label="expand filter set"><h4>Language</h4><ThickCaretDown width="15"></ThickCaretDown></a>
            <div className="filterset closed">
              <div>
                <Options></Options>
                <ShowMoreLink></ShowMoreLink>
              </div>
            </div>
            <hr></hr>
        </div>
      )
    }
  } catch {}

}

export default CatalogLanguageFilters;