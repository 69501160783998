import React, { useState } from 'react';
import returnBestImage from "../../functions/returnBestImage";
import onLoadImageHandler from '../../functions/onLoadImageHandler';
import fallbackImageHandler from '../../functions/fallbackImageHandler';
import IconAngleLeft from "../icons/iconAngleLeft";
import IconAngleRight from "../icons/iconAngleRight";

const ContentCarousel = (props) => {

    try {

        const [index, setIndex] = useState(0); 
        const length = props.data.length;
    
        const handlePrevious = (e) => {
            const carousel = e.currentTarget.closest('.cam-carousel').querySelector('.cam-carousel-content-container');
            let elementWidth = carousel.offsetWidth;
            carousel.scrollLeft -= elementWidth;
        };
    
        const handleNext = (e) => {
            const carousel = e.currentTarget.closest('.cam-carousel').querySelector('.cam-carousel-content-container');
            let elementWidth = carousel.offsetWidth;
            carousel.scrollLeft += elementWidth;
        };
    
        const ContentItem = (props) => {
            let link = "/item?b=" + props.data.id;
            let image = '';
            let title = '';
            let author = '';
            try {
                image = returnBestImage(props.data);
                title = props.data.title.substring(0, 50);
                if (title.length === 50) {
                    title += '..';
                }
                author = props.data.author.substring(0, 50);
                if (author.length === 50) {
                    author += '..';
                }
            } catch {}
            return (
                <a className="cam-carousel-item" href={link}>
                    <img onLoad={onLoadImageHandler} alt={title} onError={fallbackImageHandler} src={image}></img>
                    <div className='carousel-item-title'>{title}</div>
                    <div className='carousel-item-author'>{author}</div>
                </a>
            )
        }
    
        const ContentMap = (props) => {
            return props.data.map((item) => <ContentItem key={item.id} data={item}></ContentItem>)
        }
    
        const btnIconStyle = {fontSize: '48px', color: '#485082'};
    
        return (
            <div className="cam-carousel">
                <button onClick={handlePrevious}><span className='sr-only'>Previous</span><IconAngleLeft></IconAngleLeft></button>
                <div className='cam-carousel-content-container'><ContentMap start={index} data={props.data}></ContentMap></div>
                
                <button onClick={handleNext}><span className='sr-only'>Next</span><IconAngleRight></IconAngleRight></button>
            </div>
        );

    } catch {}

    
};

export default ContentCarousel;