const getInitials = (name) => {
    try {
        let initials = '';
        let firstName = name.replace(/.+,/, '');
        firstName = firstName.trim();
        initials += firstName.charAt(0);
        initials += name.charAt(0);
        initials = initials.toUpperCase();
        return initials;
    } catch {}
}

export default getInitials;