import configs from '../../functions/configs';
import ContentCarousel from '../shared/contentCarousel';
import { useState } from 'react';

let carouselData = {};
let carouselLink = '';
let carouselTitle = '';

const SearchCarouselTip = (props) => {

    const url = new URL(window.location);
    const conf = configs();

    const [carouselState, setCarouselState] = useState('');

    let noGroupingParam = '&view=grid'; // way to get non-grouped search results

    try {


        if (url.searchParams.get('search').toLowerCase() === 'spanish' &&
            url.searchParams.get('booklistfilter') !== 'true' &&
            url.searchParams.get('event') !== 'true' &&
            url.searchParams.get('website') !== 'true'
        ) {

            let link = '?catalog=true&language=Español+%7C+Spanish&targetage=Adults';
            fetch(conf.searchAPI + link + noGroupingParam).then(response => response.json()).then((res) => {
                console.log(res);
                carouselData = res.response.docs;
                carouselLink = '/' + link;
                carouselTitle = 'Popular titles in our Spanish Collection';
                setCarouselState(carouselTitle);
            });

        } else if ((url.searchParams.get('search').toLowerCase() === 'new dvds' || 
            url.searchParams.get('search').toLowerCase() === 'new dvd') &&
            (url.searchParams.get('booklistfilter') !== 'true' &&
            url.searchParams.get('event') !== 'true' &&
            url.searchParams.get('website') !== 'true')) {

            let link = '?catalog=true&materialtype=DVD&pasttwoyears=true';

            fetch(conf.searchAPI + link + noGroupingParam).then(response => response.json()).then((res) => {
                console.log(res);
                carouselData = res.response.docs;
                carouselLink = '/' + link;
                carouselTitle = 'New popular DVDs';
                setCarouselState(carouselTitle);
            });
            
        } else {
            return '';
        }

        if (carouselTitle !== '') {

            return (

                <div className='discover-carousel-card'>
                    <div className='search-carousel-tip-top-container'>
                        <div>
                            <div className='dark-gray bold'>BROWSE</div>
                            <h3 className='bold'>{carouselTitle}</h3>
                        </div>
                        <div className='search-carousel-tip-link-container'><a href={carouselLink}>View All</a></div>
                    </div>

                    <ContentCarousel data={carouselData} />
                </div>
            )
        }

    } catch { }

    return '';
}

export default SearchCarouselTip;