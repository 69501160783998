import CheckoutsContainer from "./checkoutsContainer";
import HoldsContainer from "./holdsContainer";
import BooklistsContainer from "./booklistsContainer";
import EditContainer from "./editContainer";
import HistoryContainer from "./historyContainer";
import SavedSearchesContainer from "./savedSearchesContainer";

const MyAccountContentContainer = (props) => {

    if (props.selection.type === 'checkouts') {
        return (
            <CheckoutsContainer loginState={props.loginState}></CheckoutsContainer>
        )
    } else if (props.selection.type === 'holds') {
        return (
            <HoldsContainer loginState={props.loginState}></HoldsContainer>
        )
    } else if (props.selection.type === 'booklists') {
        return (
            <BooklistsContainer loginState={props.loginState}></BooklistsContainer>
        )
    } else if (props.selection.type === 'edit') {
        return (
            <EditContainer loginState={props.loginState}></EditContainer>
        )
    } else if (props.selection.type === 'history') {
        return (
            <HistoryContainer loginState={props.loginState}></HistoryContainer>
        )
    } else if (props.selection.type === 'savedSearches') {
        return (
            <SavedSearchesContainer loginState={props.loginState}></SavedSearchesContainer>
        )
    }
    
}

export default MyAccountContentContainer;