import sideToggleOverlay from "../../functions/sideToggleOverlay";
import IconAngleLeft from "../icons/iconAngleLeft";
import configs from "../../functions/configs";
import postData from "../../functions/postData";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import { useContext } from "react";
import { UserContext } from "../..";
import clearCache from "../../functions/clearCache";
import capitalizeFirstLetter from "../../functions/capitalizeFirstLetter";
import setCookie from "../../functions/setCookie";
import detectUserAgent from "../../functions/detectUserAgent";

const AddAccountSideDrawer = (props) => {

    const conf = configs();

    const user = useContext(UserContext);

    const closeOverlay = (e) => {
        sideToggleOverlay('#add-account-side-drawer', e);
    }
    
    const backToAccountDrawer = (e) => {
        sideToggleOverlay('#add-account-side-drawer', e);
        setTimeout(()=> {
            sideToggleOverlay('#my-account-side-drawer', e);
        }, 200);
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    const addAccount = (e) => {
        e.preventDefault();

        let codeVal = document.querySelector('#add-account-barcode-input').value.trim().toLowerCase().replace(/ /, '');
        let pinVal = document.querySelector('#add-account-pin-input').value.trim();
        clearCache();
        toggleLoadingOverlay();
        postData(conf.baseAPI + '/user/add-account.php', 'code=' + codeVal + '&pin=' + pinVal + '&agent=' + detectUserAgent()).then((res) => {
            toggleLoadingOverlay();
            if (typeof res.description !== 'undefined') {
                launchErrorOverlay(res.description, '');
                return '';
            }
            if (typeof res.error !== 'undefined') {
                launchErrorOverlay(res.error, '');
                return '';
            }
            if (typeof res.bpl_catalog !== 'undefined') {
                user.data.session = res;

                let firstName = '';
                try {
                    firstName = user.data.session.bpl_catalog.name.replace(/.+,/, '');
                    firstName = firstName.trim();
                    firstName = firstName.toLowerCase();
                    firstName = capitalizeFirstLetter(firstName);
                    firstName = firstName.replace(/ .+/, '');
                } catch {}

                launchSuccessOverlay('New account added for ' + firstName + '!');
                closeOverlay();
                setCookie('bplswitchedcheckouts', Date.now());
                setCookie('bplswitchedholds', Date.now());
                setCookie('bplswitchedbooklists', Date.now());
                setCookie('bplswitchedhistory', Date.now());
                clearCache();
                props.setLoginState('account added ' + Date.now());
            }
        });
    }

    return (
        <div onClick={containerClick} id="add-account-side-drawer" className='overlay-container side-slide'>
            <div className='overlay-body side-slide-right'>
                <div className="overlay-top-button-container">
                    <button aria-label="Close dialog" onClick={backToAccountDrawer} ><IconAngleLeft /></button>
                    
                </div>
               <div className="bold main-color eighteen-point">Add Account</div>
               <form autoComplete="off" onSubmit={addAccount} id="add-account-form">
                    <label htmlFor="add-account-barcode-input">Barcode/Username</label>
                    <input autoComplete="off" required id="add-account-barcode-input" type="text"></input>

                    <label htmlFor="add-account-pin-input">PIN</label>
                    <input autoComplete="off" required id="add-account-pin-input" type="password"></input>

                    <input type="submit" value="Add Account"></input>
               </form>
            </div>
        </div>
    )
}

export default AddAccountSideDrawer;