import React from "react";
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";
import IconCalendar from "../../icons/iconCalendar";
import IconWalkIn from "../../icons/iconWalkIn";

const EventRegistrationFilters = (props) => { 

  const url = new URL(window.location.href);

  let eventFilterGrouping = 'hide';
  if (url.searchParams.get('event') !== null) {
    eventFilterGrouping = '';
  }

  let registActive = 'no';
  let noRegistActive = 'no';

  try {
    if (url.searchParams.get('registration') !== null) {
      if (url.searchParams.get('registration') === 'registration') {
        registActive = 'yes';
      }
      if (url.searchParams.get('registration') === 'noregistration') {
        noRegistActive = 'yes';
      }
    }
  } catch {}

  
  return (
    <div id="event-registration-filters" className={eventFilterGrouping}>
      <a href="#" className="toggle-filterset" onClick={toggleFilterset} aria-label="expand filter set"><h4>Registration</h4><ThickCaretDown flip width="15"></ThickCaretDown></a>
      <div className="filterset open">
        <button onClick={props.filterProps.functions.updateSearchData} activated={registActive} filtertype='event-registration' filterval="registration" value="registration" className="filter-square-btn"><IconCalendar />RSVP</button>
        <button onClick={props.filterProps.functions.updateSearchData} activated={noRegistActive} filtertype='event-registration' filterval="noregistration" value="noregistration" className="filter-square-btn"><IconWalkIn />No RSVP</button>
      </div>
      <hr></hr>
    </div>
  )
  
}

export default EventRegistrationFilters;