import MultipleFormatsMap from "./multipleFormatsMap";

const FormatSelector = (props) => {
  
  try {
    return (
      <div className="formats-container">
        <div className="formats-header bold">Formats:</div>
        <div className="format-selection-container">
          <MultipleFormatsMap updateItemPage={props.updateItemPage} data={props.groupData.response.docs}></MultipleFormatsMap>
        </div>
      </div>
    )
  
  } catch {

  }
  
  
};

export default FormatSelector;