import configs from "../../functions/configs";
import { useState, useContext } from "react";
import postData from "../../functions/postData";
import returnBestImage from "../../functions/returnBestImage";
import fallbackImageHandler from "../../functions/fallbackImageHandler";
import onLoadImageHandler from "../../functions/onLoadImageHandler";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import toggleOverlay from "../../functions/toggleOverlay";
import IconRobot from "../icons/iconRobot";
import IconBook from "../icons/iconBook";
import IconTablet from "../icons/iconTablet";
import CatalogFormatIcon from "../icons/catalogFormatIcon";
import CaretDown from "../icons/caretDown";
import setCookie from "../../functions/setCookie";
import ThinX from "../icons/thinX";
import getData from "../../functions/getData";
import { UserContext } from "../..";
import deleteCookie from "../../functions/deleteCookie";
import getCookie from "../../functions/getCookie";
import IconInfo from "../icons/iconInfo";

let dataGrabbed = false;

const holds = {};

const HoldsContainer = (props) => {

    const conf = configs();

    const [readyState, updateReadyState] = useState('loading');
    const [filterState, updateFilterState] = useState('none');
    const [sortState, updateSortState] = useState('position');
    const [cancelState, updateCancelState] = useState('');
    const [pauseState, updatePauseState] = useState('');
    const [editPickupLocationHold, updateEditPickupLocationHold] = useState({});
    const [pickupLocationState, updatePickupLocationState] = useState('');
    const [editEmailHold, updateEditEmailHold] = useState({});
    const [emailNotificationState, updateEmailNotificationState] = useState('');
    const user = useContext(UserContext);

    if (readyState === 'loading') {
        try {
            // unset filters and sort if returning to screen after initial load
            holds.data.forEach((item)=>{
                item.hide = 'none';
            });
            holds.data.sort((a, b) => {
                if (a.positionSort > b.positionSort) {
                    return 1;
                } else if (a.positionSort < b.positionSort) {
                    return -1;
                } else {
                    return 0;
                }
            });
        } catch {}
    }

    let switchedAccounts = getCookie('bplswitchedholds');

    if (switchedAccounts !== '') {
        delete holds.data;
    }

    if (dataGrabbed === false || switchedAccounts !== '') {
        getData(conf.baseAPI + "/user/return-holds.php").then((res) => {
            holds.data = res;
            console.log(res);
            dataGrabbed = true;
            
            if (switchedAccounts !== '') {
                updateReadyState('ready' + Date.now());
            } else {
                updateReadyState('ready');
            }
            deleteCookie('bplswitchedholds');
            try {
                for (let i = 0; i < res.length; i++) {
                    try {
                        if (res[i].pickupLocation.code === '53') {
                            document.querySelector('#park-slope-closure-message').classList.remove('hide');
                        }
                    } catch {}
                }
            } catch {}
        });
    }

    const cancelSierraHold = (e) => {
        e.preventDefault();
        toggleLoadingOverlay();

        const id = e.currentTarget.attributes.holdid.value;

        if (e.currentTarget.attributes.rebalancing.value === 'yes') {

            const itemId = e.currentTarget.attributes.itemid.value;

            postData(conf.baseAPI + "/sierra/sierra-cancel-rebalancing-hold.php", "id=" + id + "&itemId=" + itemId).then((res) => {
                console.log(res);
                toggleLoadingOverlay();
                if (res === null) { // success
                    for (let i = 0; i < holds.data.length; i++) {
                        try {
                            let sierraHoldId = holds.data[i].id.replace(/.+\//, '');
                            if (sierraHoldId === id) {
                                holds.data.splice(i, 1);
                            }
                        } catch {}
                    }
                    updateCancelState('canceled ' + id);
                    launchSuccessOverlay(
                        "Your rebalancing hold has been canceled",
                        ""
                    );
                } else if (typeof res.sessionExpired !== 'undefined') {
                    toggleOverlay('#login-overlay', e);
                } else if (typeof res.error !== 'undefined') { // network error
                    launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
                } else {
                    launchErrorOverlay(res.description, '');
                }
            });
            
        } else {

            postData(conf.baseAPI + "/sierra/sierra-cancel-hold.php", "id=" + id).then((res) => {
                console.log(res);
                toggleLoadingOverlay();
                if (res === null) { // success
                    for (let i = 0; i < holds.data.length; i++) {
                        try {
                            let sierraHoldId = holds.data[i].id.replace(/.+\//, '');
                            if (sierraHoldId === id) {
                                holds.data.splice(i, 1);
                            }
                        } catch {}
                    }
                    updateCancelState('canceled ' + id);
                    launchSuccessOverlay(
                        "Your hold has been canceled",
                        ""
                    );
                } else if (typeof res.sessionExpired !== 'undefined') {
                    toggleOverlay('#login-overlay', e);
                } else if (typeof res.error !== 'undefined') { // network error
                    launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
                } else {
                    launchErrorOverlay(res.description, '');
                }
            });

        }
        
    }

    const pauseSierraHold = (e) => {
        e.preventDefault();
        toggleLoadingOverlay();
        const id = e.currentTarget.attributes.holdid.value;
        postData(conf.baseAPI + "/sierra/sierra-pause-hold.php", "id=" + id).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            if (res === null) { // success
                for (let i = 0; i < holds.data.length; i++) {
                    try {
                        let sierraHoldId = holds.data[i].id.replace(/.+\//, '');
                        if (sierraHoldId === id) {
                            holds.data[i].frozen = true;
                        }
                    } catch {}
                }
                updatePauseState('paused ' + id + Date.now());
                launchSuccessOverlay(
                    "Your hold has been paused",
                    ""
                );
            } else if (typeof res.sessionExpired !== 'undefined') {
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                launchErrorOverlay(res.description, '');
            }
        });
    }

    const unpauseSierraHold = (e) => {
        e.preventDefault();
        toggleLoadingOverlay();
        const id = e.currentTarget.attributes.holdid.value;
        postData(conf.baseAPI + "/sierra/sierra-unpause-hold.php", "id=" + id).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            if (res === null) { // success
                for (let i = 0; i < holds.data.length; i++) {
                    try {
                        let sierraHoldId = holds.data[i].id.replace(/.+\//, '');
                        if (sierraHoldId === id) {
                            holds.data[i].frozen = false;
                        }
                    } catch {}
                }
                updatePauseState('unpaused ' + id + Date.now());
                launchSuccessOverlay(
                    "The pause has been removed",
                    ""
                );
            } else if (typeof res.sessionExpired !== 'undefined') {
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                launchErrorOverlay(res.description, '');
            }
        });
    }

    const cancelOverdriveHold = (e) => {
        e.preventDefault();
        toggleLoadingOverlay();
        const id = e.currentTarget.attributes.oid.value;
        postData(conf.baseAPI + "/overdrive/overdrive-cancel-hold.php", "id=" + id).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            if (res === null) { // success
                for (let i = 0; i < holds.data.length; i++) {
                    try {
                        if (holds.data[i].reserveId === id) {
                            holds.data.splice(i, 1);
                        }
                    } catch {}
                }
                updateCancelState('canceled ' + id);
                launchSuccessOverlay(
                    "Your hold has been canceled",
                    ""
                );
            } else if (typeof res.sessionExpired !== 'undefined') {
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                launchErrorOverlay(res.message, '');
            }
        });
    }

    const overdrivePauseHold = (e) => {
        e.preventDefault();
        toggleLoadingOverlay();
        const id = e.currentTarget.attributes.oid.value;
        postData(conf.baseAPI + "/overdrive/overdrive-pause-hold.php", "id=" + id).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            if (typeof res.reserveId !== 'undefined') { // success
                for (let i = 0; i < holds.data.length; i++) {
                    try {
                        if (holds.data[i].reserveId === id) {
                            delete holds.data[i].actions.addSuspension;
                            holds.data[i].actions.releaseSuspension = 'placeholder';
                        }
                    } catch {}
                }
                updatePauseState('paused ' + id + Date.now());
                launchSuccessOverlay(
                    "Your hold has been paused",
                    ""
                );
            } else if (typeof res.sessionExpired !== 'undefined') {
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                launchErrorOverlay(res.message, '');
            }
        });
    }

    const overdriveUnpauseHold = (e) => {
        e.preventDefault();
        toggleLoadingOverlay();
        const id = e.currentTarget.attributes.oid.value;
        postData(conf.baseAPI + "/overdrive/overdrive-unpause-hold.php", "id=" + id).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            if (res === null) { // success
                for (let i = 0; i < holds.data.length; i++) {
                    try {
                        if (holds.data[i].reserveId === id) {
                            delete holds.data[i].actions.releaseSuspension;
                            holds.data[i].actions.addSuspension = 'placeholder';
                        }
                    } catch {}
                }
                updatePauseState('unpaused ' + id + Date.now());
                launchSuccessOverlay(
                    "The pause has been removed",
                    ""
                );
            } else if (typeof res.sessionExpired !== 'undefined') {
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                launchErrorOverlay(res.message, '');
            }
        });
    }

    const checkoutOverdriveHold = (e) => {
        e.preventDefault();
        toggleLoadingOverlay();
        const id = e.currentTarget.attributes.oid.value;
        postData(conf.baseAPI + "/overdrive/overdrive-checkout.php", "oid=" + id).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            if (typeof res.reserveId !== 'undefined') { // success
                for (let i = 0; i < holds.data.length; i++) {
                    try {
                        if (holds.data[i].reserveId === id) {
                            holds.data.splice(i, 1);
                        }
                    } catch {}
                }
                updateCancelState('checkedout ' + id + Date.now());
                launchSuccessOverlay(
                    "You can access this title in the checkouts tab",
                    ""
                );
                setCookie('bplupdatecheckouts', Date.now());
            } else if (typeof res.sessionExpired !== 'undefined') {
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                launchErrorOverlay(res.message, '');
            }
        });
    } 

    
    const Hold = (props) => {

        try {
            if (props.data.hide === 'hidden') {
                return "";
            }
        } catch {}

        try {

        if (props.data.type === 'sierra') {

            let link = "/item?b=" + props.data.bib;

            let img = returnBestImage(props.data.solrData);

            let pickupLocation = '';
            try {
                pickupLocation =  props.data.pickupLocation.name.replace(/ \(..\)$/, '');
            } catch {}

            let holdId = '';
            try {
                holdId = props.data.id.replace(/.+\//, '');
            } catch{}

            const SierraStatusMessage = (props) => {

                try {
                    if (props.data.note.indexOf("Rebalancing project") > -1) {
                        let rebalancingLocation = '';
                        try {
                            for (let i = 0; i < props.data.solrData.sm_items_up_for_grabs.length; i++) {
                                if (props.data.solrData.sm_items_up_for_grabs[i].indexOf(props.data.record.id) > -1) {
                                    const upForGrabsParts = props.data.solrData.sm_items_up_for_grabs[i].split(' | ');
                                    rebalancingLocation = upForGrabsParts[1];
                                    rebalancingLocation = rebalancingLocation.toUpperCase();
                                }
                            }
                        } catch {}
                        return (
                            <div className="my-account-due-date-container">REBALANCING FROM {rebalancingLocation}</div>
                        )
                    }
                } catch {}

                try {
                    if (props.data.status.code === 'b') {
                        return (
                            <div className="my-account-due-date-container">READY FOR PICKUP AT {pickupLocation}</div>
                        )
                    }
                } catch{}

                try {
                    if (props.data.status.code === 'j') {
                        return (
                            <div className="my-account-due-date-container">READY FOR PICKUP AT {pickupLocation}</div>
                        )
                    }
                } catch{}

                try {
                    if (props.data.status.code === 'i') {
                        return (
                            <div className="my-account-due-date-container">READY FOR PICKUP AT {pickupLocation}</div>
                        )
                    }
                } catch{}
                
                try {
                    if (props.data.status.code === 't') {
                        return (
                            <div className="my-account-due-date-container">IN TRANSIT TO {pickupLocation}</div>
                        )
                    }
                } catch{}

                return (
                    <div className="my-account-due-date-container"><div>YOU ARE</div><div className="due-date-time">#{props.data.priority}</div> ON HOLDS LIST</div>
                )
            }

            const SierraPauseHold = (props) => {

                if (props.data.frozen === true) {
                    return (
                        <button onClick={unpauseSierraHold} holdid={props.holdid} className="my-account-catalog-main-cta">Remove Pause</button>
                    )
                }

                return (
                    <button onClick={pauseSierraHold} holdid={props.holdid} className="my-account-catalog-main-cta">Pause Hold</button>
                )
            }

            const SierraPickupLocation = (props) => {

                let pickupLocationText = '';

                try {
                    pickupLocationText = props.data.pickupLocation.name.replace(/ \(..\)/, '');
                } catch {}

                if (props.data.status.code === 'b' || props.data.status.code === 'i' || props.data.status.code === 'j') { // ready for pickup
                    let pickupByText = '';

                    try {
                        let pickupByDate = new Date(props.data.pickupByDate);
                        pickupByText = pickupByDate.toLocaleDateString("en-US");
                    } catch {}

                    return (
                        <div className="flex">
                            <div className="bold">Pickup By:</div>
                            <div className="bold pickup-by-text">{pickupByText}</div>
                        </div>
                    )
                }

                if (props.data.status.code === 'b' || props.data.status.code === 't' || props.data.status.code === 'i' || props.data.status.code === 'j') { // in transit
                    return (
                        <div>
                            <div className="bold">Pickup Location:</div>
                            <div>{pickupLocationText}</div>
                        </div>
                    )
                }

                const editSierraPickupLocation = (e) => {
                    e.preventDefault();
                    updateEditPickupLocationHold(props.data);
                    setTimeout(() => {
                        toggleOverlay('#edit-sierra-pickup-location-overlay', e);
                    } , 0);
                }

                return (
                    <div>
                        <div className="bold">Pickup Location:</div>
                        <div><button title="Change pickup location" onClick={editSierraPickupLocation} className="button-looks-like-link"><span>{pickupLocationText}</span> <CaretDown /></button></div>
                    </div>
                )
            }

            let isRebalancing = 'no';
            try {
                if (props.data.note.indexOf("Rebalancing project") > -1) {
                    isRebalancing = 'yes';
                }
            } catch {}

            const NoteDisplay = () => {
                try {
                    if (typeof props.data.note !== 'undefined') {
                        if (props.data.note.indexOf("Rebalancing project") === -1) {
                            if (props.data.note !== '') {
                                return (
                                    <div className="hold-note-container"><div><IconInfo /></div><div>{props.data.note}</div></div>
                                )
                            }
                        }
                    }
                } catch {}
            }

            return (
                <div bib={props.data.bib} className="my-account-catalog-record">

                    <SierraStatusMessage data={props.data}></SierraStatusMessage>

                    <div className="flex-small-gap">
                        <div>
                            <img onError={fallbackImageHandler} onLoad={onLoadImageHandler} alt={props.data.solrData.title} src={img}></img>
                        </div>

                        <div className="my-account-text-container">
                            <div className="my-account-catalog-title"><a href={link}>{props.data.solrData.title}</a></div>
                            <div className="my-account-catalog-author">{props.data.solrData.author}</div>
                            <div className="my-account-catalog-material-type"><CatalogFormatIcon format={props.data.solrData.material_type}></CatalogFormatIcon> {props.data.solrData.material_type} - {props.data.solrData.is_copies_non_deleted} copies owned</div>
                            <SierraPickupLocation holdid={holdId} data={props.data}></SierraPickupLocation>
                            <NoteDisplay />
                        </div>
                    </div>

                    <div className="my-account-catalog-actions">
                        <SierraPauseHold holdid={holdId} data={props.data}></SierraPauseHold>
                        <a holdid={holdId} itemid={props.data.record.id} onClick={cancelSierraHold} rebalancing={isRebalancing} className="my-account-action-link" href="#">Cancel Hold</a>
                    </div>
                </div>
            )
        }

        if (props.data.type === 'overdrive') {

            let link = "/item?b=" + props.data.bib;

            let img = returnBestImage(props.data.solrData);

            const OverdriveStatusMessage = (props) => {

                if (typeof props.data.actions.checkout !== 'undefined') {
                    return (
                        <div className="my-account-due-date-container">READY FOR CHECKOUT</div>
                    )
                }

                return (
                    <div className="my-account-due-date-container"><div>YOU ARE </div><div className="due-date-time">#{props.data.holdListPosition}</div> ON HOLDS LIST</div>
                )
            }

            const OverdriveCTA = () => {

                if (typeof props.data.actions.checkout !== 'undefined') {
                    return (
                        <button onClick={checkoutOverdriveHold} oid={props.data.reserveId} className="my-account-catalog-main-cta">Checkout Item</button>
                    )
                }

                if (typeof props.data.actions.addSuspension !== 'undefined') {
                    return (
                        <button onClick={overdrivePauseHold} oid={props.data.reserveId} className="my-account-catalog-main-cta">Pause Hold</button>
                    )
                }

                if (typeof props.data.actions.releaseSuspension !== 'undefined') {
                    return (
                        <button onClick={overdriveUnpauseHold} oid={props.data.reserveId} className="my-account-catalog-main-cta">Remove Pause</button>
                    )
                }
                
            }

            const launchEditEmail = (e) => {
                e.preventDefault();
                updateEditEmailHold(props.data);
                setTimeout(()=> {
                    toggleOverlay('#edit-email-notification-overlay');
                }, 0);
            }

            const OverdriveNotificationEmail = (props) => {
                if (props.data.emailAddress !== '') {
                    return (
                        <div><div className="bold">Notification:</div><div><button onClick={launchEditEmail} title="Edit notification email" className="button-looks-like-link"><span>{props.data.emailAddress}</span> <CaretDown></CaretDown></button></div></div>
                    )
                } else {
                    return (
                        <div><div className="bold">Notification:</div><div><button onClick={launchEditEmail} title="Add notification email" className="button-looks-like-link"><span>Add email notification</span> <CaretDown></CaretDown></button></div></div>
                    )
                }
            }

            return (
                <div bib={props.data.bib} className="my-account-catalog-record">

                    <OverdriveStatusMessage data={props.data}></OverdriveStatusMessage>

                    <div className="flex-small-gap">
                        <div>
                            <img alt={props.data.solrData.title} src={img}></img>
                        </div>

                        <div className="my-account-text-container">
                            <div className="my-account-catalog-title"><a href={link}>{props.data.solrData.title}</a></div>
                            <div className="my-account-catalog-author">{props.data.solrData.author}</div>
                            <div className="my-account-catalog-material-type"><CatalogFormatIcon format={props.data.solrData.material_type}></CatalogFormatIcon> {props.data.solrData.material_type} - {props.data.solrData.digital_copies_owned} copies owned</div>
                            <OverdriveNotificationEmail data={props.data}></OverdriveNotificationEmail>
                        </div>
                    </div>

                    <div className="my-account-catalog-actions">
                        <OverdriveCTA data={props.data}></OverdriveCTA>
                        <a onClick={cancelOverdriveHold} oid={props.data.reserveId} className="my-account-action-link" href="#">Cancel Hold</a>
                    </div>
                </div>
            )
        }

        } catch (e) {console.log(e)}
        
    }

    const Holds = (props) => {
        try {
            if (props.data.length === 0) {
                return (
                    <div className="my-account-catalog-no-results">You have no holds!</div>
                )
            }
    
            if (props.data.length > 0) {
                return props.data.map((item)=><Hold key={item.id} data={item}></Hold>);
            }
        } catch {
            return (
                <div>Beep Boop. Gathering data... &nbsp; <IconRobot></IconRobot> </div>
            )
        }
    }

    const HoldCount = (props) => {

        try {
            switch (user.data.session.bpl_catalog.ptype) {
                case 24:
                    conf.physicalHoldLimit = 100;
                    break;
                case 8:
                case 9:
                case 25:
                case 151:
                case 208:
                case 209:
                case 251:
                    conf.physicalHoldLimit = 50;
                    break;
                case 12:
                case 13:
                case 14:
                case 15:
                    conf.physicalHoldLimit = 3;
                    break;
                case 20:
                    conf.physicalHoldLimit = 999;
                    break;
                case 11:
                case 19:
                case 40:
                case 81:
                case 82:
                    conf.physicalHoldLimit = 0;
                    conf.digitalHoldLimit = 0;
                    break;
            }
        } catch {}
        
        try {

            const digitalHolds = [];
            const physicalHolds = [];

            for (let i = 0; i < props.data.length; i++) {
                if (props.data[i].type === 'overdrive') {
                    digitalHolds.push(props.data[i]);
                } else {
                    physicalHolds.push(props.data[i]);
                }
            }


            let physicalText = physicalHolds.length + " out of " + conf.physicalHoldLimit;
            let digitalText = digitalHolds.length + " out of " + conf.digitalHoldLimit;

            //if (props.data.length > 0) {
                return (
                    <div className="account-count-container">
                        <div className="individual-count-container">
                            <div className="bold main-color">
                                {physicalText}
                            </div>
                            <div className="dark-gray account-count-text">
                                Physical Holds
                            </div>
                        </div>
                        <div>
                            <hr />
                        </div>
                        <div className="individual-count-container">
                            <div className="bold main-color">
                                {digitalText}
                            </div>
                            <div className="dark-gray account-count-text">
                                Digital Holds
                            </div>
                        </div>
                    </div>
                )
            //}
        } catch {}
        
    }

    const filterDigital = (e) => {
        holds.data.forEach((item)=>{
            item.hide = 'none';
        });
        updateReadyState('loaded');
        if (filterState === 'digital') {
            updateFilterState('none');
        } else {
            holds.data.forEach((item)=>{
                if (item.type === "sierra") {
                    item.hide = 'hidden';
                }
            });
            updateFilterState('digital');
        }
    }

    const filterPhysical = (e) => {
        holds.data.forEach((item)=>{
            item.hide = 'none';
        });
        updateReadyState('loaded');
        if (filterState === 'physical') {
            updateFilterState('none');
        } else {
            holds.data.forEach((item)=>{
                if (item.type === "overdrive") {
                    item.hide = 'hidden';
                }
            });
            updateFilterState('physical');
        }
    }

    const sortHolds = (e) => {
        updateReadyState('loaded');
        if (e.target.value === 'Sort by title') {
            holds.data.sort((a, b) => {
                if (a.solrData.title > b.solrData.title) {
                    return 1;
                } else if (a.solrData.title < b.solrData.title) {
                    return -1;
                } else {
                    return 0;
                }
            });
            updateSortState('title');
        } else if (e.target.value === 'Sort by hold position') {
            holds.data.sort((a, b) => {
                if (a.positionSort > b.positionSort) {
                    return 1;
                } else if (a.positionSort < b.positionSort) {
                    return -1;
                } else {
                    return 0;
                }
            });
            updateSortState('position');
        }
    }

    const HoldFilters = (props) => {
        try {
            if (props.data.length > 0) {

                let digitalClass = '';
                let physicalClass = '';
                if (filterState === 'physical') {
                    physicalClass = 'active';
                } else if (filterState === 'digital') {
                    digitalClass = 'active';
                }

                let sortVal = 'Sort by hold position';
                if (sortState === 'title') {
                    sortVal = 'Sort by title';
                } 

                return (
                    <div className="account-sort-fiter-container">
                        <div className="account-filter-button-container">
                            <button id="filter-digital-holds" className={digitalClass} onClick={filterDigital}><IconTablet></IconTablet> Filter Digital</button>
                            <button id="filter-physical-holds" className={physicalClass} onClick={filterPhysical}><IconBook></IconBook> Filter Physical</button>
                        </div>
                        <label for="holds-sort" className="sr-only">Sort holds</label>
                        <select onChange={sortHolds} defaultValue={sortVal} id="holds-sort">
                            <option>Sort by hold position</option>
                            <option>Sort by title</option>
                        </select>
                    </div>
                )
            }
        } catch {}
    }

    const EditPickupLocationOverlay = (props) => {
        
        const closeOverlay = (e) => {
            toggleOverlay('#edit-sierra-pickup-location-overlay', e);
        }

        const Option = (props) => {
            return (
                <option value={props.data.code}>{props.data.name}</option>
            )
        }

        const Options = (props) => {
            try {
                if (editPickupLocationHold.solrData.material_type === 'Board Game') {
                    return conf.boardGamePickupLocations.map(item => <Option key={item.code} data={item}></Option>);
                } else if (editPickupLocationHold.solrData.material_type  === 'Musical Instrument') {
                    return conf.musicalInstrumentPickupLocations.map(item => <Option key={item.code} data={item}></Option>);
                } else if (editPickupLocationHold.solrData.call_number  === 'TELESCOPE') {
                    return conf.telescopePickupLocations.map(item => <Option key={item.code} data={item}></Option>);
                } else if (editPickupLocationHold.solrData.call_number === 'TOOL') {
                    return conf.toolLibraryPickupLocations.map(item => <Option key={item.code} data={item}></Option>);
                }
                return conf.defaultPickupLocations.map(item => <Option key={item.code} data={item}></Option>);
            } catch { 
                return conf.defaultPickupLocations.map(item => <Option key={item.code} data={item}></Option>);
            }
        }

        const updatePickupLocation = (e) => {
            e.preventDefault();
            toggleLoadingOverlay();
            const id = editPickupLocationHold.id.replace(/.+\//, '');
            const val = document.querySelector('#new-sierra-pickup-location').value;
            const name = document.querySelector('#new-sierra-pickup-location option:checked').innerHTML;
            postData(conf.baseAPI + "/sierra/sierra-edit-pickup-location.php", "id=" + id + '&val=' + val).then((res) => {
                console.log(res);
                toggleLoadingOverlay();
                toggleOverlay('#edit-sierra-pickup-location-overlay', e);
                if (res === null) { // success
                    for (let i = 0; i < holds.data.length; i++) {
                        try {
                            if (holds.data[i].id === editPickupLocationHold.id) {
                                holds.data[i].pickupLocation.code = val;
                                holds.data[i].pickupLocation.name = name;
                            }
                        } catch {}
                    }
                    updatePickupLocationState('update ' + id + Date.now());
                    launchSuccessOverlay(
                        "Your pickup location has been updated",
                        ""
                    );
                } else if (typeof res.sessionExpired !== 'undefined') {
                    toggleOverlay('#login-overlay', e);
                } else if (typeof res.error !== 'undefined') { // network error
                    launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
                } else {
                    launchErrorOverlay(res.description, '');
                }
            });
        }

        let defaultVal = '';
        try {
            defaultVal = editPickupLocationHold.pickupLocation.code;
        } catch {}

        return (
            <div id="edit-sierra-pickup-location-overlay" className='overlay-container'>
                <div className='overlay-body'>
                    <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                    <h3>Change Pickup Location</h3>
                    <form onSubmit={updatePickupLocation} id='edit-sierra-pickup-location'>
                        <label htmlFor="new-sierra-pickup-location">New pickup location</label>
                        <select defaultValue={defaultVal} id="new-sierra-pickup-location">
                            <Options></Options>
                        </select>
                        <button type="submit">Update</button>
                    </form>
                </div>
            </div>
        )
    }

    const EditEmailNotificationOverlay = (props) => {
        
        const closeOverlay = (e) => {
            toggleOverlay('#edit-email-notification-overlay', e);
        }

        const updateEmailNotification = (e) => {
            e.preventDefault();
            toggleLoadingOverlay();
            const id = editEmailHold.reserveId;
            const val = document.querySelector('#edit-email-input').value;
            postData(conf.baseAPI + "/overdrive/overdrive-edit-hold-email.php", "id=" + id + '&val=' + encodeURIComponent(val)).then((res) => {
                console.log(res);
                toggleLoadingOverlay();
                toggleOverlay('#edit-email-notification-overlay', e);
                if (res === null) { // success
                    for (let i = 0; i < holds.data.length; i++) {
                        try {
                            if (holds.data[i].reserveId === editEmailHold.reserveId) {
                                holds.data[i].emailAddress = val;
                            }
                        } catch {}
                    }
                    updateEmailNotificationState('update ' + id + Date.now());
                    launchSuccessOverlay(
                        "Your email notification address has been updated",
                        ""
                    );
                } else if (typeof res.sessionExpired !== 'undefined') {
                    toggleOverlay('#login-overlay', e);
                } else if (typeof res.error !== 'undefined') { // network error
                    launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
                } else {
                    launchErrorOverlay(res.message, '');
                }
            });
        }

        let defaultVal = '';
        try {
            defaultVal = editEmailHold.emailAddress;
        } catch {}

        return (
            <div id="edit-email-notification-overlay" className='overlay-container'>
                <div className='overlay-body'>
                    <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                    <h3>Edit Email Notification</h3>
                    <form onSubmit={updateEmailNotification} id='edit-sierra-pickup-location'>
                        <label htmlFor="edit-email-input">Notification Email Address</label>
                        <input defaultValue={defaultVal} type="text" id="edit-email-input"></input>
                        <button type="submit">Update</button>
                    </form>
                </div>
            </div>
        )
    }

    return (
        <div className="my-account-content-container">
            <h2>Holds</h2>

            <div className="account-top-tiles-container">
                <HoldCount data={holds.data}></HoldCount>
                <HoldFilters data={holds.data}></HoldFilters>
            </div>
            <div className="checkouts-holds-container">
                <Holds data={holds.data}></Holds>
            </div>
            <EditPickupLocationOverlay />
            <EditEmailNotificationOverlay />
        </div>
    )
}

export default HoldsContainer;