async function getData(url = '') {
   
  const response = await fetch(url, {
    method: "GET",
    cache: "no-cache"
  });
  if (!response.ok) {
    return new Error('Network error');
  }
  return response.json();
}

export default getData;