import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import IconSpinner from "../icons/iconSpinner";

const AccessOverlay = (props) => {

    const closeOverlay = (e) => {
        toggleOverlay('#access-overlay', e);
        try {
            document.querySelector('#access-overdrive-container').classList.add('hide');
            document.querySelector('#access-overdrive-container').attributes.src.value = "";
        } catch (e) {console.log(e)}
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    const hideExtra = (e) => {
        // console.log(e);
        try {
            e.target.contentDocument.body.style.overflow = 'hidden';
        } catch {}
    }

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="access-overlay" className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Access</h3>
                <div className="overdrive-access-loading-spinner"><IconSpinner></IconSpinner></div>
                <iframe src="" onLoad={hideExtra} loading="eager" id="access-overdrive-container" className="hide"></iframe>
            </div>
        </div>
    )
}

export default AccessOverlay;