const toggleFilterset = (e) => {
    e.preventDefault();
    const container = e.currentTarget.nextElementSibling;
    const caretIcon = e.currentTarget.lastElementChild;
    if (e.currentTarget.nextElementSibling.classList.contains('closed')) {
        container.classList.remove('closed');
        container.classList.add('open');
        caretIcon.classList.add('flip');
        container.style.height = "auto";
        let height = container.clientHeight + 'px';
        container.style.height = '0px';
        setTimeout(function() {
            container.style.height = height;
        }, 0);
        setTimeout(function() {
            container.style.height = 'unset';
        }, 200);
    } else {
        container.style.height = "auto";
        let height = container.clientHeight + 'px';
        container.style.height = '0px';
        container.style.height = height;
        setTimeout(function(e) {
        container.classList.remove('open');
        container.classList.add('closed');
        caretIcon.classList.remove('flip');
        container.style.height = '0px';
        }, 0);
    }
}

export default toggleFilterset;