import React from "react";
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";
import IconGlobe from "../../icons/iconGlobe";
import IconBuilding from "../../icons/iconBuilding";

const EventVirtualFilters = (props) => { 

  const url = new URL(window.location.href);

  let eventFilterGrouping = 'hide';
  if (url.searchParams.get('event') !== null) {
    eventFilterGrouping = '';
  }

  let virtualActive = 'no';
  let inpersonActive = 'no';

  try {
    if (url.searchParams.get('virtualevent') !== null) {
      virtualActive = 'yes';
    }
  } catch {}

  try {
    if (url.searchParams.get('inpersonevent') !== null) {
      inpersonActive = 'yes';
    }
  } catch {}
  
  return (
    <div id="event-location-filters" className={eventFilterGrouping}>
      <a href="#" className="toggle-filterset" onClick={toggleFilterset} aria-label="expand filter set"><h4>Virtual / In Person</h4><ThickCaretDown flip width="15"></ThickCaretDown></a>
      <div className="filterset open">
        <button onClick={props.filterProps.functions.updateSearchData} activated={virtualActive} filtertype='event-virtual' filterval="Virtual" value="Virtual" className="filter-square-btn"><IconGlobe />Virtual Events</button>
        <button onClick={props.filterProps.functions.updateSearchData} activated={inpersonActive} filtertype='event-in-person' filterval="in-person" value="in-person" className="filter-square-btn"><IconBuilding />In Person Events</button>
      </div>
      <hr></hr>
    </div>
  )
  
}

export default EventVirtualFilters;