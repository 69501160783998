import React from "react";
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";
import IconCoffee from "../../icons/iconCoffee";
import IconSun from "../../icons/iconSun";
import IconMoon from "../../icons/iconMoon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";

const EventDateFilters = (props) => { 

  const url = new URL(window.location.href);

  const updateSearchHandler = props.filterProps.functions.updateSearchData;

  let eventFilterGrouping = 'hide';
  if (url.searchParams.get('event') !== null) {
    eventFilterGrouping = '';
  }

  let sunActive = 'no';
  let monActive = 'no';
  let tueActive = 'no';
  let wedActive = 'no';
  let thuActive = 'no';
  let friActive = 'no';
  let satActive = 'no';

  if (url.searchParams.get('dayofweek') !== null) {
    const separatedFilters = url.searchParams.get('dayofweek').split('||');

    if (separatedFilters.includes('Sun')) {
      sunActive = 'yes';
    }

    if (separatedFilters.includes('Mon')) {
      monActive = 'yes';
    }

    if (separatedFilters.includes('Tue')) {
      tueActive = 'yes';
    }

    if (separatedFilters.includes('Wed')) {
      wedActive = 'yes';
    }

    if (separatedFilters.includes('Thu')) {
      thuActive = 'yes';
    }

    if (separatedFilters.includes('Fri')) {
      friActive = 'yes';
    }

    if (separatedFilters.includes('Sat')) {
      satActive = 'yes';
    }

  }

  let morningActive = 'no';
  let afternoonActive = 'no';
  let eveningActive = 'no';

  if (url.searchParams.get('timeofday') !== null) {
    const separatedTimeFilters = url.searchParams.get('timeofday').split('||');

    if (separatedTimeFilters.includes('Morning')) {
      morningActive = 'yes';
    }

    if (separatedTimeFilters.includes('Afternoon')) {
      afternoonActive = 'yes';
    }

    if (separatedTimeFilters.includes('Evening')) {
      eveningActive = 'yes';
    }
  }

  const marginBottom = {marginBottom: '20px'};

  const ExampleDatePicker = () => {

    let defaultDate = new Date();
    if (url.searchParams.get('eventdate') !== null) {
      defaultDate = new Date(url.searchParams.get('eventdate'));
    } 
    const [startDate, setStartDate] = useState(defaultDate);
    return (
      <DatePicker id="event-date-input" selected={startDate} onChange={(date) => setStartDate(date)} />
    );
  };

  
  try {
    return (
      <div id="event-date-filters" className={eventFilterGrouping}>
          <a href="#" className="toggle-filterset" onClick={toggleFilterset} aria-label="expand filter set"><h4>Event Date/Time</h4><ThickCaretDown flip width="15"></ThickCaretDown></a>
          <div className="filterset open">
            <div>

              <div style={marginBottom}>
                <div className="event-date-error-msg hide">Please enter date in format MM-DD-YYYY</div>
                <label htmlFor="event-date-input">Jump to start date</label>
                <div className="flex">
                  
                  <ExampleDatePicker />
                  <button onClick={updateSearchHandler} id="apply-event-date-input">Apply</button>
                </div>
                
              </div>

              <div style={marginBottom} className="flex wrap">
                <button onClick={props.filterProps.functions.updateSearchData} activated={morningActive} filtertype="timeofday" filterval="Morning" className="filter-square-btn"><IconCoffee />Morning</button>
                <button onClick={props.filterProps.functions.updateSearchData} activated={afternoonActive} filtertype="timeofday" filterval="Afternoon" className="filter-square-btn"><IconSun />Afternoon</button>
                <button onClick={props.filterProps.functions.updateSearchData} activated={eveningActive} filtertype="timeofday" filterval="Evening" className="filter-square-btn"><IconMoon />Evening</button>
              </div>

              <div className="flex wrap">
                <a onClick={updateSearchHandler} filtertype="dayofweek" filterval="Sun" activated={sunActive} className="filter-pill-button" href="#">Su</a>
                <a onClick={updateSearchHandler} filtertype="dayofweek" filterval="Mon" activated={monActive} className="filter-pill-button" href="#">M</a>
                <a onClick={updateSearchHandler} filtertype="dayofweek" filterval="Tue" activated={tueActive} className="filter-pill-button" href="#">T</a>
                <a onClick={updateSearchHandler} filtertype="dayofweek" filterval="Wed" activated={wedActive} className="filter-pill-button" href="#">W</a>
                <a onClick={updateSearchHandler} filtertype="dayofweek" filterval="Thu" activated={thuActive} className="filter-pill-button" href="#">Th</a>
                <a onClick={updateSearchHandler} filtertype="dayofweek" filterval="Fri" activated={friActive} className="filter-pill-button" href="#">F</a>
                <a onClick={updateSearchHandler} filtertype="dayofweek" filterval="Sat" activated={satActive} className="filter-pill-button" href="#">Sa</a>
              </div>

            </div>
          </div>
          <hr></hr>
      </div>
    )
  } catch {}
}

export default EventDateFilters;