
const XIcon = (props) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path d="M8.62164 7L13.1607 2.46095C13.3761 2.24591 13.4973 1.9541 13.4976 1.64971C13.4978 1.34533 13.3772 1.0533 13.1621 0.837879C12.9471 0.622457 12.6553 0.501283 12.3509 0.501014C12.0465 0.500745 11.7545 0.621403 11.5391 0.836446L7 5.3755L2.46095 0.836446C2.24553 0.621023 1.95335 0.5 1.6487 0.5C1.34404 0.5 1.05187 0.621023 0.836446 0.836446C0.621023 1.05187 0.5 1.34404 0.5 1.6487C0.5 1.95335 0.621023 2.24553 0.836446 2.46095L5.3755 7L0.836446 11.5391C0.621023 11.7545 0.5 12.0466 0.5 12.3513C0.5 12.656 0.621023 12.9481 0.836446 13.1636C1.05187 13.379 1.34404 13.5 1.6487 13.5C1.95335 13.5 2.24553 13.379 2.46095 13.1636L7 8.6245L11.5391 13.1636C11.7545 13.379 12.0466 13.5 12.3513 13.5C12.656 13.5 12.9481 13.379 13.1636 13.1636C13.379 12.9481 13.5 12.656 13.5 12.3513C13.5 12.0466 13.379 11.7545 13.1636 11.5391L8.62164 7Z" fill="#474851"/>
    </svg>
  );
  
}

export default XIcon;
