import SortSearch from './sortSearch';
import NumberOfResults from './numberOfResults';
import SearchResults from './searchResults';
import HomePageDiscovery from './homePageDiscovery';
import Pagination from './pagination';
import Spellcheck from "./spellcheck";
import IconEllipsis from '../icons/iconEllipsis';
import SearchSparkleTip from './searchSparkleTip';
import SearchCarouselTip from './searchCarouselTip';

const SearchResultsContainer = (props) => {
    
    const url = new URL(window.location.href);

    if (url.search === '') {
        // HOME PAGE CAROUSELS
        return (
            <HomePageDiscovery></HomePageDiscovery>
        )
    }

    const MoreActions = (props) => {
        const showMoreActions = (e) => {
            try {
                if (e.currentTarget.nextSibling.classList.contains('hide')) {
                    e.currentTarget.nextSibling.classList.remove('hide');
                } else {
                    e.currentTarget.nextSibling.classList.add('hide');
                }
            } catch {}
        }
        //console.log(props);
        try {
            return (
                <div className="booklist-more-actions-container">
                    
                    <button onClick={showMoreActions} title="More actions" className="booklist-more-actions-btn"><IconEllipsis height="30"></IconEllipsis><span className='sr-only'>More actions</span></button>
                    <div className="more-actions-dropdown-container hide">
                        <div className="more-actions-dropdown">
                            <button onClick={()=>{window.location.href = '/api/misc/list-pdf-export.php?id=' + props.solrData.original_list.booklist_id;}} className="list-export-btn main-color">Export PDF</button>
                        </div>
                    </div>
                </div>
              )
        } catch {}
    }

    const BooklistItemCount = (props) => {

        // i don't like, so am removing...
        return '';

        try {
            let count = props.solrData.response.docs.length;

            if (count === 1) {
                return (
                    <div className='booklist-item-count'>1 item</div>
                )
            }

            let countPretty = count.toLocaleString();
            return (
                <div className='booklist-item-count'>{countPretty} items</div>
            )

        } catch {}
    }

    if (url.searchParams.get('booklist') !== null) {
        return (
            <div className='search-results-container'>
                
                <div className='sort-and-num-results-container'>
                    <SortSearch solrData={props.solrData} filterProps={props.filterProps} ></SortSearch>
                    <MoreActions solrData={props.solrData} />
                </div>
                
                <BooklistItemCount solrData={props.solrData}/>
                
                <SearchResults setSelectedBibId={props.setSelectedBibId} updateSearchData={props.filterProps.functions.updateSearchData} solrData={props.solrData}/>
            </div>
        )
    }

    // DEFAULT IS SEARCH RESULTS
    return (
        <div className='search-results-container'>

            <Spellcheck solrData={props.solrData}></Spellcheck>

            <div className='sort-and-num-results-container'>
                <SortSearch solrData={props.solrData} filterProps={props.filterProps} ></SortSearch>
                <NumberOfResults></NumberOfResults>
            </div>
            
            <SearchSparkleTip />
            <SearchCarouselTip />
            <SearchResults setSelectedBibId={props.setSelectedBibId} updateSearchData={props.filterProps.functions.updateSearchData} solrData={props.solrData}/>
            <Pagination updateSearchData={props.filterProps.functions.updateSearchData} solrData={props.solrData}></Pagination>
        </div>
    )
    
    
}

export default SearchResultsContainer;