import '../../App.css';
import NavBar from "../shared/navBar";
import Footer from '../shared/footer';
import OverlayContainer from '../overlays/overlayContainer';
import BreadCrumb from '../shared/breadcrumb';
import BottomNav from '../shared/bottomNav';
import { useState } from 'react';
import NoInternetMessage from '../shared/noInternetMessage';


function ItemPageError() {

  const [loginState, setLoginState] = useState('');

  return (
    <div className="App item-page">
      <NavBar></NavBar>
      <NoInternetMessage />
      <div className="main-app-container">
        <header className="app-header">
          <BreadCrumb></BreadCrumb>
          <div className="item-page-header-flex-container">
            <div className="item-page-header-text">
              <h1>Title not found</h1>
              <p>
                Sorry, we couldn't locate this title! It may have been removed
                from our collection.
              </p>
            </div>
          </div>
        </header>
      </div>
      <Footer></Footer>
      <BottomNav></BottomNav>
      <OverlayContainer
        setLoginState={setLoginState}
        
      ></OverlayContainer>
    </div>
  );

}

export default ItemPageError;
