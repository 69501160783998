
const Summary = (props) => {
  try {
    if (props.text === '') {
      return '';
    }
    return (
      <div className="summary">
        <h3>Summary</h3>
        <div>{props.text}</div>
        <div>{props.transliteration}</div>
      </div>
    )
  } catch {}
};

export default Summary;