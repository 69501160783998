import toggleOverlay from "../../functions/toggleOverlay";
import postData from "../../functions/postData";
import ThinX from '../icons/thinX';
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import {useContext} from "react";
import { UserContext } from '../..';
import getCookie from "../../functions/getCookie";
import configs from "../../functions/configs";
import clearCache from "../../functions/clearCache";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import detectUserAgent from "../../functions/detectUserAgent";

function LoginOverlay (props) {

  const conf = configs();

  const user = useContext(UserContext);

  const closeOverlay = (e) => {
    toggleOverlay('#login-overlay', e);
  }

  const containerClick = (e) => {
    if (e.target.classList.contains('overlay-container')) {
        closeOverlay();
    }
  }

  let touchstartY = 0;
  let touchendY = 0;
  const handleTouchStart = (event) => {
      touchstartY = event.touches[0].screenY;
  }
  const handleTouchMove = (event) => {
      touchendY = event.touches[0].screenY;
  }
  const handleTouchEnd = (event) => {
      if (touchendY > (touchstartY + 300)) {
          closeOverlay();
      }
      touchstartY = 0;
      touchendY = 0;
  }

  const loginSubmit = (e) => {
    e.preventDefault();
    clearCache();
    document.querySelector('#login-overlay-invalid-credential-msg').classList.add('hide');

    try {
      document.querySelector("#login-overlay .close-overlay").focus();
    } catch {}

    let params = 'code=' + encodeURIComponent(document.querySelector('#overlay-login-barcode').value.replace(/ /g, ''));
    params += '&pin=' + encodeURIComponent(document.querySelector('#overlay-login-pin').value);
    params += '&agent=' + detectUserAgent();

    toggleLoadingOverlay();

    postData(conf.baseAPI + "/user/login.php", params).then( res => {
  
      toggleLoadingOverlay();

      if (res.description === "Invalid parameter : Invalid barcode or PIN") {
        document.querySelector('#login-overlay-invalid-credential-msg').classList.remove('hide');
      } else if (typeof res.error !== 'undefined') { // network error
        document.querySelector('#edit-names-overlay button.close-overlay').click();
        launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
      } else if (typeof res.name !== 'undefined') { // other error
        document.querySelector('#edit-names-overlay button.close-overlay').click();
        launchErrorOverlay('Sorry, a network error occurred. ' + res.name);
      } else {
        document.querySelector("#login-overlay .close-overlay").click();
        user.data.isSession = true;
        user.data.session = res;
        try {
          delete user.data.session.bpl_catalog.pin;
          delete user.data.session.bpl_catalog.overdrive_api_key;
        } catch {}

        props.setLoginState('loggedin');

        try {
          document.querySelector('a.login-logout-button').innerHTML = "Logout";
          document.querySelector('a.login-logout-button').setAttribute('href', '/logout'); 
        } catch (err) {console.log(err)}

        setTimeout(() => {
          try {
            let selector = getCookie('opacLC');
            document.querySelector(selector).click();
          } catch {}
        }, 300);
      }

    });
  }

  return (
    <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="login-overlay" className='overlay-container'>
      <div className='overlay-body'>
        <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
        <h3>Log In</h3>

        <div id="login-overlay-invalid-credential-msg" className="hide">Invalid credentials. Please try again, or <a href="/pin-reset-form">reset your pin</a>.</div>

        <form onSubmit={loginSubmit} id="overlay-login-form">
          <label htmlFor="overlay-login-barcode">Barcode/Username</label>
          <input id="overlay-login-barcode" type="text"></input>

          <br></br>
          <label htmlFor="overlay-login-pin">PIN</label>
          <input id="overlay-login-pin" type="password"></input>

          <br></br>
          <a href="/pin-reset">Reset pin</a> or <a href="https://www.bklynlibrary.org/card/">get a card</a>

          <button type="submit">Log in</button>
        </form>
        
      </div>
    </div>
  )

}

export default LoginOverlay;