const launchErrorOverlay = (textMarkup = 'Sorry, an error occurred. Try again, or contact us if this issue persists', linkMarkup = '<a href="https://www.bklynlibrary.org/contact">Contact Us</a>') => {
    const overlay = document.querySelector("#error-overlay");
    try {
        document.querySelector("#success-overlay").classList.add('hide');
    } catch {}
    if (overlay.classList.contains('hide')) {
        document.querySelector('#error-overlay .error-overlay-text').innerHTML = textMarkup;
        document.querySelector('#error-overlay .error-overlay-link').innerHTML = linkMarkup;
        document.querySelector('#error-overlay .error-overlay-text').focus();
        overlay.classList.remove('hide');
        setTimeout(() => { 
            overlay.classList.add('hide');
        }, 7000);
    } else {
        overlay.classList.add('hide');
        setTimeout(()=>{
            document.querySelector('#error-overlay .error-overlay-text').innerHTML = textMarkup;
            document.querySelector('#error-overlay .error-overlay-link').innerHTML = linkMarkup;
            document.querySelector('#error-overlay .error-overlay-text').focus();
            overlay.classList.remove('hide');
            setTimeout(() => { 
                overlay.classList.add('hide');
            }, 7000);
        }, 50);
    }
}

export default launchErrorOverlay;