import toggleOverlay from '../../functions/toggleOverlay';
import {useContext} from "react";
import { UserContext } from '../..';
import setCookie from '../../functions/setCookie';
import configs from '../../functions/configs';
import IconSparkles from "../icons/iconSparkles";

const RebalancingClaimButton = (props) => {

  const user = useContext(UserContext);

  const conf = configs();

  const rebalancingClaim = (e) => {

    setCookie('rebalancingBibs', e.currentTarget.attributes.bibs.value);

    toggleOverlay('#rebalacing-claim-overlay', e);

    document.querySelector('#update-rebalancing-items-overlay').click();

  }

  try {

    let bibList = '';

    let rebalancingBtnClasses = "rebalancing-claim-button hide";

    try {
      if (typeof props.data.id !== 'undefined') {

        bibList = props.data.id;
        if (typeof props.data.sm_items_up_for_grabs !== 'undefined') {
          rebalancingBtnClasses = "rebalancing-claim-button";
        }

      } else if (typeof props.data[0] !== 'undefined') {
        
        for (let i = 0; i < props.data.length; i++) {
          bibList += props.data[i].id + ',';
          if (typeof props.data[i].sm_items_up_for_grabs !== 'undefined') {
            rebalancingBtnClasses = "rebalancing-claim-button";
          }
        }

        bibList = bibList.replace(/\,$/, '');
      }
    } catch {}

    if (user.data.isSession) {
      if (conf.rebalancingPtypes.indexOf(user.data.session.bpl_catalog.ptype) > -1) {
        return (
          <button onClick={rebalancingClaim} bibs={bibList} className={rebalancingBtnClasses}><IconSparkles /> Rebalancing Claim</button>
        )
      }
    }

  } catch {}
  
};

export default RebalancingClaimButton;
