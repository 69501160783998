const launchSuccessOverlay = (textMarkup = '', linkMarkup = '') => {
    const overlay = document.querySelector("#success-overlay");
    try {
        document.querySelector("#error-overlay").classList.add('hide');
    } catch {}
    if (overlay.classList.contains('hide')) {
        document.querySelector('#success-overlay .success-overlay-text').innerHTML = textMarkup;
        document.querySelector('#success-overlay .success-overlay-link').innerHTML = linkMarkup;
        document.querySelector('#success-overlay .success-overlay-text').focus();
        overlay.classList.remove('hide');
        setTimeout(() => { 
            overlay.classList.add('hide');
        }, 5000);
    } else {
        overlay.classList.add('hide');
        setTimeout(() => {
            document.querySelector('#success-overlay .success-overlay-text').innerHTML = textMarkup;
            document.querySelector('#success-overlay .success-overlay-link').innerHTML = linkMarkup;
            document.querySelector('#success-overlay .success-overlay-text').focus();
            overlay.classList.remove('hide');
            setTimeout(() => { 
                overlay.classList.add('hide');
            }, 5000);
        }, 50);
    }
}

export default launchSuccessOverlay;