import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import { useContext } from "react";
import { UserContext } from "../..";

const DigitalCardOverlay = (props) => {

    const user = useContext(UserContext);

    const closeOverlay = (e) => {
        toggleOverlay('#digital-card-overlay', e);
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    const DigitalCardContent = () => {
        try {
            let name = user.data.session.bpl_catalog.name;
            let barcode = user.data.session.bpl_catalog.barcode;
            let expiration = user.data.session.bpl_catalog.expiration_date;
            let imgSrc = "/api/user/barcode-image.php?text=" + barcode + "&size=80&codetype=Codabar&sizefactor=2";

            return (
                <div className="digital-card-container">
                    <div><img className="digital-card-img" alt="Brooklyn Library Logo" src="/blue-bpl-logo.svg"></img></div>
                    <div className="main-color">{name}</div>
                    <div>{barcode}</div>
                    <div>Expires: {expiration}</div>
                    <div><img className="barcode" alt="barcode image" src={imgSrc}></img></div>
                </div>
            )
        } catch {}
    }

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="digital-card-overlay" className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Digital Card</h3>
                
                <DigitalCardContent></DigitalCardContent>
                
            </div>
        </div>
    )
} 

export default DigitalCardOverlay;