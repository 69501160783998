const MagnifyingGlassIcon = (props) => {

  let height = '16';

  if (typeof props.height !== 'undefined') {
    height = props.height;
  }

  let fill = '#2C2C2C';

  if (typeof props.fillColor !== 'undefined') {
    fill = props.fillColor;
  }

  return (
    <svg height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" fill="none">
      <path stroke={fill} d="M6.90906 2.33057C5.93814 2.33057 4.98903 2.61848 4.18174 3.15789C3.37444 3.69731 2.74524 4.464 2.37368 5.36101C2.00213 6.25803 1.90491 7.24507 2.09433 8.19734C2.28375 9.1496 2.75129 10.0243 3.43783 10.7109C4.12438 11.3974 4.99909 11.8649 5.95135 12.0544C6.90362 12.2438 7.89067 12.1466 8.78768 11.775C9.6847 11.4035 10.4514 10.7742 10.9908 9.96696C11.5302 9.15967 11.8181 8.21055 11.8181 7.23963C11.818 5.93769 11.3008 4.6891 10.3802 3.76849C9.45959 2.84788 8.211 2.33065 6.90906 2.33057Z" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path stroke={fill} d="M10.5718 10.9021L14.0002 14.3305" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>
  )
  
};

export default MagnifyingGlassIcon;