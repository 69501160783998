import { SolrContext } from "../../../searchPage";
import React, { useContext } from "react";
import { useState } from "react";
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";
import { UserContext } from "../../..";
import configs from "../../../functions/configs";

const CatalogRebalancingBranchFilters = (props) => { 

  const user = useContext(UserContext);

  const [hideExtraBranches, updateHideExtraBranches] = useState(true);

  const solrData = React.useContext(SolrContext);

  const url = new URL(window.location.href);

  const conf = configs();

  const updateSearchHandler = props.filterProps.functions.updateSearchData;

  let catalogFilterGrouping = props.catalogFilterGrouping;

  let branchCount = 0;
  
  const showMoreBranches = (e) => {
    e.preventDefault();
    let height = window.scrollY;
    if (hideExtraBranches) {
      updateHideExtraBranches(false);
    } else {
      updateHideExtraBranches(true);
      height = window.scrollY + document.querySelector('#catalog-branch-filters').getBoundingClientRect().top - 50;
      window.scrollTo({top: height, behavior: 'smooth'});
    }
  }

  const BranchOption = (props) => {
    
    branchCount++;
    let containerClasses = 'filter-checkbox-container';
    let idVal = 'rebalancing-branch-' + encodeURIComponent(props.data.val);
    if (branchCount > 20 && hideExtraBranches) {
      containerClasses = 'filter-checkbox-container hide';
    }
    let isChecked = false;
    if (url.searchParams.get('rebalancingloc') !== null) {
      const values = url.searchParams.get('rebalancingloc').split('||');
      if (values.indexOf(props.data.val) > -1) {
        isChecked = true;
      }
    }
    if (isChecked) {
      return (
        <div className={containerClasses}>
          <input filtertype='rebalancing-branch' checked='checked' value={props.data.val} onChange={updateSearchHandler} id={idVal} type="checkbox"></input><label htmlFor={idVal}>{props.data.val}</label>
        </div>
      )
    }
    return (
      <div className={containerClasses}>
        <input filtertype='rebalancing-branch' value={props.data.val} onChange={updateSearchHandler} id={idVal} type="checkbox"></input><label htmlFor={idVal}>{props.data.val}</label>
      </div>
    )
  }

  const BranchOptions = () => {
    return(
      solrData.facets.rebalancing_locations.buckets.map((item) => <BranchOption key={item.val} data={item}></BranchOption>)
      )
  }

  const ShowMoreBranchesLink = () => {
    if (branchCount > 20) {
      if (hideExtraBranches) {
        return (<a href="#" className="show-more-filters-link" onClick={showMoreBranches}><span className="show-more-text-span">Show more branches</span> <ThickCaretDown width='10px'></ThickCaretDown></a>)
      }
      return (<a href="#" className="show-more-filters-link" onClick={showMoreBranches}><span className="show-more-text-span">Show fewer branches</span> <ThickCaretDown width='10px' flip='flip'></ThickCaretDown></a>)
    }
  }

  try {
    if (!conf.rebalancingPtypes.includes(user.data.session.bpl_catalog.ptype)) {
      return '';
    }
  } catch { return ''}

  try {
    if (solrData.facets.rebalancing_locations.buckets.length > 0) {
      return (
        <div className={catalogFilterGrouping}>
            <a href="#" id="catalog-rebalancing-filters" className="toggle-filterset" onClick={toggleFilterset} aria-label="expand filter set"><h4>Rebalancing Locations</h4><ThickCaretDown flip width="15"></ThickCaretDown></a>
            <div className="filterset open">
              <div>
                <BranchOptions></BranchOptions>
                <ShowMoreBranchesLink></ShowMoreBranchesLink>
              </div>
            </div>
            <hr></hr>
        </div>
      )
    }
  } catch {}

}

export default CatalogRebalancingBranchFilters;