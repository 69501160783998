let activeElement = null

const toggleOverlay = (cssSelector, event) => {
    //console.log(event);
    const overlay = document.querySelector(cssSelector);
    if (overlay.classList.contains('overlay-active')) {
        overlay.querySelector('.overlay-body').classList.remove('active');
        overlay.classList.add('transition-state');
        setTimeout(() => { 
            overlay.classList.remove('overlay-active');
            overlay.classList.remove('transition-state');
            document.body.classList.remove('overlay-mode');
            try {
                activeElement.focus();
            } catch {}
        }, 200);
    } else {
        try {
            activeElement = event.currentTarget;
        } catch {}
        
        overlay.classList.add('overlay-active');
        document.body.classList.add('overlay-mode');
        setTimeout(() => { 
            overlay.querySelector('.overlay-body').classList.add('active');
        }, 0);

        setTimeout(() => { 
            overlay.querySelector('button').focus();
        }, 200);
    }
}

export default toggleOverlay;