import React from "react";
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";

const EventAgeFilters = (props) => { 

  const url = new URL(window.location.href);

  const updateSearchHandler = props.filterProps.functions.updateSearchData;

  let eventFilterGrouping = 'hide';
  if (url.searchParams.get('event') !== null) {
    eventFilterGrouping = '';
  }

  let birthActive = 'no';
  let kidsActive = 'no';
  let teensActive = 'no';
  let adultsActive = 'no';
  let olderAdultsActive = 'no';

  if (url.searchParams.get('eventage') !== null) {
    const separatedFilters = url.searchParams.get('eventage').split('||');

    if (separatedFilters.includes('Birth to Five Years')) {
      birthActive = 'yes';
    }

    if (separatedFilters.includes('Kids')) {
      kidsActive = 'yes';
    }

    if (separatedFilters.includes('Teens & Young Adults')) {
      teensActive = 'yes';
    }

    if (separatedFilters.includes('Adults')) {
      adultsActive = 'yes';
    }

    if (separatedFilters.includes('Older Adults')) {
      olderAdultsActive = 'yes';
    }

  }

  try {
    return (
      <div id="event-age-filters" className={eventFilterGrouping}>
          <a href="#" className="toggle-filterset" onClick={toggleFilterset} aria-label="expand filter set"><h4>Event Age</h4><ThickCaretDown flip width="15"></ThickCaretDown></a>
          <div className="filterset open">
            <div className="flex wrap">
              <a onClick={updateSearchHandler} filtertype="eventage" filterval="Birth to Five Years" activated={birthActive} className="filter-pill-button" href="#">Birth to Five</a>
              <a onClick={updateSearchHandler} filtertype="eventage" filterval="Kids" activated={kidsActive} className="filter-pill-button" href="#">Kids</a>
              <a onClick={updateSearchHandler} filtertype="eventage" filterval="Teens & Young Adults" activated={teensActive} className="filter-pill-button" href="#">Teens</a>
              <a onClick={updateSearchHandler} filtertype="eventage" filterval="Adults" activated={adultsActive} className="filter-pill-button" href="#">Adults</a>
              <a onClick={updateSearchHandler} filtertype="eventage" filterval="Older Adults" activated={olderAdultsActive} className="filter-pill-button" href="#">Older Adults</a>
            </div>
          </div>
          <hr></hr>
      </div>
    )
  } catch {}
}

export default EventAgeFilters;