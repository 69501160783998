import MagnifyingGlassIcon from '../icons/magnifyingGlassIcon';
import { SolrContext } from '../../searchPage';
import { useContext, useState } from 'react';

const FilterSearchbar = (props) => {

  const inputData = props.inputData;
  const filterType = props.filterType;
  const updateSearchHandler = props.updateSearchHandler;

  const [autocompleteData, setAutocompleteData] = useState([]);

  const getAutocomplete = (e) => {
    if (e.currentTarget.value.trim() === '') {
      setAutocompleteData([]);
      return '';
    }
    try {
      const autocompleteArray = [];
      for (let i = 0; i < inputData.length; i++) {
        if (inputData[i].toLowerCase().trim().indexOf(e.currentTarget.value.toLowerCase().trim()) > -1) {
          autocompleteArray.push(inputData[i]);
        }
        if (autocompleteArray.length > 9) {
          break;
        }
      }
      setAutocompleteData(autocompleteArray);
    } catch {}
  }

  const applyAutocomplete = (e) => {
    updateSearchHandler(e);
    setAutocompleteData([]);
  }

  const AutocompleteOption = (props) => {
    return (
      <button onClick={applyAutocomplete} filtertype={filterType} filterval={props.data} value={props.data} className='filters-autocomplete-link'>{props.data}</button>
    )
  }

  const AutcompleteOptions = () => {
    if (autocompleteData.length === 0) {
      return '';
    } 
    console.log(autocompleteData);
    try {
      return autocompleteData.map(key=> <AutocompleteOption key={key} data={key} />);
    } catch {}
  }
  
  let searchbarContainerClasses = 'filterset-autocomplete-search';

  try {
    if (autocompleteData.length > 0) {
      searchbarContainerClasses = 'filterset-autocomplete-search autocomplete-active';
    }
  } catch {}

  return (
    <div className={searchbarContainerClasses}>
      <div className='flex'>
        <label htmlFor={props.inputid} className='sr-only'>Quick lookup</label>
        <input
          className="filter-search-input"
          type="text"
          placeholder="Quick lookup"
          name='quick lookup'
          id={props.inputid}
          onChange={getAutocomplete}
          defaultValue=""
          autoComplete='off'
        >
        </input>
        <MagnifyingGlassIcon />
      </div>
      <div className=''>
        <AutcompleteOptions />
      </div>
    </div>
  )
}

export default FilterSearchbar;