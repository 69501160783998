
const ThinX = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} viewBox="0 0 12 12" fill="none">
      <path d="M11 0.830566L1 10.8306" stroke="#4A4C7C"/>
      <path d="M1 0.830566L11 10.8306" stroke="#4A4C7C"/>
    </svg>
  )
};

export default ThinX;
