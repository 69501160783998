import toggleFiltersOverlay from '../../functions/toggleFiltersOverlay';
import IconFilters from "../icons/iconFilters";

const ToggleFiltersOverlayButton = (props) => {

    return (            
        <button onClick={toggleFiltersOverlay} className='launch-filters-btn'><IconFilters height="15"></IconFilters> Filters</button>
    )
    
}

export default ToggleFiltersOverlayButton;