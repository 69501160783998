
const DuotoneCircleArrowUp = (props) => {

  let height = 16;
    
    if (typeof props.height !== 'undefined') {
        height = props.height
    } 
    
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} viewBox="0 0 512 512">{/*<!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--> */}
    <path className="fa-secondary" opacity="0.7" fill="#484e80" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM385 231c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-71-71V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V193.9l-71 71c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 119c9.4-9.4 24.6-9.4 33.9 0L385 231z"/>
    <path className="fa-primary" opacity="0.7" fill="#ffffff" d="M239 119c9.4-9.4 24.6-9.4 33.9 0L385 231c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-71-71V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V193.9l-71 71c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 119z"/>
    </svg>
  )
};

export default DuotoneCircleArrowUp;
