
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";
import IconWizard from "../../icons/iconWizard";
import IconAtom from "../../icons/iconAtom";

const CatalogFictionFilters = (props) => { 

  const url = new URL(window.location.href);

  let fictionActive = 'no';
  if (url.searchParams.get('fiction') === 'Fiction') {
    fictionActive = 'yes';
  }

  let nonFictionActive = 'no';
  if (url.searchParams.get('fiction') === 'Nonfiction') {
    nonFictionActive = 'yes';
  }

  return (
    <div id="catalog-filterset-fiction" className={props.catalogFilterGrouping}>
      <a href="#" className="toggle-filterset fiction" onClick={toggleFilterset} aria-label="expand filter set"><h4>Fiction / Nonfiction</h4><ThickCaretDown width="15"></ThickCaretDown></a>
      <div className="fiction-filters filterset closed">
        <button onClick={props.filterProps.functions.updateSearchData} activated={fictionActive} filtertype="catalog-fiction" filterval="Fiction" className="filter-square-btn"><IconWizard />Fiction</button>
        <button onClick={props.filterProps.functions.updateSearchData} activated={nonFictionActive} filtertype="catalog-fiction" filterval="Nonfiction" className="filter-square-btn"><IconAtom />Nonfiction</button>
      </div>
      <hr></hr>
    </div>
  )

}

export default CatalogFictionFilters;