const SuccessOverlay = (props) => {

    return (
        <div id="success-overlay" className="hide">
            <div className="success-overlay-container">
                <div className="bold">Success!</div>
                <div className="flex-small-gap">
                    <div className="success-overlay-text"></div>
                    <div className="success-overlay-link"></div>
                </div>
            </div>
        </div>
    )
}

export default SuccessOverlay;