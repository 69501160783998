import { useEffect, useState } from "react";
import configs from "../../functions/configs";
import postData from "../../functions/postData";
import IconSparkles from "../icons/iconSparkles";

let grabbedSpellcheck = false;
const Spellcheck = (props) => {

  const url = new URL(window.location.href);
  const conf = configs();

  const [spellcheckState, setSpellcheckState] = useState([]);

  let letsGo = false;

  try {
    if (typeof props.solrData.grouped !== 'undefined') {
      if (props.solrData.grouped.ss_grouping.matches === 0) {
        letsGo = true;
      }
    }
    if (typeof props.solrData.response !== 'undefined') {
      if (props.solrData.response.docs.length === 0) {
        letsGo = true;
      }
    }
    if (url.searchParams.get('search') === null) {
      letsGo = false;
    }
    if (url.searchParams.size > 2) {
      letsGo = false;
    }
  } catch {}
  

  useEffect(()=>{
    if (letsGo && grabbedSpellcheck === false) {
      postData(conf.baseAPI + '/solr/spellcheck.php', 'query=' + url.searchParams.get('search')).then(res => {
        grabbedSpellcheck = true;
        setSpellcheckState(res);
        console.log(res);
        
      });
    }
  },[props.solrData]);
  
  
  if (letsGo === false) {
    return '';
  }

  try {

    console.log('spellcheckState', spellcheckState);
    if (typeof spellcheckState.ai_results !== 'undefined') {
      if (spellcheckState.ai_results.candidates[0].content.parts[0].text.indexOf('**') > -1) { // markdown response, check for content in solr and format pretty
        let textResponse = spellcheckState.ai_results.candidates[0].content.parts[0].text;
        console.log('has markdown', textResponse);
        let linkText = textResponse.match(/\*\*.+\*\*/)[0].replace(/\*/g, '');
        let link = '/?search=' + linkText;

        textResponse = textResponse.replace(linkText, ' ');

        return (
          <div className="search-sparkle-tip">
              <div><IconSparkles /></div>
              <div>Looking for <a href={link}>{linkText}</a>?</div>
          </div>
        )
      } else { // plain text response 
        let textResponse = spellcheckState.ai_results.candidates[0].content.parts[0].text;
        console.log('no markdown', textResponse);
        return (
          <div className="search-sparkle-tip">
              
              <div><IconSparkles /></div>
              <div>Looking for {textResponse}?</div>
              
          </div>
        )
      }
    }
    
    if (spellcheckState.length > 0) {

      let link ="/?search=" + spellcheckState[0].suggestion;
      if (spellcheckState[0].suggestion.toLowerCase().trim() === url.searchParams.get('search').toLowerCase().trim()) {
        return '';
      }
      return (
        <div className="spellcheck-container">
          Did you mean <a href={link}>{spellcheckState[0].suggestion}</a>?
        </div>
      )
    } else {
      return '';
    }
    
  } catch (err) {console.log(err)}
};

export default Spellcheck;