const ErrorOverlay = (props) => {

    return (
        <div id="error-overlay" className="hide">
            <div className="error-overlay-container">
                
                <div className="bold">Error</div>
                    
                <div className="flex-small-gap">
                    <div className="error-overlay-text"></div>
                    <div className="error-overlay-link"></div>
                </div>
                
            </div>
        </div>
    )
}

export default ErrorOverlay;