import capitalizeFirstLetter from "./capitalizeFirstLetter";

const getFirstName = (name) => {
    try {
        let firstName = name.replace(/.+,/, '')
        firstName = firstName.trim();
        firstName = firstName.toLowerCase();
        firstName = capitalizeFirstLetter(firstName);
        firstName = firstName.replace(/ .+/, '');
        return firstName;
    } catch {}
}

export default getFirstName;