import { SolrContext } from "../../../searchPage";
import React from "react";
import { useState } from "react";
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";

const WebsiteBlogTypeFilter = (props) => { 

  const solrData = React.useContext(SolrContext);

  const url = new URL(window.location.href);

  const updateSearchHandler = props.filterProps.functions.updateSearchData;

  let websiteFilterGrouping = 'hide';
  if (url.searchParams.get('website') !== null) {
    websiteFilterGrouping = '';
  }

  let count = 0;

  let sc = 0; // see counts
  try {
      sc = parseInt(localStorage.getItem("seeCountsVisible"));
  } catch {}

  const Option = (props) => {
    count++;
    let containerClasses = 'filter-checkbox-container';
    let idVal = 'blog-type-' + encodeURIComponent(props.data.val);
    let isChecked = false;
    if (url.searchParams.get('blogtype') !== null) {
      const values = url.searchParams.get('blogtype').split('||');
      if (values.indexOf(props.data.val) > -1) {
        isChecked = true;
      }
    }

    let countPretty = props.data.count.toLocaleString();

    if (isChecked) {

      if (sc === 1) {
        return (
          <div className={containerClasses}>
            <input filtertype='website-blog-type' checked='checked' value={props.data.val} onChange={updateSearchHandler} id={idVal} type="checkbox"></input><label htmlFor={idVal}>{props.data.val} <span className="filter-toggle-counts">{countPretty}</span></label>
          </div>
        )
      }

      return (
        <div className={containerClasses}>
          <input filtertype='website-blog-type' checked='checked' value={props.data.val} onChange={updateSearchHandler} id={idVal} type="checkbox"></input><label htmlFor={idVal}>{props.data.val} <span className="filter-toggle-counts hide">{countPretty}</span></label>
        </div>
      )
    }

    if (sc === 1) {
      return (
        <div className={containerClasses}>
          <input filtertype='website-blog-type' value={props.data.val} onChange={updateSearchHandler} id={idVal} type="checkbox"></input><label htmlFor={idVal}>{props.data.val} <span className="filter-toggle-counts">{countPretty}</span></label>
        </div>
      )
    }
    return (
      <div className={containerClasses}>
        <input filtertype='website-blog-type' value={props.data.val} onChange={updateSearchHandler} id={idVal} type="checkbox"></input><label htmlFor={idVal}>{props.data.val} <span className="filter-toggle-counts hide">{countPretty}</span></label>
      </div>
    )
  }

  const Options = () => {
    return(
      solrData.facets.ss_blog_category.buckets.map((item) => <Option key={item.val} data={item}></Option>)
      )
  }
  
  try {
    if (solrData.facets.ss_blog_category.buckets.length > 0) {
      
      return (
        <div id="website-blog-type-filters" className={websiteFilterGrouping}>
            <a href="#" className="toggle-filterset" onClick={toggleFilterset} aria-label="expand filter set"><h4>Blog Type</h4><ThickCaretDown flip width="15"></ThickCaretDown></a>
            <div className="filterset open">
              <div>
                <Options></Options>
              </div>
            </div>
            <hr></hr>
        </div>
      )
    }
  } catch {}
  

}

export default WebsiteBlogTypeFilter;