import toggleFilterset from "../../../functions/toggleFilterset";

import ThickCaretDown from "../../icons/thickCaretDown";


const CatalogPublishDate = (props) => { 

    const url = new URL(window.location.href);

    let pastTwoYearsActive = 'no';
    if (url.searchParams.get('pasttwoyears') === 'true') {
        pastTwoYearsActive = 'yes';
    }

    let pastTenYearsActive = 'no';
    if (url.searchParams.get('pasttenyears') === 'true') {
        pastTenYearsActive = 'yes';
    }

    let pubYearStartDefault = '';
    let pubYearEndDefault = '';

    try {
        if (url.searchParams.get('pubdate') !== null) {
            const pubDateParts = url.searchParams.get('pubdate').split(' TO ');
            pubYearStartDefault = pubDateParts[0];
            pubYearEndDefault = pubDateParts[1];
        }
    } catch {}

    return (
        <div className={props.catalogFilterGrouping}>
          <a href="#" className="toggle-filterset publish-year" onClick={toggleFilterset} aria-label="expand filter set"><h4>Year of Publication</h4><ThickCaretDown width="15"></ThickCaretDown></a>
          <div className="publish-year-filters filterset closed">
            <form onSubmit={props.filterProps.functions.updateSearchData} id="publication-year-form">
              <div className="flex">
                <div>
                  <label htmlFor="publish-year-start">Start</label>
                  <input type="text" maxLength="4" placeholder="YYYY" id="publish-year-start" defaultValue={pubYearStartDefault}></input>
                </div>
                
                <div>
                  <label htmlFor="publish-year-end">End</label>
                  <input type="text" maxLength="4" placeholder="YYYY" id="publish-year-end" defaultValue={pubYearEndDefault}></input>
                </div>
                <div>
                  <input type="submit" value="Apply"></input>
                </div>
              </div>
            </form>
            <a onClick={props.filterProps.functions.updateSearchData} filtertype="catalog-published-last-two-years" activated={pastTwoYearsActive} className="filter-pill-button" href="#">Past two years</a>            
            <a onClick={props.filterProps.functions.updateSearchData} filtertype="catalog-published-last-ten-years" activated={pastTenYearsActive} className="filter-pill-button" href="#">Past ten years</a>          
          </div>
          <hr></hr>
        </div>
    )
}

export default CatalogPublishDate;