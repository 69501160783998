import configs from "../../functions/configs";
import IconLandmark from "../icons/iconLandmark";
import IconTime from "../icons/iconTime";
//import IconCalendar from "../icons/iconCalendar";
import IconLocation from "../icons/iconLocation";
import IconCalendarPlus from "../icons/iconCalendarPlus";
import getData from "../../functions/getData";
import returnBestImage from "../../functions/returnBestImage";
import { useState } from "react";

const SearchResultWebsite = (props) => {

  const conf = configs();

  const url = new URL(window.location.href);

  const [imageState, setImageState] = useState('');

  const websiteImageErrorHandler = (e) => {
    if (e.target.src.indexOf('styles/large/public/images/events/') > -1) {
      e.target.src = e.target.src.replace('/styles/large/public', '');
    } else if (e.target.src.indexOf('images/events/calendar/') > -1) {
      e.target.src = e.target.src.replace('images/events/calendar/', '');
    } else if (e.target.src.indexOf('/public') > -1) {
      let ending = e.target.src.replace(/.+\//, '');
      e.target.src = "https://static.bklynlibrary.org/prod/" + ending;
    } else {
      e.target.src = '/websiteFallback.png';
    }
  }

  const updateSearchData =  props.updateSearchData;

  try {

    let webItemLink = conf.drupalBaseURL + '/node/' + props.data.item_id;

    let imgSource = '/websiteFallback.png';
    
    let webSummmary = '';
    let imageAlt = 'preview';
    let resultType = '';
    try {resultType = props.data.ss_type} catch {};

    if (resultType === 'Booklist') {
      resultType = 'Booklist: Staff Selections';
    }

    if (resultType === 'Blog') {
      try {
        if (typeof props.data.ss_blog_category !== 'undefined') {
          resultType += ': ' + props.data.ss_blog_category;
        }
      } catch {}
    }

    let linkTarget = "_blank";

    try {imageAlt = props.data.ts_title.replace(/[\'\"<>]/, '')} catch {};
    
    try {
      if (typeof props.data.ts_body !== 'undefined') {
        const parser = new DOMParser();
        const doc = parser.parseFromString(props.data.ts_body, "text/html");
        webSummmary = doc.body.firstChild.textContent.substring(0, 200);
        try {
          const firstImage = doc.body.getElementsByTagName('img')[0];
          if (typeof firstImage !== 'undefined') {
            imgSource = firstImage.src.replace('http://localhost:3000', 'https://www.bklynlibrary.org');
          }
        } catch {};
      }
    } catch {};

    try {
      if (typeof props.data.ss_image_url !== 'undefined') {
        if (props.data.ss_image_url !== '') {
          imgSource = props.data.ss_image_url;
        }
      }
    } catch {};

    try {
      if (typeof props.data.ss_image_alt !== 'undefined') {
        if (props.data.ss_image_alt !== '') {
          imageAlt = props.data.ss_image_alt;
        }
      }
    } catch {};

    

    if (props.data.ss_type === 'Shop') {
      try {
        webItemLink = props.data.ss_url;
      } catch {};
    }

    if (props.data.ss_type === 'Subject Guide') {
      try {
        webItemLink = props.data.ss_url;
      } catch {};
    }
    

    if (props.data.ss_type === 'Booklist') {
      linkTarget = "";
      try {
        const booklistFirstItem = JSON.parse(props.data.sm_field_catalog_item[0]);
        imgSource = booklistFirstItem.img;

        getData(conf.selectAPI + '?fq=id:' + booklistFirstItem.bib).then(res => {
          let fetchedSource = returnBestImage(res.response.docs[0]);
          console.log('fetched image src', fetchedSource);
          if (fetchedSource !== '') {
            setImageState(fetchedSource);
          }
        });
        
        webItemLink = props.data.ss_url.replace('/search', '');
        webSummmary = props.data.ts_field_list_description;
      } catch {};
    }
    
    if (props.data.ss_type === 'event') {

      resultType = "Event";
      imgSource = props.data.ss_image_url;

      if (typeof props.data.ss_image_alt !== 'undefined') {
        if (props.data.ss_image_alt !== '') {
          imageAlt = props.data.ss_image_alt;
        }
      }

      try {
        if (props.data.ds_event_start_date.indexOf('2024-02-13') > -1) {
          if (props.data.item_id !== '687413') {
            props.data.is_event_canceled = 1;
          }
        }

      } catch {}


      try {
        if (props.data.item_id === '697967') {
          imgSource = 'https://static.bklynlibrary.org/prod/2024-02/10126_nitl24_coe.png';
        }

        if (props.data.item_id === '699406') {
          imgSource = 'https://static.bklynlibrary.org/prod/public/images/events/calendar/jacket_14.jpg';
        }
      } catch {}

      const EventTime = (props) => {

        try {
  
          if (resultType === "Event") {
  
            const startDate = new Date(props.data.ds_event_start_date);
            const endDate = new Date(props.data.ds_event_end_date);
    
            let startDateString = conf.daysOfWeek[startDate.getDay()] + ', ' + conf.monthsOfYear[startDate.getMonth()] + ' ' + startDate.getDate();
    
            let startHours = startDate.getHours();
            let startMeridian = 'am';

            if (startHours >= 12) {
              startMeridian = 'pm';
            }
    
            if (startHours > 12) {
              startHours = startHours - 12;
            }
    
            let startMinutes = startDate.getMinutes().toString();
            if (startMinutes.match(/^\d$/) !== null) {
              startMinutes = '0' + startMinutes;
            }
    
            let endHours = endDate.getHours();
            let endMeridian = 'am';

            if (endHours >= 12) {
              endMeridian = 'pm';
            }
    
            if (endHours > 12) {
              endHours = endHours - 12;
            }
    
            let endMinutes = endDate.getMinutes().toString();
            if (endMinutes.match(/^\d$/) !== null) {
              endMinutes = '0' + endMinutes;
            }

            if (props.data.item_id === '697967') {
              endHours = '2';
              endMinutes = '00';
              endMeridian = 'am';
            }
    
            let timeString = startHours + ':' + startMinutes + startMeridian + ' to ' + endHours + ':' + endMinutes + endMeridian;
    
            const CanceledStatus = (props) => {
              try {
                if (props.data.is_event_canceled === 1) {
                  return (
                    <div className="event-canceled-msg">This event has been cancelled</div>
                  )
                }
              } catch {}
            }
    
            const RegistrationMessage = (props) => {
              try {
                if (props.data.is_event_registration === 1 && props.data.is_event_canceled === 0) {
                  return (<div className="event-registration-msg">REGISTER</div>)
                }
              } catch {}
            }

            try {
              if (props.data.is_virtual === 1) {
                props.data.ss_event_location = 'Virtual'
              }
            } catch {}
    
            return (
              <div className="event-top-extras-container">
                <div className="event-date-location-container"> 
                  <div className="flex"><div><IconCalendarPlus height='15'></IconCalendarPlus></div><div>{startDateString}</div></div>
                  <div className="flex"><div><IconTime height='15'></IconTime></div><div>{timeString}</div></div>
                  <div className="flex"><div><IconLocation height='15'></IconLocation></div><div className="notranslate">{props.data.ss_event_location}</div></div>
                </div>
                
                <div><RegistrationMessage data={props.data}></RegistrationMessage></div>
                <div><CanceledStatus data={props.data}></CanceledStatus></div>
              </div>
            )
          }
        } catch {}
      }


      const EventTags = (props) => {

        const EventTag = (props) => {

          try {

            let isActivated = 'no';
            if (props.data !== '') {
              // active filter
              let filtersURL = url.searchParams.get('eventtags');
              if (filtersURL !== null) {
                  const filters = filtersURL.split('||');
                  if (filters.indexOf(props.data) > -1) {
                    isActivated = 'yes';
                  }
              }
            }

            return (
              <button filtertype="eventtags" filterval={props.data} activated={isActivated} onClick={updateSearchData} className="subject-pill-btn no-color">{props.data}</button>
            )

          } catch {}
          
        }

        try {
          return props.data.sm_event_tags.map(item => <EventTag key={item} data={item}></EventTag>);
        } catch {}

      }

      return (
        <div className="result-container website">
          <div className="image-container">
            <img onError={websiteImageErrorHandler} alt={imageAlt} src={imgSource}></img>
          </div>
          <div className="result-detail-container">
            <div className="result-detail-text">
              <div className="result-type">{resultType}</div>
              <div className="result-title">
                <a target={linkTarget} href={webItemLink}>
                  {props.data.ts_title}
                </a>
              </div>
              <EventTime data={props.data}></EventTime>
              <div className="web-summary">{webSummmary}</div>

              <div className="flex wrap event-tags-container">
                <EventTags data={props.data}></EventTags>
              </div>
              
            </div>
          </div>
        </div>
      )

    } // end event display


    const BlogTags = (props) => {

      const BlogTag = (props) => {
        try {
          let isActivated = 'no';
          if (props.data !== '') {
            // active filter
            let filtersURL = url.searchParams.get('blogtags');
            if (filtersURL !== null) {
                const filters = filtersURL.split('||');
                if (filters.indexOf(props.data) > -1) {
                  isActivated = 'yes';
                }
            }
          }
          return (
            <button filtertype="blogtags" filterval={props.data} activated={isActivated} onClick={updateSearchData} className="subject-pill-btn no-color">{props.data}</button>
          )
        } catch {}
      }

      try {
        return props.data.sm_blog_tags.map(item => <BlogTag key={item} data={item}></BlogTag>);
      } catch {}
    }

    // default display
    if (imageState !== '') {
      imgSource = imageState;
    }
    return (
      <div className="result-container website">
        <div className="image-container">
          <img onError={websiteImageErrorHandler} alt={imageAlt} src={imgSource}></img>
        </div>
        <div className="result-detail-container">
          <div className="result-detail-text">
            <div className="result-type">{resultType}</div>
            <div className="result-title">
              <a target={linkTarget} href={webItemLink}>
                {props.data.ts_title}
              </a>
            </div>
            <div className="web-summary">{webSummmary}</div>
            <div className="flex wrap margin-top-20">
              <BlogTags data={props.data} />
            </div>
          </div>
        </div>
      </div>
    )

  } catch {}

};

export default SearchResultWebsite;