const PlusSign = (props) => {
  let height = '18';

  if (typeof props.height !== 'undefined') {
    height = props.height;
  }

  return (
    <svg height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
      <path d="M15 9.75H9.75V15H8.25V9.75H3V8.25H8.25V3H9.75V8.25H15V9.75Z" fill="#4A4C7C"/>
    </svg>
  )

};

export default PlusSign;