import configs from "../../functions/configs";
import SearchResultGroupedCatalog from "./searchResultGroupedCatalog";
import SearchResultSingleCatalog from "./searchResultSingleCatalog";
import SearchResultWebsite from "./searchResultWebsite";

const SearchResult = (props) => {
  const conf = configs();
  if (props.data) {
    if (typeof props.data.groupValue !== "undefined") { // GROUPED QUERIES
      try {

        if (props.data.doclist.docs[0].ss_type === "catalog") {
          
          if (props.data.doclist.docs.length === 1) {
            return (<SearchResultSingleCatalog setSelectedBibId={props.setSelectedBibId} updateSearchData={props.updateSearchData} data={props.data.doclist.docs[0]}></SearchResultSingleCatalog>);
          } else {
            return (<SearchResultGroupedCatalog setSelectedBibId={props.setSelectedBibId} updateSearchData={props.updateSearchData} data={props.data.doclist.docs}></SearchResultGroupedCatalog>);
          }

        } else {

          // GROUPED WEBSITE RECORDS
          return ( <SearchResultWebsite updateSearchData={props.updateSearchData} data={props.data.doclist.docs[0]}></SearchResultWebsite>);
        }
      
      } catch {}
      
    } else {
      if (props.data.ss_type === 'catalog') {
        return (<SearchResultSingleCatalog setSelectedBibId={props.setSelectedBibId} updateSearchData={props.updateSearchData} data={props.data}></SearchResultSingleCatalog>)
      }
      return ( <SearchResultWebsite updateSearchData={props.updateSearchData} data={props.data}></SearchResultWebsite>);
    }
  }
};

export default SearchResult;
