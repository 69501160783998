import IconTriangleExclamation from "../icons/iconTriangleExclamation";

const NoInternetMessage = (props) => {

  let containerClasses = "hide no-internet-message";

  try {

    if (!navigator.onLine) {
      containerClasses = "no-internet-message";
    }
  
    window.addEventListener("offline", (event) => {
      const noInternetAlert = document.querySelector('.no-internet-message');
      noInternetAlert.classList.remove('hide');
    });
  
    window.addEventListener("online", (event) => {
      const noInternetAlert = document.querySelector('.no-internet-message');
      noInternetAlert.classList.add('hide');
    });
  
  } catch {}
  
  return (
    <div className={containerClasses}>
      <div>
        <IconTriangleExclamation />
      </div>
      <div>
        You are not connected to the internet. Find a connection for full functionality.
      </div>
    </div>
  )
};

export default NoInternetMessage;
