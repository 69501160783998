

const SavedSearchesContainer = (props) => {


    return (
        <div>
            <h2>Saved Searches</h2>
            
        </div>
    )
}

export default SavedSearchesContainer;