
const OrderInformation = (props) => {

  const Order = (props) => {
    try {
      const orderParts = props.data.split(' || ');

      let prettyString = orderParts[0] + ' ';
      if (orderParts[0] === '1') {
        prettyString += 'copy purchased for ';
      } else {
        prettyString += 'copies purchased for ';
      }
      prettyString += orderParts[1] + ' on ' + orderParts[2];

      return (
        <div className="individual-order-listing">{prettyString}</div>
      )
    } catch {}
  }

  const Orders = (props) => {
    try {
      return props.data.sm_orders.map(item => <Order key={item} data={item}></Order>)
    } catch {}
  }

  try {
    if (props.data.bs_on_order === true) {
      if (props.data.sm_orders.length > 0) {
        return (
          <div className="order-information">
            <h3>Orders</h3>
            <Orders data={props.data}></Orders>
          </div>
        )
      }
    }
  } catch {}
};

export default OrderInformation;