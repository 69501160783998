import ActiveFilters from "./activeFilters";
import IconCircleCheck from "../icons/iconCircleCheck";
import { useState } from "react";

const ActiveFiltersContainer = (props) => {

  const filters = [];

  const acceptedFilterGroups = [
    'lexile',
    'onorder',
    'rebalancingloc',
    'callrange',
    'listauthor',
    'videotag',
    'eventlanguage',
    'readinglevel',
    'eventdatecustom',
    'dayofweek',
    'timeofday',
    'pasttenyears',
    'pasttwoyears',
    'fiction',
    'collection',
    'unlimitedaccess',
    'author',
    'blogtype',
    'eformat',
    'eprovider',
    'availablenow',
    'eventdate',
    'pubdate',
    'eventtags',
    'eventage',
    'subjects',
    'language',
    'materialtype',
    'targetage',
    'availableat',
    'contenttype',
    'eventlocation',
    'rebalancing',
    'search',
    'virtualevent',
    'inpersonevent',
    'blogtags',
    'registration'
  ];

  const url = new URL(window.location.href);

  url.searchParams.forEach((value, key) => {
    if (acceptedFilterGroups.indexOf(key) > -1) {
      let URLKey = key;
      const values = value.split('||');
      values.forEach((val) => {
        let URLValue = val;
        switch (key) {
          case 'availablenow':
            key = 'Available Now';
            val = '';
            break;
          case 'onorder':
            key = 'On Order';
            val = '';
            break;
          case 'materialtype':
            key = 'Format';
            break;
          case 'subjects':
            key = 'Tags';
            break;
          case 'targetage':
            key = 'Age';
            break;
          case 'fiction':
            key = 'Fiction Status';
            break;
          case 'pubdate':
            key = 'Publication Date';
            break;
          case 'author':
            key = 'Author';
            break;
          case 'pasttwoyears':
            key = 'Past Two Years';
            val = '';
            break;
          case 'pasttenyears':
            key = 'Past Ten Years';
            val = '';
            break;
          case 'search':
            key = 'Search';
            break;
          case 'listauthor':
            key = 'List Author';
            break;
          case 'contenttype':
            key = 'Content Type';
            break;
          case 'eventlocation':
            key = 'Event Location';
            break;
          case 'availableat':
            key = 'Branch Location';
            break;
          case 'language':
            key = 'Language';
            break;
          case 'eventage':
            key = 'Event Age';
            break;
          case 'eventtags':
            key = 'Event Tags';
            break;
          case 'dayofweek':
            key = 'Day of Week';
            break;
          case 'timeofday':
            key = 'Time of Day';
            break;
          case 'eventdate':
            key = 'Event Date';
            break;
          case 'collection':
            key = 'Collection';
            break;
          case 'rebalancingloc':
            key = 'Rebalancing Location';
            break;
          case 'rebalancing':
            key = 'Rebalancing';
            val = '';
            break;
          case 'virtualevent':
            key = 'Virtual';
            val = '';
            break;
          case 'inpersonevent':
            key = 'In Person';
            val = '';
            break;
          case 'callrange':
            key = 'Dewey';
            break;
          case 'blogtype':
            key = 'Blog Type';
            break;
          case 'blogtags':
            key = 'Blog Tags';
            break;
          case 'registration':
            key = 'RSVP';
            if (val === 'noregistration') {
              key = 'No RSVP';
            }
            val = '';
        }

        filters.push({
          'key': key,
          'value': val,
          'URLKey': URLKey,
          'URLValue': URLValue,
          'id': URLKey + URLValue
        });
      });
    }
  });


  const PinFiltersButton = (props) => {
    let pinClass = '';
    if (url.searchParams.get('pinned') === 'checked') {
      pinClass = 'checked';
    }
    if (pinClass === '') {
      return (
        <div className="pin-filters-container">
          <button title="Filters stay in place between searches when pinned" onClick={props.filterProps.functions.updateSearchData} id="pin-filters">Pin filters</button>
        </div>
      )
    }
    if (pinClass === 'checked') {
      return (
        <div className="pin-filters-container">
          <button title="Filters stay in place between searches when pinned" onClick={props.filterProps.functions.updateSearchData} id="pin-filters">Unpin filters</button>
        </div>
      )
    }
  }
  
  if (filters.length > 0) {
    return (
      <div className="active-filters-container-parent">
        <div className="active-filters-pin-filters-container">
          <div>
            <div className="selected-filters-text">SELECTED FILTERS</div>
            <div className="active-filters-container">
              <button
                onClick={props.filterProps.functions.updateSearchData}
                className="active-filter clear-all-button"
              >
                Clear All
              </button>
              <ActiveFilters
                filterProps={props.filterProps}
                filters={filters}
              ></ActiveFilters>
            </div>
          </div>
          <PinFiltersButton filterProps={props.filterProps} />
        </div>
      </div>
    );
  } 
}

export default ActiveFiltersContainer;
