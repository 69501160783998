const IconAngleRight = (props) => {

    let height = 16;
    
    if (typeof props.height !== 'undefined') {
        height = props.height
    } 

    return (
        <svg height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>
    )
}

export default IconAngleRight;