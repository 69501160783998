import XIcon from "../icons/xIcon";
import ThickCaretDown from "../icons/thickCaretDown";
import ProTipIcon from "../icons/proTipIcon";
import React from "react";
import { useState } from "react"; 
import CatalogFormatFilters from "./filterGroups/catalogFormatFilters";
import CatalogBranchFilters from "./filterGroups/catalogBranchFilters";
import toggleFilterset from "../../functions/toggleFilterset";
import CatalogTagFilters from "./filterGroups/catalogTagFilters";
import CatalogLanguageFilters from "./filterGroups/catalogLanguageFilters";
import CatalogAuthorFilters from "./filterGroups/catalogAuthorFilters";
import CatalogPublishDate from "./filterGroups/catalogPublishDate";
import CatalogAvailabilityFilters from "./filterGroups/catalogAvailabilityFilters";
import CatalogAgeFilters from "./filterGroups/catalogAgeFilters";
import CatalogFictionFilters from "./filterGroups/catalogFictionFilters";
import CatalogCollectionFilters from "./filterGroups/catalogCollectionFilters";
import CatalogRebalancingBranchFilters from "./filterGroups/catalogRebalancingBranchFilters";
import CatalogDeweyRange from "./filterGroups/catalogDeweyRange";
import BooklistAuthorFilters from "./filterGroups/booklistAuthorFilters";
import WebsiteTypeFilters from "./filterGroups/websiteTypeFilters";
import EventLocationFilters from "./filterGroups/eventLocationFilters";
import EventVirtualFilters from "./filterGroups/eventVirtual";
import EventRegistrationFilters from "./filterGroups/eventRegistrationFilters";
import EventAgeFilters from "./filterGroups/eventAgeFilters";
import EventTagFilters from "./filterGroups/eventTagFilters";
import EventDateFilters from "./filterGroups/eventDateFilters";
import toggleFiltersOverlay from "../../functions/toggleFiltersOverlay";
import WebsiteBlogTypeFilter from "./filterGroups/websiteBlogTypeFilter";
import WebsiteBlogTagFillters from "./filterGroups/websiteBlogTagFilters";

const Filters = (props) => {
  
  const url = new URL(window.location.href);

  let viewResultsText = 'Loading Results...';
  let numberofSearchResults = 0;

  try {
    
    if (typeof props.solrData.grouped !== 'undefined') {
      if (props.solrData.grouped.ss_grouping.matches === 0) {
        numberofSearchResults = 0
      } else {
        numberofSearchResults = props.solrData.facets.groupings.numBuckets;
      }
    } else {
      numberofSearchResults = props.solrData.response.numFound;
    }
    if (numberofSearchResults === 0) {
      viewResultsText = 'No Results :(';
    } else if (numberofSearchResults === 1) {
      viewResultsText = 'View 1 Result';
    } else if (numberofSearchResults > 999) {
      let numString = numberofSearchResults.toLocaleString("en-US");
      let numStringArray = numString.split(',');
      viewResultsText = 'View ' + numStringArray[0] + 'K Results';
    } else {
      viewResultsText = 'View ' + numberofSearchResults.toLocaleString("en-US") + ' Results';
    }

  } catch {}

  // homepage
  if (url.searchParams.get('catalog') === null && 
      url.searchParams.get('booklistfilter') === null && 
      url.searchParams.get('website') === null && 
      url.searchParams.get('event') === null &&
      url.searchParams.get('search') === null &&
      url.searchParams.get('booklist') === null) {
        viewResultsText = 'View Home';
  }

  let everythingChecked = '';
  let catalogChecked = '';
  let booklistChecked = '';
  let websiteChecked = '';
  let eventChecked = '';

  // HTML classes for other tabs
  if (url.searchParams.get('catalog') !== null) {
    catalogChecked = 'checked';
  } else if (url.searchParams.get('booklistfilter') !== null) {
    booklistChecked = 'checked';
  } else if (url.searchParams.get('website') !== null) {
    websiteChecked = 'checked';
  } else if (url.searchParams.get('event') !== null) {
    eventChecked = 'checked';
  } else {
    everythingChecked = 'checked';
  }

  if (url.searchParams.get('booklist') !== null) {
    catalogChecked = 'checked';
  }

  let proTipContainerClasses = 'pro-tip-container hide';

  if (url.searchParams.get('catalog') === null && 
      url.searchParams.get('booklistfilter') === null && 
      url.searchParams.get('website') === null && 
      url.searchParams.get('event') === null &&
      url.searchParams.get('booklist') === null) {
      proTipContainerClasses = 'pro-tip-container';
  }

  let catalogFilterGrouping = 'hide';
  if (url.searchParams.get('catalog') === 'true') {
    catalogFilterGrouping = '';
  }
  if (url.searchParams.get('booklist') !== null) {
    catalogFilterGrouping = '';
  }

  let categoryContainerClasses = '';
  if (url.searchParams.get('booklist') !== null) {
    categoryContainerClasses = 'hide';
  }
  
  
  const SeeCountsButton = () => {

    let seeCountsVisible = 0;
    let defaultText = 'See Counts';

    try {
      seeCountsVisible = parseInt(localStorage.getItem("seeCountsVisible"));
      if (seeCountsVisible === 1) {
        defaultText = 'Hide Counts';
      }
    } catch {}

    const [countText, setCountText] = useState(defaultText);
  
    const togglefacets = () => {
      
      try {
        const filterToggleCounts = document.getElementsByClassName('filter-toggle-counts');        

        console.log('seeCountsVisible', seeCountsVisible);

        if (seeCountsVisible === 1) {
          localStorage.setItem("seeCountsVisible", 0);
          setCountText('See Counts');
        } else {
          localStorage.setItem("seeCountsVisible", 1);
          setCountText('Hide Counts');
        }

        for (const ftc of filterToggleCounts) {
          ftc.classList.toggle('hide');
        }
        
      } catch (err) {console.log(err)}
    }

    let toggleFitlerFacetsClasses = 'hide';

    if (url.searchParams.get('catalog') !== null || url.searchParams.get('event') !== null || url.searchParams.get('website') !== null) {
      toggleFitlerFacetsClasses = '';
    }

    return (
      <div className={toggleFitlerFacetsClasses} id="filter-toggle-facets">
        <div className="filterset open">
          <button onClick={togglefacets} title="Displays counts for select filters" className="filter-toggle-facet">{countText}</button>
        </div>
      </div>
    )
  }

  return (
    <div className="filters">
      <div className="fixed-filters-header">
        <div className="filter-header-flex-container">
          <button onClick={toggleFiltersOverlay} className="close-modal-overlay"><XIcon></XIcon><span className="sr-only">Close filters</span></button>
          <h3 className="no-color filter-and-sort-header">Filter Options</h3>
        </div>
        <hr id="fixed-filter-header-hr"></hr>
      </div>
      <div className="filters-main-content">

        <div className={categoryContainerClasses}>
          <a href="#" className='toggle-filterset category' onClick={toggleFilterset} aria-label="expand filter set"><h4>Category</h4><ThickCaretDown flip width="15"></ThickCaretDown></a>
          <div className="filterset open">
            <div>
              <div className="radio-button-container">
                <input selected="selected" type="radio" onChange={props.filterProps.functions.updateSearchData} checked={everythingChecked} id="category-everything" name="categoryfilter" value="everything" />
                <label htmlFor="category-everything"><span className="radio-primary-label">Everything</span><br /><span className="radio-secondary-label">Get all results for everything</span></label>
              </div>
              <div className="radio-button-container">
                <input type="radio" id="category-catalog" onChange={props.filterProps.functions.updateSearchData} checked={catalogChecked} name="categoryfilter" value="catalog" />
                <label htmlFor="category-catalog"><span className="radio-primary-label">Books & More</span><br /><span className="radio-secondary-label">Search books, eBooks, movies, and more</span></label>
              </div>
              <div className="radio-button-container">
                <input type="radio" id="category-booklists" onChange={props.filterProps.functions.updateSearchData} checked={booklistChecked} name="categoryfilter" value="booklist" />
                <label htmlFor="category-booklists"><span className="radio-primary-label">Booklists</span><br /><span className="radio-secondary-label">Search topical booklists made by staff</span></label>
              </div>
              <div className="radio-button-container">
                <input type="radio" id="category-events" onChange={props.filterProps.functions.updateSearchData} checked={eventChecked} name="categoryfilter" value="event" />
                <label htmlFor="category-events"><span className="radio-primary-label">Events & Classes</span><br /><span className="radio-secondary-label">Search our events and classes</span></label>
              </div>
              <div className="radio-button-container">
                <input type="radio" id="category-website" onChange={props.filterProps.functions.updateSearchData} checked={websiteChecked} name="categoryfilter" value="website" />
                <label htmlFor="category-website"><span className="radio-primary-label">Pages, Blogs & More</span><br /><span className="radio-secondary-label">Search our blogs, podcasts, and info pages</span></label>
              </div>
            </div>
          </div>
          <div className={proTipContainerClasses}>
            <div className="pro-tip-image-container"><ProTipIcon></ProTipIcon></div>
            <div className="pro-tip-text-container"><div className="pro-tip-title">Pro Tip</div><div>Select one of the other categories for more filtering options</div></div>
          </div>
        
         <hr></hr>
        </div>

        <CatalogAvailabilityFilters filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping}></CatalogAvailabilityFilters>

        <CatalogRebalancingBranchFilters filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping} />

        <CatalogFormatFilters filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping}  ></CatalogFormatFilters>

        <CatalogAgeFilters filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping}></CatalogAgeFilters>

        <CatalogTagFilters filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping}  ></CatalogTagFilters>

        <CatalogLanguageFilters filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping}  ></CatalogLanguageFilters>

        <CatalogBranchFilters filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping}  ></CatalogBranchFilters>

        <CatalogCollectionFilters filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping}></CatalogCollectionFilters>

        <CatalogFictionFilters filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping}></CatalogFictionFilters>

        <CatalogAuthorFilters filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping}  ></CatalogAuthorFilters>

        <CatalogPublishDate filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping}></CatalogPublishDate>

        <CatalogDeweyRange filterProps={props.filterProps} catalogFilterGrouping={catalogFilterGrouping} />

        <BooklistAuthorFilters filterProps={props.filterProps}></BooklistAuthorFilters>

        <WebsiteTypeFilters filterProps={props.filterProps}  ></WebsiteTypeFilters>

        <WebsiteBlogTypeFilter filterProps={props.filterProps}  />

        <WebsiteBlogTagFillters filterProps={props.filterProps}  />

        <EventVirtualFilters filterProps={props.filterProps}></EventVirtualFilters>

        <EventRegistrationFilters filterProps={props.filterProps}></EventRegistrationFilters>

        <EventLocationFilters filterProps={props.filterProps}  ></EventLocationFilters>

        <EventAgeFilters filterProps={props.filterProps}></EventAgeFilters>

        <EventDateFilters filterProps={props.filterProps}></EventDateFilters>

        <EventTagFilters filterProps={props.filterProps}  ></EventTagFilters>

        <SeeCountsButton />

      </div>
      
      <div className="fixed-filters-footer">
        <hr></hr>
        <div className="filter-footer-flex-container">
          <div>
            <button onClick={props.filterProps.functions.updateSearchData} className="clear-all-filters-footer clear-all-button">Clear All</button>
          </div>
          <div>
            <button onClick={toggleFiltersOverlay} className="view-results-filters-footer">{viewResultsText}</button>
          </div>
        </div>
      </div>
        
    </div>

  )

};

export default Filters;
