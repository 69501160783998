import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import postData from "../../functions/postData";
import configs from "../../functions/configs";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import detectUserAgent from "../../functions/detectUserAgent";

const CreateNewListOverlay = (props) => {

    const conf = configs();

    const closeOverlay = (e) => {
        let addListOverlayIsOpen = false;
        try {
            if (document.querySelector('#add-to-list-overlay').classList.contains('overlay-active')) {
                addListOverlayIsOpen = true;
            }
        } catch {}
        toggleOverlay("#create-new-list-overlay", e);
        if (addListOverlayIsOpen) {
            setTimeout(()=>{
                document.body.classList.add('overlay-mode');
            },201);
        }
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    const createNewList = (e) => {
        toggleLoadingOverlay();

        let titleData = encodeURIComponent(document.querySelector('#new-list-overlay-name').value);
        let descriptionData = encodeURIComponent(document.querySelector('#new-list-overlay-description').value);
        let agent = detectUserAgent();

        postData(conf.baseAPI + "/user/create-new-list.php", 'agent=' + agent + '&title=' + titleData + "&description=" + descriptionData).then((res) => {

            console.log(res);
            toggleLoadingOverlay();

            if (res === null) { // success
                try {
                    document.querySelector('#create-new-list-overlay button.close-overlay').click();
                    if (window.location.href.indexOf('/my-account') > -1) {
                        document.querySelector('#update-my-account-booklists-state').click();
                    } else {
                        document.querySelector('#add-to-list-overlay-force-state-update').click();
                    }
                } catch {}
            } else if (typeof res.sessionExpired !== 'undefined') {
                document.querySelector('#create-new-list-overlay button.close-overlay').click();
                document.querySelector('#add-to-list-overlay button.close-overlay').click();
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                document.querySelector('#create-new-list-overlay button.close-overlay').click();
                document.querySelector('#add-to-list-overlay button.close-overlay').click();
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                document.querySelector('#create-new-list-overlay button.close-overlay').click();
                document.querySelector('#add-to-list-overlay button.close-overlay').click();
                launchErrorOverlay(res.message, '');
            }

        });
    }

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="create-new-list-overlay" className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Create New List</h3>

                <label htmlFor="new-list-overlay-name">List name</label>
                <input maxLength="200" id="new-list-overlay-name" type="text"></input>

                <label htmlFor="new-list-overlay-description">Description</label>
                <textarea maxLength="2000" id="new-list-overlay-description" type="text"></textarea>

                <div className="flex-small-gap">
                    <button onClick={createNewList} className="default-blue-button">Create list</button>
                    <button onClick={closeOverlay} className="default-white-button">Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default CreateNewListOverlay;