import IconInfo from "../icons/iconInfo";

const ToolMessage = (props) => {

    try {
        if (props.data.material_type === 'Tool') {
            return (
                <div className="tool-message-container"><div><IconInfo /></div><div>All tools are in storage. You must a place a hold to check out a tool. You will be notified when your hold is ready.</div></div>
            )
        }
    } catch {}
    
}


export default ToolMessage;