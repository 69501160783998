import configs from "../../functions/configs";
import { useState, useContext } from "react";
import postData from "../../functions/postData";
import returnBestImage from "../../functions/returnBestImage";
import toggleOverlay from "../../functions/toggleOverlay";
import fallbackImageHandler from "../../functions/fallbackImageHandler";
import onLoadImageHandler from "../../functions/onLoadImageHandler";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import IconTablet from "../icons/iconTablet";
import IconBook from "../icons/iconBook";
import IconRobot from "../icons/iconRobot";
import CatalogFormatIcon from "../icons/catalogFormatIcon";
import getCookie from '../../functions/getCookie';
import deleteCookie from '../../functions/deleteCookie';
import IconTriangleExclamation from "../icons/iconTriangleExclamation";
import getData from "../../functions/getData";
import { UserContext } from "../..";

let dataGrabbed = false;
const checkouts = {};

const CheckoutsContainer = (props) => {

    const conf = configs();

    const user = useContext(UserContext);

    const [readyState, updateReadyState] = useState('loading');
    const [filterState, updateFilterState] = useState('none');
    const [sortState, updateSortState] = useState('dueDate');
    const [returnState, setReturnState] = useState('');
    const [renewalState, setRenewalState] = useState('');

    if (readyState === 'loading') {
        try {
            // unset filters and sort if returning to screen after initial load
            checkouts.data.forEach((item)=>{
                item.hide = 'none';
            });
            checkouts.data.sort((a, b) => {
                if (a.dueTime > b.dueTime) {
                    return 1;
                } else if (a.dueTime < b.dueTime) {
                    return -1;
                } else {
                    return 0;
                }
            });
        } catch {}
    }

    let switchedAccounts = getCookie('bplswitchedcheckouts');

    if (switchedAccounts !== '') {
        delete checkouts.data;
    }
 
    if (dataGrabbed === false || switchedAccounts !== '') {
        getData(conf.baseAPI + "/user/return-checkouts.php").then((res) => {
            console.log(res);
            checkouts.data = res;
            if (switchedAccounts !== '') {
                updateReadyState('loaded' + Date.now());
            } else {
                updateReadyState('loaded');
            }
            dataGrabbed = true;
            deleteCookie('bplswitchedcheckouts');
        });
    }

    const launchAccessOverlay = (e) => {
        toggleOverlay('#access-overlay');
        const iframe = document.querySelector('#access-overdrive-container');
        iframe.classList.add('hide');
        let id = e.target.attributes.oid.value;
        postData(conf.baseAPI + "/overdrive/get-overdrive-access-link.php", 'id=' + id).then((res) => {
            iframe.attributes.src.value = res.link;
            iframe.classList.remove('hide');
        });
    }

    const overdriveReturnEarly = (e) => {
        e.preventDefault();
        const id = e.target.attributes.oid.value;
        toggleLoadingOverlay();
        postData(conf.baseAPI + "/overdrive/overdrive-return-early.php", 'id=' + id).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            if (res === null) { // success
                for (let i = 0; i < checkouts.data.length; i++) {
                    try {
                        if (checkouts.data[i].reserveId === id) {
                            checkouts.data.splice(i ,1);
                        }
                    } catch {}
                }
                setReturnState('deleted ' + id);
                launchSuccessOverlay(
                    "Your checkout has been returned.",
                    ""
                );
            } else if (typeof res.sessionExpired !== 'undefined') {
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                if (res.message === "An unmapped error has occurred. '7'") {
                    // https://www.amazon.com/hz/mycd/digital-console/contentlist/booksAll/dateDsc/
                    launchErrorOverlay("Kindle checkouts can only be returned early via Amazon's website.", 
                        '<a targer="_blank" href="https://www.amazon.com/hz/mycd/digital-console/contentlist/booksAll/dateDsc/">Return through Amazon</a>');
                } else {
                    launchErrorOverlay(res.message, '');
                }
                
            }
        });
    }

    const sierraRenew = (e) => {
        e.preventDefault();
        const id = e.target.attributes.checkoutid.value;
        const bibId = e.target.attributes.bibid.value;
        try {
            if (bibId === '12587573') {
                launchErrorOverlay('ILL renewals can only be made by calling 718-230-2187', '<a href="tel:718-230-2187">Call to renew</a>');
                return '';
            }
        } catch {}
        toggleLoadingOverlay();
        postData(conf.baseAPI + "/sierra/sierra-renew-checkout.php", 'checkoutnum=' + id).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            if (typeof res.id !== 'undefined') { // success
                for (let i = 0; i < checkouts.data.length; i++) {
                    try {
                        let checkoutId = checkouts.data[i].id.replace(/.+\//, '');
                        if (checkoutId === id) {
                            checkouts.data[i].dueDate = res.dueDate;
                            const newDueDate = new Date(res.dueDate);
                            checkouts.data[i].dueTime = Math.floor(newDueDate.getTime() / 1000);
                            checkouts.data[i].numberOfRenewals = res.numberOfRenewals;
                        }
                    } catch (err) {console.log(err)}
                }

                if (sortState === 'dueDate') {
                    checkouts.data.sort((a, b) => {
                        if (a.dueTime > b.dueTime) {
                            return 1;
                        } else if (a.dueTime < b.dueTime) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                }
                
                setRenewalState('renewed ' + res.id + res.dueDate );
                
                launchSuccessOverlay(
                    "Your checkout has been renewed.",
                    ""
                );
            } else if (typeof res.sessionExpired !== 'undefined') {
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                launchErrorOverlay(res.description, '');
            }
        });
    }
    
    const Checkout = (props) => {
        //console.log('checkout', props);

        try {
            if (props.data.hide === 'hidden') {
                return "";
            }
        } catch {}
        
        try {

        //const moveDownOne = {position: "relative", top: "1px"};

        if (props.data.type === 'sierra') {

            let link = "/item?b=" + props.data.bib;

            const dueDate = new Date(props.data.dueDate);
            let dueDateFormatted = dueDate.toLocaleDateString("en-US");

            const currentDate = new Date();
            let isOverdue = false;

            if (dueDate.getTime() <= currentDate.getTime()) {
                isOverdue = true;
            }

            let img = returnBestImage(props.data.solrData);

            let checkoutId = "";
            try {
                checkoutId = props.data.id.replace(/.+\//,'');
            } catch {}

            const RenewalCount = () => {
                try {
                    if (props.data.numberOfRenewals === 1) {
                        return (
                            <div className="num-of-renewals">Renewed 1 time</div>
                        )
                    } else {
                        return (
                            <div className="num-of-renewals">Renewed {props.data.numberOfRenewals} times</div>
                        )
                    }
                } catch {}
            }

           
            const DueDate = (props) => {
                if (isOverdue) {
                    return (
                        <div className="my-account-due-date-container"><div>DUE DATE:</div><div className='due-date-time overdue'><IconTriangleExclamation />{dueDateFormatted}</div></div>
                    )
                }
                return (
                    <div className="my-account-due-date-container"><div>DUE DATE:</div><div className='due-date-time'>{dueDateFormatted}</div></div>
                )
            }

            return (
                <div bib={props.data.bib} className="my-account-catalog-record">

                    <DueDate isOverdue={isOverdue} />

                    <div className="flex-small-gap">
                        <div>
                            <img onError={fallbackImageHandler} onLoad={onLoadImageHandler} alt={props.data.solrData.title} src={img}></img>
                        </div>

                        <div className="my-account-text-container">
                            <div className="my-account-catalog-title"><a href={link}>{props.data.solrData.title}</a></div>
                            <div className="my-account-catalog-author">{props.data.solrData.author}</div>
                            <div className="my-account-catalog-material-type"><CatalogFormatIcon format={props.data.solrData.material_type}></CatalogFormatIcon> {props.data.solrData.material_type}</div>
                            <div className="my-account-catalog-barcode">Barcode: {props.data.item.barcode}</div>
                        </div>
                    </div>

                    <div className="my-account-catalog-actions">
                        <button onClick={sierraRenew} checkoutid={checkoutId} bibid={props.data.bib} className="my-account-catalog-main-cta">Renew</button>
                        <RenewalCount data={props.data} />
                    </div>
                </div>
            )
        }

        if (props.data.type === 'overdrive') {
            let link = "/item?b=" + props.data.bib;

            let dueDate = new Date(props.data.expires);
            let dueDateFormatted = dueDate.toLocaleDateString("en-US");

            let img = returnBestImage(props.data.solrData);

            return (
                <div bib={props.data.bib} className="my-account-catalog-record">

                    <div className="my-account-due-date-container"><div>DUE DATE:</div><div className="due-date-time">{dueDateFormatted}</div></div>

                    <div className="flex-small-gap">
                        <div>
                            <img onError={fallbackImageHandler} onLoad={onLoadImageHandler} alt={props.data.solrData.title} src={img}></img>
                        </div>

                        <div className="my-account-text-container">
                            <div className="my-account-catalog-title"><a href={link}>{props.data.solrData.title}</a></div>
                            <div className="my-account-catalog-author">{props.data.solrData.author}</div>
                            <div className="my-account-catalog-material-type"><CatalogFormatIcon format={props.data.solrData.material_type}></CatalogFormatIcon> {props.data.solrData.material_type}</div>
                        </div>
                    </div>

                    <div className="my-account-catalog-actions">
                        <button oid={props.data.reserveId} onClick={launchAccessOverlay} className="my-account-catalog-main-cta">Access</button>
                        <a oid={props.data.reserveId} onClick={overdriveReturnEarly} className="my-account-action-link" href="#">Return early</a>
                    </div>
                </div>
            )
        }
        
        } catch (e) {console.log(e)}
        
    }

    const Checkouts = (props) => {
        try {
            if (props.data.length === 0) {
                return (
                    <div className="my-account-catalog-no-results">You have no checkouts!</div>
                )
            }
    
            if (props.data.length > 0) {
                return props.data.map((item)=><Checkout key={item.id} data={item}></Checkout>);
            }
        } catch {
            return (
                <div>Beep Boop. Gathering data... &nbsp; <IconRobot></IconRobot></div>
            )
        }
    }

    const CheckoutCount = (props) => {
        
        try {

            const digitalCheckouts = [];
            const physicalCheckouts = [];

            for (let i = 0; i < props.data.length; i++) {
                if (props.data[i].type === 'overdrive') {
                    digitalCheckouts.push(props.data[i]);
                } else {
                    physicalCheckouts.push(props.data[i]);
                }
            }

            try {
                switch (user.data.session.bpl_catalog.ptype) {
                    case 20:
                    case 24:
                    case 25:
                    case 8:
                    case 208:
                        conf.physicalCheckoutLimit = 999;
                        break;
                    case 9:
                    case 151:
                    case 209:
                    case 251:
                        conf.physicalCheckoutLimit = 100;
                        break;
                    case 6:
                    case 7:
                    case 10:
                    case 16:
                    case 17:
                    case 91:
                    case 210:
                        conf.physicalCheckoutLimit = 99;
                        break;
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                        conf.physicalCheckoutLimit = 3;
                        break;
                    case 11:
                    case 19:
                    case 40:
                    case 81:
                    case 82:
                        conf.physicalCheckoutLimit = 0;
                        conf.digitalCheckoutLimit = 0;
                        break;
                }
            } catch {}

            let physicalText = physicalCheckouts.length + " out of " + conf.physicalCheckoutLimit;
            let digitalText = digitalCheckouts.length + " out of " + conf.digitalCheckoutLimit;

            //if (props.data.length > 0) {
                return (
                    <div className="account-count-container">
                        <div className="individual-count-container">
                            <div className="bold main-color">
                                {physicalText}
                            </div>
                            <div className="dark-gray account-count-text">
                                Physical Checkouts
                            </div>
                        </div>
                        <div>
                            <hr />
                        </div>
                        <div className="individual-count-container">
                            <div className="bold main-color">
                                {digitalText}
                            </div>
                            <div className="dark-gray account-count-text">
                                Digital Checkouts
                            </div>
                        </div>
                    </div>
                )
            //}
        } catch {}
        
    }

    const filterDigital = (e) => {
        checkouts.data.forEach((item)=>{
            item.hide = 'none';
        });
        updateReadyState('loaded');
        if (filterState === 'digital') {
            updateFilterState('none');
        } else {
            checkouts.data.forEach((item)=>{
                if (item.type === "sierra") {
                    item.hide = 'hidden';
                }
            });
            updateFilterState('digital');
        }
    }

    const filterPhysical = (e) => {
        checkouts.data.forEach((item)=>{
            item.hide = 'none';
        });
        updateReadyState('loaded');
        if (filterState === 'physical') {
            updateFilterState('none');
        } else {
            checkouts.data.forEach((item)=>{
                if (item.type === "overdrive") {
                    item.hide = 'hidden';
                }
            });
            updateFilterState('physical');
        }
    }

    const sortCheckouts = (e) => {
        updateReadyState('loaded');
        if (e.target.value === 'Sort by title') {
            checkouts.data.sort((a, b) => {
                if (a.solrData.title > b.solrData.title) {
                    return 1;
                } else if (a.solrData.title < b.solrData.title) {
                    return -1;
                } else {
                    return 0;
                }
            });
            updateSortState('title');
        } else if (e.target.value === 'Sort by due date') {
            checkouts.data.sort((a, b) => {
                if (a.dueTime > b.dueTime) {
                    return 1;
                } else if (a.dueTime < b.dueTime) {
                    return -1;
                } else {
                    return 0;
                }
            });
            updateSortState('dueDate');
        }
    }

    const CheckoutFilters = (props) => {
        try {
            if (props.data.length > 0) {

                let digitalClass = '';
                let physicalClass = '';
                if (filterState === 'physical') {
                    physicalClass = 'active';
                } else if (filterState === 'digital') {
                    digitalClass = 'active';
                }

                let sortVal = 'Sort by due date';
                if (sortState === 'title') {
                    sortVal = 'Sort by title';
                } 

                return (
                    <div className="account-sort-fiter-container">

                        <div className="account-filter-button-container">
                            <button id="filter-digital-checkouts" className={digitalClass} onClick={filterDigital}><IconTablet></IconTablet> Filter Digital</button>
                            <button id="filter-physical-checkouts" className={physicalClass} onClick={filterPhysical}><IconBook></IconBook> Filter Physical</button>
                        </div>

                        <label for="checkouts-sort" className="sr-only">Sort checkouts</label>
                        <select onChange={sortCheckouts} defaultValue={sortVal} id="checkouts-sort">
                            <option>Sort by due date</option>
                            <option>Sort by title</option>
                        </select>
                        
                    </div>
                )
            }
        } catch {}
    }


    return (
        <div className="my-account-content-container">
            <h2>Checkouts</h2>
            
            <div className="account-top-tiles-container">
                <CheckoutCount data={checkouts.data}></CheckoutCount>
                <CheckoutFilters data={checkouts.data}></CheckoutFilters>
            </div>
            <div className="checkouts-holds-container">
                <Checkouts data={checkouts.data}></Checkouts>
            </div>
        </div>
    )
}

export default CheckoutsContainer;