const clearCache = () => {
    try {
        caches.keys().then((keyList) =>
            Promise.all(
            keyList.map((key) => {
                return caches.delete(key);
            }),
        ));
    } catch {}
}

export default clearCache;