import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import postData from "../../functions/postData";
import configs from "../../functions/configs";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay"

const HistoryOptOutOverlay = (props) => {

    const conf = configs();

    const closeOverlay = (e) => {
        toggleOverlay('#history-opt-out-overlay', e);
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    const optOutHistory = () => {
        toggleLoadingOverlay();
        postData(conf.baseAPI + "/sierra/sierra-opt-out-reading-history.php", '').then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            try {
                document.querySelector('#history-opt-out-overlay button').click(); // close overlay
                document.querySelector('#force-history-opt-out-state').click();
            } catch {}
        });
    }

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="history-opt-out-overlay" className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Opt Out of History</h3>
                <p>Opting out of recording checkout history deletes all your current history and cannot be undone.</p>
                <p>Are you sure you want to opt out of recording checkout history?</p>

                <br />
                <br />
                
                <button onClick={optOutHistory} className="default-blue-button">Yes, Opt out</button>
            </div>
        </div>
    )
}

export default HistoryOptOutOverlay;