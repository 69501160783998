import './App.css';
import {useState, createContext} from "react";
import useFetch from "./hooks/useFetch";
import configs from './functions/configs';
import NavBar from "./components/shared/navBar";
import Filters from "./components/searchPage/filters";
import Footer from "./components/shared/footer";
import hideLastFiltersHorizontalRule from "./functions/hideLastFilterHorizontalRule";
import BreadCrumb from "./components/shared/breadcrumb";
import SearchResultsContainer from "./components/searchPage/searchResultsContainer";
import ActiveFiltersContainer from "./components/searchPage/activeFiltersContainer";
import ToggleFiltersOverlayButton from "./components/searchPage/toggleFiltersOverlayButton";
import SearchPageHeader from "./components/searchPage/searchPageHeader";
import OverlayContainer from "./components/overlays/overlayContainer";
import BottomNav from "./components/shared/bottomNav";
import ReactGA from 'react-ga4';
import NotOnlineScreen from './components/shared/notOnlineScreen';
import NoInternetMessage from './components/shared/noInternetMessage';

export const SolrContext = createContext();

let hasTypedSearchbar = false;
let popstateFired = false;

function SearchApp() {

  const conf = configs();
  const url = new URL(window.location);
  
  const [responseData] = useFetch(conf.searchAPI + url.search);
  const [searchData, setSearch] = useState({currentURL: url});
  const [selectedBibId, setSelectedBibId] = useState('');
  const [loginState, setLoginState] = useState('');

  const isOnline = window.navigator.onLine;

  if (url.search === '') {
    try {
      // clear data to on homepage to get the 'beep boop' loading message
      responseData.grouped = null;
      responseData.facets = null;
      responseData.response = null;
    } catch {}
  }

  try {
    if (url.searchParams.get('event') !== null) {
      document.title = 'Event Search | Brooklyn Public Library';
    } else if (url.searchParams.get('website') !== null) {
      document.title = 'Site Search | Brooklyn Public Library';
    } else if (url.searchParams.get('booklistfilter') !== null) {
      document.title = 'Booklist Search | Brooklyn Public Library';
    } else if (url.searchParams.get('catalog') !== null) {
      document.title = 'Catalog Search | Brooklyn Public Library';
    } else {
      document.title = 'Search | Brooklyn Public Library';
    }
  } catch {}

  console.log(responseData);
  
  window.addEventListener('popstate', function(event) {//browser back or forward button click force reload  
    if (!popstateFired) {
      popstateFired = true;
      this.location.reload();
    } else {
      event.preventDefault();
    }
  });

  try {
    if (url.searchParams.get('booklist') !== null) {
      if (typeof responseData.original_list !== 'undefined') {
        if (responseData.original_list.type === 'staff' && responseData.original_list.privacy === 'public') {
          if (responseData.original_list.title.match(/bookmatch/i) !== null) {
            ReactGA.event({category:'booklist_visited', action: 'BookMatch List Visited', label: responseData.original_list.title});
          } else {
            ReactGA.event({category:'non_bookmatch_booklist_visited', action: 'Non Bookmatch List Visited', label: responseData.original_list.title});
          }
        }
      }
    }
  } catch {}

  hideLastFiltersHorizontalRule();

  const updateSearchData = (event) => {

    let filtersPinned = false;

    //console.log(event);

    if (url.searchParams.get('pinned') === 'checked') {
      filtersPinned = true;
    }

    if (event.currentTarget.id === 'pin-filters') {
      if (url.searchParams.get('pinned') === 'checked') {
        url.searchParams.delete('pinned');
      } else {
        url.searchParams.set('pinned', 'checked');
      }
    }

    if (event.currentTarget.id === 'first-page') {
      if (url.searchParams.get('pagination') === null) {
        return '';
      } else {
        url.searchParams.delete("pagination");
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    } else if (event.currentTarget.id === 'previous-page') {
      if (url.searchParams.get('pagination') === null) {
        return '';
      } else if (url.searchParams.get('pagination') === '2') {
        url.searchParams.delete("pagination");
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        let currentPage = parseInt(url.searchParams.get('pagination'));
        currentPage--;
        url.searchParams.set('pagination', currentPage);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    } else if (event.currentTarget.id === 'next-page') {
      if (url.searchParams.get('pagination') === null) {
        url.searchParams.set('pagination', '2');
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        let total = 0;
        if (typeof responseData.grouped !== "undefined") {
          total = responseData.facets.groupings.numBuckets;
        } else {
          total = responseData.response.numFound;
        }
        let lastPage = Math.ceil(total / conf.defaultPageLength);

        if (lastPage <= parseInt(url.searchParams.get('pagination'))) {
          return '';
        }

        let currentPage = parseInt(url.searchParams.get('pagination'));
        currentPage++
        url.searchParams.set('pagination', currentPage);
        
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    } else if (event.currentTarget.id === 'last-page') {
      try {
        let total = 0;
        if (typeof responseData.grouped !== "undefined") {
          total = responseData.facets.groupings.numBuckets;
        } else {
          total = responseData.response.numFound;
        }

        let lastPage = Math.ceil(total / conf.defaultPageLength);
        if (lastPage <= parseInt(url.searchParams.get('pagination'))) {
          return '';
        }

        url.searchParams.set("pagination", lastPage);

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });

      } catch {}

    } else {
      url.searchParams.delete("pagination");
    }

    if (event.currentTarget.id === 'pagination-form') {
      event.preventDefault();

      let paginationInputValue = parseInt(document.querySelector('#pagination-manual-input').value);
      let paginationPageLimit = parseInt(document.querySelector('#pagination-page-limit').textContent);


      if (paginationInputValue <= 0) {
        return '';
      }

      if (paginationInputValue > paginationPageLimit) {
        return '';
      }

      if (paginationInputValue === 1) {
        url.searchParams.delete("pagination");
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        url.searchParams.set("pagination", paginationInputValue);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      
    }
    
    if (event.currentTarget.id === 'global-searchbar') {
      event.preventDefault();

      if (!filtersPinned) { // clears other filters but keeps main category
        if (url.searchParams.get('catalog') !== null) {
          url.search = ''; 
          url.searchParams.set("catalog", "true");
        } else if (url.searchParams.get('booklistfilter') !== null) {
          url.search = ''; 
          url.searchParams.set("booklistfilter", "true");
        } else if (url.searchParams.get('website') !== null) {
          url.search = ''; 
          url.searchParams.set("website", "true");
        } else if (url.searchParams.get('event') !== null) {
          url.search = ''; 
          url.searchParams.set("event", "true");
        }
      }
     
      // send to GA
      if (url.searchParams.get('catalog') !== null) {
        try {
          ReactGA.event({category:'catalog_search_text', action: 'BPL Catalog Search Submission', label: document.querySelector('#global-search-text-input').value.toLowerCase()});
        } catch (err) {console.log('reactGA error', err)}
      } else {
        try {
          ReactGA.event({category:'global_search_text', action: 'BPL Global Search Submission', label: document.querySelector('#global-search-text-input').value.toLowerCase()});
        } catch (err) {console.log('reactGA error', err)}
      }

      // set search param
      if (document.querySelector('#global-search-text-input').value === '') {
        url.searchParams.delete("search");
      } else {
        url.searchParams.set("search", document.querySelector('#global-search-text-input').value);
      }
      
    } else if (event.currentTarget.id === 'sort-selectbox') {


      url.searchParams.set("sort", event.currentTarget.value);

      /*
      switch (event.currentTarget.value) {
        case "author":
          url.searchParams.set("sort", "author");
          break;
        case "popularity":
          url.searchParams.set("sort", "popularity");
          break;
        case "relevance":
          url.searchParams.delete("sort");
          break;
        case "title":
          url.searchParams.set("sort", "title");
          break;
        case "publishdate":
          url.searchParams.set("sort", "publishdate");
          break;
        
        default:
          url.searchParams.set("sort", event.currentTarget.value);
      }
      */

    } else if (event.currentTarget.id.match(/^category-.+/) !== null) {

      if (url.searchParams.get('search') !== null) {
        let searchVal = url.searchParams.get('search');
        // first clear all filter values then reapply search value
        url.search = '';
        url.searchParams.set("search", searchVal);
      } else {
        url.search = '';
      }
      
      switch (event.currentTarget.value) {
        case "catalog":
          url.searchParams.set("catalog", "true");
          break;
        case "booklist":
          url.searchParams.set("booklistfilter", "true");
          break;
        case "website":
          url.searchParams.set("website", "true");
          break;
        case "event":
          url.searchParams.set("event", "true");
          break;
      }

      if (filtersPinned) {
        url.searchParams.set('pinned', 'checked');
      }

    } else if (typeof event.currentTarget.attributes.filtertype !== 'undefined') { // filters using filtertype attribute

      if (event.currentTarget.attributes.filtertype.value === 'active-filter') {
        event.preventDefault();
        let urlValueOfFilter = url.searchParams.get(event.currentTarget.attributes.urlkey.value)
        if (urlValueOfFilter !== null) {
          const currentFilters = urlValueOfFilter.split('||');
          if (currentFilters.length === 1) {
            url.searchParams.delete(event.currentTarget.attributes.urlkey.value);
          } else {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.attributes.urlvalue.value), 1);
            url.searchParams.set(event.currentTarget.attributes.urlkey.value, currentFilters.join('||'));
          }
        }
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-age') {
        let ageFiltersURL = url.searchParams.get('targetage');
        if (ageFiltersURL === null) {
          url.searchParams.set('targetage', event.currentTarget.value);
        } else {
          const currentAgeFilters = ageFiltersURL.split('||');
          if (currentAgeFilters.includes(event.currentTarget.value)) {
            currentAgeFilters.splice(currentAgeFilters.indexOf(event.currentTarget.value), 1);
            url.searchParams.set('targetage', currentAgeFilters.join('||'));
          } else {
            currentAgeFilters.push(event.currentTarget.value);
            url.searchParams.set('targetage', currentAgeFilters.join('||'));
          }
          if (currentAgeFilters.length === 0) {
            url.searchParams.delete('targetage');
          }
        }
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-available-now') {
        if (url.searchParams.get('availablenow') === null) {
          url.searchParams.set('availablenow', 'true');
          url.searchParams.delete('onorder');
        } else {
          url.searchParams.delete('availablenow');
        }
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-on-order') {
        if (url.searchParams.get('onorder') === null) {
          url.searchParams.set('onorder', 'true');
          url.searchParams.delete('availablenow');
        } else {
          url.searchParams.delete('onorder');
        }
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-rebalancing') {
        if (url.searchParams.get('rebalancing') === null) {
          url.searchParams.set('rebalancing', 'true');
        } else {
          url.searchParams.delete('rebalancing');
        }
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-format') {
        event.preventDefault();
        let formatFiltersURL = url.searchParams.get('materialtype');
        if (formatFiltersURL === null) {
          url.searchParams.set('materialtype', event.currentTarget.attributes.filterval.value.trim());
        } else {
          const currentFormatFilters = formatFiltersURL.split('||');
          if (currentFormatFilters.includes(event.currentTarget.attributes.filterval.value.trim())) {
            currentFormatFilters.splice(currentFormatFilters.indexOf(event.currentTarget.attributes.filterval.value.trim()), 1);
            url.searchParams.set('materialtype', currentFormatFilters.join('||'));
          } else {
            currentFormatFilters.push(event.currentTarget.attributes.filterval.value.trim());
            url.searchParams.set('materialtype', currentFormatFilters.join('||'));
          }
          if (currentFormatFilters.length === 0) {
            url.searchParams.delete('materialtype');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-branch') {
        event.preventDefault();
        try {
          document.querySelector('.filter-search-input').value = '';
        } catch {}
        let availableLocationsURL = url.searchParams.get('availableat');
        if (availableLocationsURL === null) {
          url.searchParams.set('availableat', event.currentTarget.value.trim());
        } else {
          const currentFilters = availableLocationsURL.split('||');
          if (currentFilters.includes(event.currentTarget.value.trim())) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.value.trim()), 1);
            url.searchParams.set('availableat', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.value.trim());
            url.searchParams.set('availableat', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('availableat');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'rebalancing-branch') {
        let availableLocationsURL = url.searchParams.get('rebalancingloc');
        if (availableLocationsURL === null) {
          url.searchParams.set('rebalancingloc', event.currentTarget.value.trim());
        } else {
          const currentFilters = availableLocationsURL.split('||');
          if (currentFilters.includes(event.currentTarget.value.trim())) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.value.trim()), 1);
            url.searchParams.set('rebalancingloc', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.value.trim());
            url.searchParams.set('rebalancingloc', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('rebalancingloc');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-tag') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('subjects');
        if (filtersURL === null) {
          if (url.searchParams.get('catalog') === null) {
            url.searchParams.set('catalog', 'true');
          }
          url.searchParams.set('subjects', event.currentTarget.attributes.filterval.value.trim());
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.attributes.filterval.value.trim())) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.attributes.filterval.value.trim()), 1);
            url.searchParams.set('subjects', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.attributes.filterval.value.trim());
            url.searchParams.set('subjects', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('subjects');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-language') {
        let filtersURL = url.searchParams.get('language');
        if (filtersURL === null) {
          url.searchParams.set('language', event.currentTarget.value.trim());
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.value.trim())) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.value.trim()), 1);
            url.searchParams.set('language', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.value.trim());
            url.searchParams.set('language', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('language');
          }
        }
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-author') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('author');
        if (filtersURL === null) {
          url.searchParams.set('author', event.currentTarget.attributes.filterval.value.trim());
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.attributes.filterval.value.trim())) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.attributes.filterval.value.trim()), 1);
            url.searchParams.set('author', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.attributes.filterval.value.trim());
            url.searchParams.set('author', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('author');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-fiction') {
        let fictionStatusURL = url.searchParams.get('fiction');
        if (fictionStatusURL === null) {
          url.searchParams.set('fiction', event.currentTarget.attributes.filterval.value.trim());
        } else {
          if (fictionStatusURL === event.currentTarget.attributes.filterval.value.trim()) {
            url.searchParams.delete('fiction');
          } else {
            url.searchParams.set('fiction', event.currentTarget.attributes.filterval.value.trim());
          }
        }
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-collection') {
        let currentParam = url.searchParams.get('collection');
        if (currentParam === null) {
          url.searchParams.set('collection', event.currentTarget.attributes.filterval.value.trim());
        } else {
          if (currentParam === event.currentTarget.attributes.filterval.value.trim()) {
            url.searchParams.delete('collection');
          } else {
            url.searchParams.set('collection', event.currentTarget.attributes.filterval.value.trim());
          }
        }
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-published-last-two-years') {
        event.preventDefault();
        url.searchParams.delete('pasttenyears');
        url.searchParams.delete('pubdate');
        if (url.searchParams.get('pasttwoyears') === 'true') {
          url.searchParams.delete('pasttwoyears');
        } else {
          url.searchParams.set('pasttwoyears', 'true');
        }
      }

      if (event.currentTarget.attributes.filtertype.value === 'catalog-published-last-ten-years') {
        event.preventDefault();
        url.searchParams.delete('pasttwoyears');
        url.searchParams.delete('pubdate');
        if (url.searchParams.get('pasttenyears') === 'true') {
          url.searchParams.delete('pasttenyears');
        } else {
          url.searchParams.set('pasttenyears', 'true');
        }
      }

      if (event.currentTarget.attributes.filtertype.value === 'booklist-author') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('listauthor');
        if (filtersURL === null) {
          url.searchParams.set('listauthor', event.currentTarget.attributes.filterval.value.trim());
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.attributes.filterval.value.trim())) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.attributes.filterval.value.trim()), 1);
            url.searchParams.set('listauthor', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.attributes.filterval.value.trim());
            url.searchParams.set('listauthor', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('listauthor');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'website-content-type') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('contenttype');
        if (filtersURL === null) {
          url.searchParams.set('contenttype', event.currentTarget.value.trim());
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.value.trim())) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.value.trim()), 1);
            url.searchParams.set('contenttype', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.value.trim());
            url.searchParams.set('contenttype', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('contenttype');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'event-location') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('eventlocation');
        if (filtersURL === null) {
          url.searchParams.set('eventlocation', event.currentTarget.value.trim());
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.value.trim())) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.value.trim()), 1);
            url.searchParams.set('eventlocation', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.value.trim());
            url.searchParams.set('eventlocation', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('eventlocation');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'event-virtual') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('virtualevent');
        if (filtersURL === null) {
          url.searchParams.set('virtualevent', 'true');
          url.searchParams.delete('inpersonevent');
        } else {
          url.searchParams.delete('virtualevent');
        } 
      }

      // Add in person event filter logic here...
      if (event.currentTarget.attributes.filtertype.value === 'event-in-person') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('inpersonevent');
        if (filtersURL === null) {
          url.searchParams.set('inpersonevent', 'true');
          url.searchParams.delete('virtualevent');
        } else {
          url.searchParams.delete('inpersonevent');
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'event-registration') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('registration');
        if (filtersURL === null) {
          url.searchParams.set('registration', event.currentTarget.attributes.filterval.value);
        } else {
          if (url.searchParams.get('registration') === event.currentTarget.attributes.filterval.value) {
            url.searchParams.delete('registration');
          } else {
            url.searchParams.set('registration', event.currentTarget.attributes.filterval.value);
          }
        } 
      }
      

      if (event.currentTarget.attributes.filtertype.value === 'eventage') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('eventage');
        if (filtersURL === null) {
          url.searchParams.set('eventage', event.currentTarget.attributes.filterval.value);
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.attributes.filterval.value)) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.attributes.filterval.value), 1);
            url.searchParams.set('eventage', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.attributes.filterval.value);
            url.searchParams.set('eventage', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('eventage');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'eventtags') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('eventtags');
        if (filtersURL === null) {
          if (url.searchParams.get('event') === null) {
            url.searchParams.set('event', 'true');
          }
          url.searchParams.set('eventtags', event.currentTarget.attributes.filterval.value);
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.attributes.filterval.value)) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.attributes.filterval.value), 1);
            url.searchParams.set('eventtags', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.attributes.filterval.value);
            url.searchParams.set('eventtags', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('eventtags');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'dayofweek') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('dayofweek');
        if (filtersURL === null) {
          url.searchParams.set('dayofweek', event.currentTarget.attributes.filterval.value);
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.attributes.filterval.value)) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.attributes.filterval.value), 1);
            url.searchParams.set('dayofweek', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.attributes.filterval.value);
            url.searchParams.set('dayofweek', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('dayofweek');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'timeofday') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('timeofday');
        if (filtersURL === null) {
          url.searchParams.set('timeofday', event.currentTarget.attributes.filterval.value);
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.attributes.filterval.value)) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.attributes.filterval.value), 1);
            url.searchParams.set('timeofday', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.attributes.filterval.value);
            url.searchParams.set('timeofday', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('timeofday');
          }
        } 
      }

      if (event.currentTarget.attributes.filtertype.value === 'website-blog-type') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('blogtype');
        if (filtersURL === null) {
          url.searchParams.set('blogtype', event.currentTarget.value);
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.value)) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.value), 1);
            url.searchParams.set('blogtype', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.value);
            url.searchParams.set('blogtype', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('blogtype');
          }
        }
      }

      if (event.currentTarget.attributes.filtertype.value === 'blogtags') {
        event.preventDefault();
        let filtersURL = url.searchParams.get('blogtags');
        if (filtersURL === null) {
          url.searchParams.set('blogtags', event.currentTarget.attributes.filterval.value);
        } else {
          const currentFilters = filtersURL.split('||');
          if (currentFilters.includes(event.currentTarget.attributes.filterval.value)) {
            currentFilters.splice(currentFilters.indexOf(event.currentTarget.attributes.filterval.value), 1);
            url.searchParams.set('blogtags', currentFilters.join('||'));
          } else {
            currentFilters.push(event.currentTarget.attributes.filterval.value);
            url.searchParams.set('blogtags', currentFilters.join('||'));
          }
          if (currentFilters.length === 0) {
            url.searchParams.delete('blogtags');
          }
        }
      }

    } else if (event.currentTarget.id === 'publication-year-form') {
      event.preventDefault();

      url.searchParams.delete('pasttwoyears');
      url.searchParams.delete('pasttenyears');

      const pubYearStart = document.getElementById('publish-year-start').value;
      const pubYearEnd = document.getElementById('publish-year-end').value;

      if (pubYearStart !== '' && pubYearEnd !== '') {
        url.searchParams.set('pubdate', pubYearStart + ' TO ' + pubYearEnd);
      } else {
        url.searchParams.delete('pubdate');
      }
    } else if (event.currentTarget.id === 'apply-event-date-input') {
      event.preventDefault();
      document.querySelector('.event-date-error-msg').classList.add('hide');
      let dateVal = document.querySelector('#event-date-input').value;
      /*
      if (dateVal.match(/\d\d\-\d\d\-\d\d\d\d/)) {
        url.searchParams.set('eventdate', document.querySelector('#event-date-input').value);
      } else {
        document.querySelector('.event-date-error-msg').classList.remove('hide');
      }
*/
      url.searchParams.set('eventdate', document.querySelector('#event-date-input').value.replace(/\//g, '-'));


    } else if (event.currentTarget.classList.contains('clear-all-button')) {
      // clear all - keep main category and search term, clear everything else
      let searchVal = url.searchParams.get('search');
      if (url.searchParams.get('catalog') !== null) {
        url.search = ''; 
        url.searchParams.set("catalog", "true");
      } else if (url.searchParams.get('booklistfilter') !== null) {
        url.search = ''; 
        url.searchParams.set("booklistfilter", "true");
      } else if (url.searchParams.get('website') !== null) {
        url.search = ''; 
        url.searchParams.set("website", "true");
      } else if (url.searchParams.get('event') !== null) {
        url.search = ''; 
        url.searchParams.set("event", "true");
      } else {
        url.search = ''; 
      }
      
    } else if (event.currentTarget.id === 'dewey-form') {
      event.preventDefault();
      let startVal = document.querySelector('#dewey-start').value.trim();
      let endVal = document.querySelector('#dewey-end').value.trim();

      url.searchParams.set('callrange', startVal + " TO " + endVal);
    }

    window.history.pushState({update: url.search}, '', url);
    setSearch(url);
    
    setTimeout(()=> {
      try {
        if (url.searchParams.get('search') === null) {
          document.querySelector('#global-search-text-input').value = '';
        }
      } catch {}
    }, 1);
    
    setTimeout(() => {
      hideLastFiltersHorizontalRule();
    }, 0);
  }

  const filterProps = {
    'functions':{
      'updateSearchData': updateSearchData
    }
  }

  let searchbarValue = '';
  if (hasTypedSearchbar === false) {
    if (url.searchParams.get('search') !== null) {
      searchbarValue = url.searchParams.get('search');
    }
  }

  const fullWidth = {width: '100%'}

  try {
    if (responseData.denied === 'List permission denied') {
      return (
        <SolrContext.Provider value={responseData}>
          <div className="App search-page">
            <NavBar></NavBar>
            <NoInternetMessage />
            <div className='main-app-container'>
              <header className="app-header">
                <a href="/" className='breadcrumb list-access-denied'>Back to search</a>
                <h1>Booklist Access Denied</h1>
              </header>
              <section></section>
            </div>
            <Footer></Footer>
            <BottomNav></BottomNav>
            <OverlayContainer setLoginState={setLoginState} selectedBibId={selectedBibId} solrData={responseData}></OverlayContainer>
          </div>
        </SolrContext.Provider>
      )
    }
  } catch {}

  
  return (
    <SolrContext.Provider value={responseData}>
      <div className="App search-page">
        <NavBar></NavBar>
        <NoInternetMessage />
        <div className='main-app-container'>
          <BreadCrumb></BreadCrumb>
          <header className="app-header">
            
            <SearchPageHeader updateSearchData={updateSearchData}></SearchPageHeader>

          </header>
          
          <section>

            <div className='filter-container'>
              <Filters solrData={responseData} filterProps={filterProps} ></Filters>
            </div>

            <ToggleFiltersOverlayButton></ToggleFiltersOverlayButton>

            <div style={fullWidth}>
              <ActiveFiltersContainer filterProps={filterProps}></ActiveFiltersContainer>
              <SearchResultsContainer setSelectedBibId={setSelectedBibId} solrData={responseData} filterProps={filterProps}></SearchResultsContainer>
            </div>
            
          </section>

        </div>

        <Footer></Footer>
        <BottomNav></BottomNav>
        <OverlayContainer setLoginState={setLoginState} selectedBibId={selectedBibId} solrData={responseData}></OverlayContainer>
      </div>
    </SolrContext.Provider>
  )


  
}

export default SearchApp;