const arrayUnique = (a) => {

  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  let unique = a.filter(onlyUnique);

  return unique;

};

export default arrayUnique;