import { useState, useEffect } from "react";
import sortBibGroup from "../functions/sortBibGroup";

const useFetch = (url) => {
  const [data, setData] = useState(null);

  useEffect(() => {

    try {
      document.querySelector('#small-loading-overlay').classList.add('hide');
      setTimeout(()=>{document.querySelector('#small-loading-overlay').classList.remove('hide');}, 0);
    } catch {}

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        try {
          if (typeof data.grouped !== 'undefined') {
            for (let i = 0; i < data.grouped.ss_grouping.groups.length; i++) {
              data.grouped.ss_grouping.groups[i].doclist.docs = sortBibGroup(data.grouped.ss_grouping.groups[i].doclist.docs);
            }
          }
        } catch {}
        setData(data);
        document.querySelector('#small-loading-overlay').classList.add('hide');
      })
    
  }, [url]);

  return [data];
};

export default useFetch;