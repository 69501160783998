import toggleOverlay from '../../functions/toggleOverlay';
import {useContext} from "react";
import { UserContext } from '../..';
import setCookie from '../../functions/setCookie';
import ReactGA from 'react-ga4';

const GetThisItemButton = (props) => {

  const user = useContext(UserContext);

  const getThisItem = (e) => {

    if (user.data.isSession) {
      props.setSelectedBibId(props.bib);
      toggleOverlay('#get-this-item-overlay', e);
      
      setTimeout(() => {
        document.querySelector('#get-this-item-overlay a.individual-format[bib="' + props.bib + '"]').click();
      }, 0 );
      
    } else {
      setCookie('opacLC','.get-this-item[bib="'+ e.currentTarget.attributes.bib.value +'"]', 1);
      toggleOverlay('#login-overlay', e);
    }

  };
  
  return (
    <button onClick={getThisItem} bib={props.bib} className="get-this-item"><img alt="plus" src="/plus.svg" /> Get this item</button>
  )
  
};

export default GetThisItemButton;
