import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import PlusSign from "../icons/plusSign";
import CatalogFormatIcon from "../icons/catalogFormatIcon";
import postData from "../../functions/postData";
import { useState } from "react";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import configs from "../../functions/configs";
import fallbackImageHandler from "../../functions/fallbackImageHandler";
import onLoadImageHandler from "../../functions/onLoadImageHandler";
import IconRobot from "../icons/iconRobot";
import detectUserAgent from "../../functions/detectUserAgent";

const lists = {};

const updates = [];

let dataGrabbed = false;
let initialLoad = true;

const AddToListOverlay = (props) => {
    
    const conf = configs();
    const url = new URL(window.location.href);

    const [formatSelection, setFormatSelection] = useState('');
    const [listState, setlistState] = useState('');

    const closeOverlay = (e) => {
        try {
            for (let i = 0; i < updates.length; i++) {
                delete updates[i];
            }
            updates.sort()
        } catch {}
        toggleOverlay("#add-to-list-overlay", e);
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    if (dataGrabbed === false && initialLoad === false) {
        dataGrabbed = true;
        postData(conf.baseAPI + "/user/return-lists.php").then((res) => {
            lists.data = res;
            
            try {
                if (res.length) {
                    setlistState('loaded');
                } else {
                    setlistState('denied');
                }
            } catch {
                setlistState('denied');
            }
        });
    }

    const selectedBib = props.selectedBibId;
    const solrData = props.solrData;
    const formatArray = [];

    try {
        if (url.pathname === '/item') {
            for (let i = 0; i < solrData.response.docs.length; i++) {
                formatArray.push({
                    bib: solrData.response.docs[i].id,
                    format: solrData.response.docs[i].material_type,
                    year: solrData.response.docs[i].publishYear,
                }); 
            }
        } else {
            if (typeof solrData.grouped !== 'undefined') {
                const groupedBibs = {};
                for (let i = 0; i < solrData.grouped.ss_grouping.groups.length; i++) {
                    for (let x = 0; x < solrData.grouped.ss_grouping.groups[i].doclist.docs.length; x++) {
                        if (solrData.grouped.ss_grouping.groups[i].doclist.docs[x].id === selectedBib) {
                            groupedBibs.data = solrData.grouped.ss_grouping.groups[i].doclist.docs;
                        }
                    }
                }

                for (let i = 0; i < groupedBibs.data.length; i++) {
                    formatArray.push({
                        bib: groupedBibs.data[i].id,
                        format: groupedBibs.data[i].material_type,
                        year: groupedBibs.data[i].publishYear
                    }); 
                }

            } else if (typeof solrData.response !== 'undefined') {
                for (let i = 0; i < solrData.response.docs.length; i++) {
                    if (solrData.response.docs[i].id === selectedBib) {
                        formatArray.push({
                            bib: solrData.response.docs[i].id,
                            format: solrData.response.docs[i].material_type,
                            year: solrData.response.docs[i].publishYear
                        }); 
                    }
                }
            }
        }
    } catch {}

    const selectFormat = (e) => {
        if (listState === 'denied') {
            dataGrabbed = false;
            initialLoad = false;
            setlistState('try again ' + Date.now());
        }
        try {
            setFormatSelection(e.currentTarget.attributes.bib.value);
        } catch {}
    }

    const Format = (props) => {

        if (props.bib === formatSelection) {
            return (
                <button bib={props.bib} className="format-overlay-select-button selected" title="Selected format" aria-label="format selection">
                    <CatalogFormatIcon format={props.format}></CatalogFormatIcon>
                    {props.format} - {props.year}
                </button>
            )
        } else {
            return (
                <button bib={props.bib} onClick={selectFormat} className="format-overlay-select-button" title="Select this format" aria-label="format selection">
                    <CatalogFormatIcon format={props.format}></CatalogFormatIcon>
                    {props.format} - {props.year}
                </button>
            )
        }
        
    }

    const Formats = (props) => {
        try {
            return formatArray.map((item) => <Format key={item.bib} bib={item.bib} format={item.format} year={item.year}></Format>);
        } catch {}
    }

    const SelectFormatContainer = (props) => {
        try {
            if (formatArray.length > 1) {
                return (
                    <div>
                        <div className="select-format-for-list-label bold">Select Format</div>
                        <div className="dark-gray select-format-for-list-subtext">Select what format you'd like to add to your booklist(s).</div>
                        <div className="list-overlay-formats-container">
                            <Formats></Formats>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <div className="select-format-for-list-label bold">Selected Format</div>
                        <div className="list-overlay-formats-container">
                            <Formats></Formats>
                        </div>
                    </div>
                )
            }
        } catch {
            return (
                <div className="list-overlay-formats-container hide">
                    <Formats></Formats>
                </div>
            )
        }
    }

    const handleInputClick = (e) => {
        const isChecked = e.currentTarget.checked;
        let action = 'add';

        if (!isChecked) {
            action = 'remove';
        }

        try {
            for (let i = 0; i < updates.length; i++) { // delete previous actions on the same list/format
                if (updates[i].bib === formatSelection && updates[i].list === e.currentTarget.attributes.listid.value) {
                    updates.splice(i, 1);
                    i--;
                }
            }
        } catch {}

        try {

            let inList = false;
            for (let i = 0; i < lists.data.length; i++) { // do nothing if going back to original checked state
                for (let x = 0; x < lists.data[i].items.length; x++) {
                    let item = JSON.parse(lists.data[i].items[x].item_value);
                    if (item.bib === formatSelection && lists.data[i].booklist_id === e.currentTarget.attributes.listid.value) {
                        inList = true;
                    }
                }
            }
            if (action === 'add') {
                if (inList) {
                    return '';
                }
            }
            if (action === 'remove') {
                if (!inList) {
                    return '';
                }
            }

        } catch {}

        updates.push({
            list: e.currentTarget.attributes.listid.value,
            bib: formatSelection,
            action: action,
            time: Date.now()
        });
    }

    const List = (props) => {

        let listAlt = props.data.title + ' cover art';
        let imgSrc = '';
        try {
            let firstitem = JSON.parse(props.data.items[0].item_value);
            imgSrc = firstitem.img;
        } catch {}
        
        let defaultChecked = '';
        try {
            for (let i = 0; i < props.data.items.length; i++) {
                let item = JSON.parse(props.data.items[i].item_value);
                if (item.bib === formatSelection) {
                    defaultChecked = 'checked';
                }
            }
        } catch {}

        try {
            for (let i = 0; i < updates.length; i++) {
                if (updates[i].bib === formatSelection && updates[i].action === 'add' && props.data.booklist_id === updates[i].list) {
                    defaultChecked = 'checked';
                }
                if (updates[i].bib === formatSelection && updates[i].action === 'remove' && props.data.booklist_id === updates[i].list) {
                    defaultChecked = '';
                }
            }
        } catch {}

        let count = '0 items';
        try {
            if (props.data.items.length === 1) {
                count = '1 item';
            } else {
                count = props.data.items.length + ' items';
            }
        } catch {}

       return (
            <div className="overlay-user-list">
                <div className="overlay-list-img"><img onLoad={onLoadImageHandler} onError={fallbackImageHandler} alt={listAlt} src={imgSrc}></img></div>
                <div className="overlay-list-title"><div className="bold">{props.data.title}</div><div className="dark-gray">{count}</div></div>
                <div className="overlay-list-checkbox"><label className="sr-only">Add to list</label><input onClick={handleInputClick} defaultChecked={defaultChecked} listid={props.data.booklist_id} title='Add to list' type="checkbox"></input></div>
            </div>
       ) 
    }

    const ListsApp = (props) => {
        try {
            if (lists.data.length === 0) {
                return (
                    <div>You don't have any lists!</div>
                )
            }
        } catch {
            return (
                <div>Beep Boop. Gathering data... &nbsp; <IconRobot></IconRobot></div>
            )
        }

        try {
            return lists.data.map(item => <List key={item.booklist_id} data={item}></List>);
        } catch {}
    }

    const applyUpdates = (e) => {
        console.log(updates);
        toggleLoadingOverlay();
        let userAgent = detectUserAgent();
        postData(conf.baseAPI + "/user/add-to-list.php", 'agent=' + userAgent + '&updates=' + encodeURIComponent(JSON.stringify(updates))).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            toggleOverlay('#add-to-list-overlay');
            try {
                for (let i = 0; i < updates.length; i++) {
                    delete updates[i];
                }
                updates.sort()
            } catch {}
            
            if (res === null) { // success
                dataGrabbed = false;
                initialLoad = false;
                setlistState('updated ' + Date.now());
                launchSuccessOverlay(
                    "List(s) updated.",
                    "<a href='/my-account#lists'>View lists</a>"
                );
            } else if (typeof res.sessionExpired !== 'undefined') {
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                launchErrorOverlay(res.message, '');
            }
        });
        
    }

    const createNewList = (e) => {
        toggleOverlay('#create-new-list-overlay');
    }

    const forceStateUpdate = () => {
        dataGrabbed = false;
        initialLoad = false;
        setlistState('updated ' + Date.now());
    }

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="add-to-list-overlay" className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Add to List</h3>
                <button onClick={createNewList} className="bold" id="create-new-list-btn-overlay"><PlusSign></PlusSign> Create new list</button>
                <hr></hr>
                <div id="overlay-lists-container">
                    <ListsApp></ListsApp>
                </div>
                <hr></hr>
                <SelectFormatContainer></SelectFormatContainer>
                <button onClick={applyUpdates} className="default-blue-button" id="create-list-overlay-save">Save updates</button>
                <button onClick={forceStateUpdate} className="hide" id="add-to-list-overlay-force-state-update">Update</button>
            </div>
        </div>
    )
} 

export default AddToListOverlay;