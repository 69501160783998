
const IconEllipsis = (props) => {

    let height = 16;

    if (typeof props.height !== 'undefined') {
        height = props.height;
    }

    return (
        <svg height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 256a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zm-160 0a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zM64 288a32 32 0 1 1 0-64 32 32 0 1 1 0 64z"/></svg>
    )
    
}

export default IconEllipsis;