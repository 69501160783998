
const ProTipIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="25" viewBox="0 0 17 25" fill="#4A4C7C">
      <path d="M12.3636 18.75C12.8273 17.1924 13.7884 15.8643 14.7398 14.541C14.9909 14.1943 15.242 13.8477 15.4835 13.4961C16.4398 12.1045 17 10.4199 17 8.59863C17 3.84766 13.1943 0 8.5 0C3.80568 0 0 3.84766 0 8.59375C0 10.415 0.560227 12.1045 1.51648 13.4912C1.75795 13.8428 2.00909 14.1895 2.26023 14.5361C3.21648 15.8594 4.17756 17.1924 4.63636 18.7451H12.3636V18.75ZM8.5 25C10.6347 25 12.3636 23.252 12.3636 21.0938V20.3125H4.63636V21.0938C4.63636 23.252 6.36534 25 8.5 25ZM4.63636 8.59375C4.63636 9.02344 4.28864 9.375 3.86364 9.375C3.43864 9.375 3.09091 9.02344 3.09091 8.59375C3.09091 5.57129 5.51051 3.125 8.5 3.125C8.925 3.125 9.27273 3.47656 9.27273 3.90625C9.27273 4.33594 8.925 4.6875 8.5 4.6875C6.36534 4.6875 4.63636 6.43555 4.63636 8.59375Z" fill="#4A4C7C"/>
    </svg>
  )
};

export default ProTipIcon;
