
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";

const CatalogAgeFilters = (props) => { 

  const url = new URL(window.location.href);

  let catalogKidsActive = 'no';
  let catalogTeensActive = 'no';
  let catalogAdultsActive = 'no';
  
  if (url.searchParams.get('targetage') !== null) {
    if (url.searchParams.get('targetage').indexOf('Kids') > -1) {
      catalogKidsActive = 'yes';
    }
    if (url.searchParams.get('targetage').indexOf('Adults') > -1) {
      catalogAdultsActive = 'yes';
    }
    if (url.searchParams.get('targetage').indexOf('Teens') > -1) {
      catalogTeensActive = 'yes';
    }
  }

  return (
    <div className={props.catalogFilterGrouping}>
      <a href="#" className="toggle-filterset age" onClick={toggleFilterset} aria-label="expand filter set"><h4>Age</h4><ThickCaretDown flip width="15"></ThickCaretDown></a>
      <div className="age-filters filterset open">
        <button onClick={props.filterProps.functions.updateSearchData} activated={catalogKidsActive} filtertype="catalog-age" value="Kids" className="filter-square-btn catalog-age-filter kids"><span className="large-filter-text">0-12</span>Kids</button>
        <button onClick={props.filterProps.functions.updateSearchData} activated={catalogTeensActive} filtertype="catalog-age" value="Teens" className="filter-square-btn catalog-age-filter teens"><span className="large-filter-text">13-17</span>Teens</button>
        <button onClick={props.filterProps.functions.updateSearchData} activated={catalogAdultsActive} filtertype="catalog-age" value="Adults" className="filter-square-btn catalog-age-filter adults"><span className="large-filter-text">18+</span>Adults</button>
      </div>
      <hr></hr>
    </div>
  )

}

export default CatalogAgeFilters;