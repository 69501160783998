const hideLastFiltersHorizontalRule = () => {
    try {
        // hide last HR - looks funny with fixed bottom HR
        if (window.innerWidth <= 1100) {
        function isHidden(el) {
            if (el.offsetParent === null) {
            return true;
            } else {
            return false;
            }
        }
        const filterHR = document.querySelectorAll('.filters-main-content hr');
        const displayedHRs = [];
        for (let i = 0; i < filterHR.length; i++) {
            if (!isHidden(filterHR[i])) {
            displayedHRs.push(filterHR[i]);
            }
        }

        for (let x = 0; x < displayedHRs.length; x++) {
            if (x === (displayedHRs.length - 1)) {
            displayedHRs[x].style.visibility = 'hidden';
            } else {
            displayedHRs[x].style.visibility = 'visible';
            }
        }

        }
    } catch {}
}

export default hideLastFiltersHorizontalRule;