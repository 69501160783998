import './App.css';
import { useState, useContext } from "react";
import NavBar from "./components/shared/navBar";
import Footer from "./components/shared/footer";
import configs from "./functions/configs";
import OverlayContainer from "./components/overlays/overlayContainer";
import BottomNav from "./components/shared/bottomNav";
import MyAccountContentContainer from "./components/accountPage/myAccountContentContainer";
import toggleLoadingOverlay from './functions/toggleLoadingOverlay';
import postData from './functions/postData';
import { UserContext } from '.';
import IconFees from './components/icons/iconFees';
import IconRenew from './components/icons/iconRenew';
import IconAngleRight from './components/icons/iconAngleRight';
import IconAngleLeft from './components/icons/iconAngleLeft';
import IconTriangleExclamation from './components/icons/iconTriangleExclamation';
import getData from './functions/getData';
import NotOnlineScreen from './components/shared/notOnlineScreen';
import clearCache from './functions/clearCache';
import NoInternetMessage from './components/shared/noInternetMessage';
import launchErrorOverlay from './functions/launchErrorOverlay';
import detectUserAgent from './functions/detectUserAgent';

let popstateFired = false;

let clickedHash = false;

let checkedSession = false;

let redirectingToOnlineResource = false;

function AccountApp() {

  const user = useContext(UserContext);

  const conf = configs();

  const [readyState, setReadyState] = useState('loading');

  const [loginState, setLoginState] = useState('');

  const [accountSelection, setAccountSelection] = useState({type: 'checkouts'});

  const url = new URL(window.location);

  const isOnline = window.navigator.onLine;

  if (checkedSession === false || loginState.indexOf('account added') > -1) {
    getData(conf.baseAPI + "/user/check-session.php", "").then( data => {

      checkedSession = true;
      user.data = data;
  
      try {
        delete user.data.session.bpl_catalog.pin;
        delete user.data.session.bpl_catalog.overdrive_api_key;
        if (typeof user.data.session.bpl_catalog.accounts !== 'undefined') {
          for (let i = 0; i < user.data.session.bpl_catalog.accounts.length; i++) {
            delete user.data.session.bpl_catalog.accounts[i].pin;
          }
        }
      } catch {}

      
  
      setReadyState('ready');

      try {
        if (typeof user.data.session.bpl_catalog.id !== 'undefined') {
          if (url.searchParams.get('res') !== null) {
            window.location.href = 'https://www.bklynlibrary.org/online-resources-login?res=' + url.searchParams.get('res');
            redirectingToOnlineResource = true;
            setReadyState('onlineResourceLogin');
            
          }
        }
        
      } catch {}
      
    });
  }
  

  try {
    if (window.location.href.indexOf('my-account#lists') > -1) {
      if (clickedHash === false) {
        clickedHash = true;
        setTimeout(()=>{
          document.querySelector('#booklists').click();
        },500);
      }
    }
  } catch {}

  window.addEventListener('popstate', function(event) {//browser back or forward button click force reload  
    if (!popstateFired) {
      popstateFired = true;
      this.location.reload();
    } else {
      event.preventDefault();
    }
  });

  document.title = 'My Account - Brooklyn Public Library';

  const toggleMyAccountSection = (e) => {

    try {
      const currentSelection = e.currentTarget;

      if (currentSelection.classList.contains('active')) {
        return '';
      }

      const previousSelection = document.querySelector('button.my-account-section.active');
      const previousSelectionRect = previousSelection.getBoundingClientRect();
      //console.log('previous selection rect', previousSelectionRect);
      const currentSelectionRect = currentSelection.getBoundingClientRect();
      //console.log('new selection rect', currentSelectionRect);
      const buttons = document.querySelectorAll('button.my-account-section');
      
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].classList.remove('active');
      }

      currentSelection.classList.add('active');

      const underline = document.querySelector('#my-account-section-underline');
      const underlineContainerRect = document.querySelector('#my-account-section-underline-track').getBoundingClientRect();
      
      // does the underline shimmy slide
      if (currentSelectionRect.left > previousSelectionRect.left) { // moving right
        setTimeout(()=> {
          underline.style.left = (currentSelectionRect.left - underlineContainerRect.left) + 'px';
          underline.style.width = currentSelectionRect.width + 'px';
        }, 0);
        
      } else if (currentSelectionRect.left < previousSelectionRect.left) { // moving left
        setTimeout(()=> {
          underline.style.left = (currentSelectionRect.left - underlineContainerRect.left) + 'px';
          underline.style.width = currentSelectionRect.width + 'px';
        }, 0);
      }

      setAccountSelection({type: currentSelection.id});

    } catch {}

  }

  const MyAccountLoginForm = () => {

    const loginMyAccount = (e) => {

      e.preventDefault();

      clearCache();

      try {
        document.querySelector("a.login-logout-button").focus();
      } catch {}

      document.querySelector('#my-account-login-invalid-msg').classList.add('hide');

      let params = 'code=' + encodeURIComponent(document.querySelector('#my-account-login-barcode').value.replace(/ /g, ''));
      params += '&pin=' + encodeURIComponent(document.querySelector('#my-account-login-pin').value);
      params += '&agent=' + detectUserAgent();

      toggleLoadingOverlay();
  
      postData(conf.baseAPI + "/user/login.php", params).then( res => {
    
        toggleLoadingOverlay();

        if (res.description === "Invalid parameter : Invalid barcode or PIN") {
          document.querySelector('#my-account-login-invalid-msg').classList.remove('hide');
          document.querySelector('#my-account-login-invalid-msg').focus();
        } else if (typeof res.error !== 'undefined') { // network error
          document.querySelector('#edit-names-overlay button.close-overlay').click();
          launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
        } else if (typeof res.name !== 'undefined') { // other error
          document.querySelector('#edit-names-overlay button.close-overlay').click();
          launchErrorOverlay('Sorry, a network error occurred. ' + res.name);
        } else {
          // success
          checkedSession = false;
          user.data.isSession = true;
          user.data.session = res;
          setReadyState('loggedIn');

          try {
            if (url.searchParams.get('res') !== null) {
              window.location.href = 'https://www.bklynlibrary.org/online-resources-login?res=' + url.searchParams.get('res');
              redirectingToOnlineResource = true;
              setReadyState('onlineResourceLogin');
              
            }
          } catch {}

          try {
            document.querySelector('a.login-logout-button').innerHTML = "Logout";
            document.querySelector('a.login-logout-button').setAttribute('href', '/logout'); 
          } catch (err) {console.log(err)}
        }
    
      });
    }

    return (
      <div className='my-account-login-container'>
        <div>
        <h2>Log In</h2>

          <div id="my-account-login-invalid-msg" className="hide">Invalid credentials. Please try again, or <a href="/pin-reset-form">reset your pin</a>.</div>

          <form onSubmit={loginMyAccount} id="my-account-login-form">

            <label htmlFor="my-account-login-barcode">Barcode/Username</label>
            <input id="my-account-login-barcode" type="text"></input>

            <br></br>
            <label htmlFor="my-account-login-pin">PIN</label>
            <input id="my-account-login-pin" type="password"></input>
            <br></br>
            <a href="/pin-reset">Reset pin</a> or <a href="https://www.bklynlibrary.org/card/">get a card</a>
            <button type="submit">Log in</button>

          </form>
        </div>
        
      </div>
    )
  }

  if (redirectingToOnlineResource) {
    return (
      <div className="App account-page">
        <NavBar></NavBar>
        <NoInternetMessage />
        <div className='main-app-container'>Redirecting to your online resource...</div>
        <Footer></Footer>
        <BottomNav></BottomNav>
        <OverlayContainer></OverlayContainer>
      </div>
    )
  }

  try {

    if (typeof user.data === 'undefined') {
      // waiting to see if user is logged in..
      return (
        <div className="App account-page">
          <NavBar></NavBar>
          <NoInternetMessage />
          <div className='main-app-container'></div>
          <Footer></Footer>
          <BottomNav></BottomNav>
          <OverlayContainer></OverlayContainer>
        </div>
      )
    }

    if (user.data.isSession === false) {

      return (
        <div className="App account-page">
          <NavBar></NavBar>
          <NoInternetMessage />
          <div className='main-app-container'>
            <MyAccountLoginForm></MyAccountLoginForm>
          </div>
          <Footer></Footer>
          <BottomNav></BottomNav>
          <OverlayContainer></OverlayContainer>
        </div>
      )

    }

    if (user.data.isSession === true) {

      let name = '';

      try {
        name = user.data.session.bpl_catalog.name.replace(/^.+\,/, '');
        if (name === '') {
          name = user.data.session.bpl_catalog.name;
        }
        name = name.trim();
        name = name.toLowerCase();
      } catch {}

      const Fees = () => {
        let fees = 0;
        try {
          fees = user.data.session.bpl_catalog.money_owed;
        } catch {}

        if (fees > 0) {
          return (
            <div className='fees-alert white-tile-container'>
              <div className='flex-small-gap'>
                <div>
                  <IconFees></IconFees>
                </div>
                <div>
                  You owe ${fees}. <a target="_blank" href="https://apps.bklynlibrary.org/patron_fees/myaccount/charges.aspx?">Pay/view fees</a> or learn more <a target="_blank" href="https://www.bklynlibrary.org/use-the-library/fees">about fees</a>.
                </div>
              </div>
            </div>
          )
        }
      }

      const postForm = (e) => {
        e.preventDefault();
        document.querySelector('#post-renewal-barcode input[type="submit"]').click();
      }

      const Renew = (props) => {
        try {
          const expiration = new Date(user.data.session.bpl_catalog.expiration_date);
          const expirationTime = Math.floor(expiration.getTime() / 1000);
          const nowTime = Math.floor(Date.now() / 1000);
          const sixtyDays = 5184000;
          const thirtyDays = 2592000;
          const oneHundredDays = 8640000;
          const oneDay = 86400;

          let formattedExpirationDate = '';
          try {
            formattedExpirationDate = expiration.toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          } catch {}

          if (conf.welcomeCardTypes.includes(user.data.session.bpl_catalog.ptype)) {
            if (nowTime >= expirationTime) {
              return (
                <div className='renew-alert white-tile-container'>
                  <div className='flex-small-gap'>
                    <div>
                      <IconRenew></IconRenew>
                    </div>
                    <div>
                      Your account expired! To <a id='renewal-link' onClick={postForm} href='https://www.bklynlibrary.org/ecardr/start.php'>renew your account</a> online for another year, please <a target="_blank" href="https://www.bklynlibrary.org/share-location">accept location sharing</a> when prompted. 
                      Otherwise, <a target='_blank' href='https://www.bklynlibrary.org/contact'>Contact Us</a> or visit any BPL branch to renew.
                    </div>
                  </div>
                  <form target='_blank' id='post-renewal-barcode' className='hide' action='https://www.bklynlibrary.org/ecardr/start.php' method='POST'>
                    <input type='text' id='txtbarcode' name='txtbarcode' value={user.data.session.bpl_catalog.barcode} />
                    <input type='text' name='ptype' value={user.data.session.bpl_catalog.ptype} />
                    <input type='submit' value='Submit' />
                  </form>
                </div>
              )
            }
            if ((nowTime + oneHundredDays) >= expirationTime) {
              return (
                <div className='renew-alert white-tile-container'>
                  <div className='flex-small-gap'>
                    <div>
                      <IconRenew></IconRenew>
                    </div>
                    <div>
                      It's time to renew your library account! To <a id='renewal-link' onClick={postForm} href='https://www.bklynlibrary.org/ecardr/start.php'>renew your account</a> online for another year, please <a target="_blank" href="https://www.bklynlibrary.org/share-location">accept location sharing</a> when prompted. 
                      Otherwise, <a target='_blank' href='https://www.bklynlibrary.org/contact'>Contact Us</a> or visit any BPL branch to renew.
                    </div>
                  </div>
                  <form target='_blank' id='post-renewal-barcode' className='hide' action='https://www.bklynlibrary.org/ecardr/start.php' method='POST'>
                    <input type='text' id='txtbarcode' name='txtbarcode' value={user.data.session.bpl_catalog.barcode} />
                    <input type='text' name='ptype' value={user.data.session.bpl_catalog.ptype} />
                    <input type='submit' value='Submit' />
                  </form>
                </div>
              )
            }
          } else if (conf.booksUnbannedTypes.includes(user.data.session.bpl_catalog.ptype)) {
            if (nowTime >= expirationTime) {
              return (
                <div className='renew-alert white-tile-container'>
                  <div className='flex-small-gap'>
                    <div>
                      <IconRenew></IconRenew>
                    </div>
                    <div>
                      Your account expired! To <a id='renewal-link' onClick={postForm} href='https://www.bklynlibrary.org/ecardr/start.php'>renew your account</a> online for another year, please <a target="_blank" href="https://www.bklynlibrary.org/share-location">accept location sharing</a> when prompted. 
                      Otherwise, <a target='_blank' href='https://www.bklynlibrary.org/contact'>Contact Us</a> or visit any BPL branch to renew.
                    </div>
                  </div>
                  <form target='_blank' id='post-renewal-barcode' className='hide' action='https://www.bklynlibrary.org/ecardr/start.php' method='POST'>
                    <input type='text' id='txtbarcode' name='txtbarcode' value={user.data.session.bpl_catalog.barcode} />
                    <input type='text' name='ptype' value={user.data.session.bpl_catalog.ptype} />
                    <input type='submit' value='Submit' />
                  </form>
                </div>
              )
            }
            if ((nowTime + oneHundredDays) >= expirationTime) {
              return (
                <div className='renew-alert white-tile-container'>
                  <div className='flex-small-gap'>
                    <div>
                      <IconRenew></IconRenew>
                    </div>
                    <div>
                      It's time to renew your library account! To <a id='renewal-link' onClick={postForm} href='https://www.bklynlibrary.org/ecardr/start.php'>renew your account</a> online for another year, please <a target="_blank" href="https://www.bklynlibrary.org/share-location">accept location sharing</a> when prompted. 
                      Otherwise, <a target='_blank' href='https://www.bklynlibrary.org/contact'>Contact Us</a> or visit any BPL branch to renew.
                    </div>
                  </div>
                  <form target='_blank' id='post-renewal-barcode' className='hide' action='https://www.bklynlibrary.org/ecardr/start.php' method='POST'>
                    <input type='text' id='txtbarcode' name='txtbarcode' value={user.data.session.bpl_catalog.barcode} />
                    <input type='text' name='ptype' value={user.data.session.bpl_catalog.ptype} />
                    <input type='submit' value='Submit' />
                  </form>
                </div>
              )
            }

          } else {

            if (user.data.session.bpl_catalog.ptype === 40) { // eCard ptype which is being phased out 11/26/24
              if (nowTime >= expirationTime) {
                return (
                  <div className='renew-alert white-tile-container'>
                    <div className='flex-small-gap'>
                      <div>
                        <IconRenew></IconRenew>
                      </div>
                      <div>
                        Your account expired on {formattedExpirationDate}! To renew your account, please <a target="_blank" href="https://www.bklynlibrary.org/contact">Contact Us</a>.
                      </div>
                    </div>
                  </div>
                )
              }
  
              if ((nowTime + oneHundredDays) >= expirationTime) {
                return (
                  <div className='renew-alert white-tile-container'>
                    <div className='flex-small-gap'>
                      <div>
                        <IconRenew></IconRenew>
                      </div>
                      <div>
                        Your account will expire on {formattedExpirationDate}! To renew your account, please <a target="_blank" href="https://www.bklynlibrary.org/contact">Contact Us</a>.
                      </div>
                    </div>
                  </div>
                )
              }
            }

            // MOST PTYPES
            if (nowTime >= expirationTime) {
              return (
                <div className='renew-alert white-tile-container'>
                  <div className='flex-small-gap'>
                    <div>
                      <IconRenew></IconRenew>
                    </div>
                    <div>
                      Your account expired! To renew your account, please visit any BPL branch with valid ID. <a target='_blank' href='https://www.bklynlibrary.org/use-the-library/renewals-and-lost-cards'>Learn more about library cards</a>.
                    </div>
                  </div>
                </div>
              )
            }

            if ((nowTime + oneHundredDays) >= expirationTime) {
              return (
                <div className='renew-alert white-tile-container'>
                  <div className='flex-small-gap'>
                    <div>
                      <IconRenew></IconRenew>
                    </div>
                    <div>
                      It's time to renew your library account! To renew your account, please visit any BPL branch with valid ID. <a target='_blank' href='https://www.bklynlibrary.org/use-the-library/renewals-and-lost-cards'>Learn more about library cards</a>.
                    </div>
                  </div>
                </div>
              )
            }
          }
        } catch {}
      }

      const scrollRight = () => {
        const container = document.querySelector('.my-account-section-container');
        container.scrollTo({
          top: 0,
          left: 900,
          behavior: "smooth"
        });
      }

      const scrollLeft = () => {
        const container = document.querySelector('.my-account-section-container');
        container.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }

      const adjustUnderline = () => {
        if (window.innerWidth < 500) {
          const buttonsContainer = document.querySelector('.my-account-section-container');
          //console.log(buttonsContainer.scrollLeft);
          if (buttonsContainer.scrollLeft < 5) {
            document.querySelector('#my-account-scroll-left').classList.add('hide');
            document.querySelector('#my-account-scroll-right').classList.remove('hide');
          } else {
            document.querySelector('#my-account-scroll-left').classList.remove('hide');
            document.querySelector('#my-account-scroll-right').classList.add('hide');

          }
        }
        const currentSelection = document.querySelector('button.my-account-section.active').getBoundingClientRect();

        const underline = document.querySelector('#my-account-section-underline');
        const underlineContainerRect = document.querySelector('#my-account-section-underline-track').getBoundingClientRect();
        underline.classList.add('no-transition');
        setTimeout(()=> {
          underline.style.left = (currentSelection.left - underlineContainerRect.left) + 'px';
          underline.classList.remove('no-transition');
        }, 0);
      }

      window.onresize = adjustUnderline;

      const marginRight= {marginRight: "20px"};

      let scrollRightClass = 'hide';

      if (window.innerWidth < 500) {
        scrollRightClass = '';
      }

      const ParkSlopeClosure = () => {

        let containerClasses = 'renew-alert white-tile-container hide';
        try {
          if (user.data.session.bpl_catalog.home_library === '53') {
            containerClasses = 'renew-alert white-tile-container';
          }
        } catch {}

        return (
          <div id="park-slope-closure-message" className={containerClasses}>
          <div className='flex-small-gap'>
            <div>
              <IconTriangleExclamation />
            </div>
            <div>
            Park Slope Library will be closed Friday, June 28th until Tuesday, July 16th for building maintenance. Holdshelf materials will not expire and will be available for pickup for one week after the branch reopens.</div>
          </div>
        </div>
        )
      }

      return (
        <div className="App account-page">
          <NavBar></NavBar>
          <NoInternetMessage />
          <div className='main-app-container'>
            <header className="app-header">
              <div className='bold'>MY ACCOUNT</div>
              <h1>Hi {name}!</h1>
            </header>
            <section>

              <div className='my-account-alerts'>
                <Renew></Renew>
                <Fees></Fees>
                
              </div>
              
              <div onScroll={adjustUnderline} className='my-account-section-container'>
                <button className='hide' onClick={scrollLeft} id="my-account-scroll-left"><IconAngleLeft height="26"></IconAngleLeft><span className='sr-only'>scroll left</span></button>
                <button id="checkouts" onClick={toggleMyAccountSection} className='my-account-section active'>Checkouts</button>
                <button id="holds" onClick={toggleMyAccountSection} className='my-account-section'>Holds</button>
                <button id="booklists" onClick={toggleMyAccountSection} className='my-account-section'>Booklists</button>
                <button id="history" onClick={toggleMyAccountSection} className='my-account-section'>History</button>
                <button style={marginRight} id="edit" onClick={toggleMyAccountSection} className='my-account-section'>Edit Account</button>
                <button className={scrollRightClass} onClick={scrollRight} id="my-account-scroll-right"><IconAngleRight height="26"></IconAngleRight><span className='sr-only'>scroll right</span></button>
              </div>
    
              <div id="my-account-section-underline-track">
                <div id="my-account-section-underline"></div>
              </div>
              
    
              <MyAccountContentContainer loginState={loginState} selection={accountSelection}></MyAccountContentContainer>
    
    
            </section>
    
          </div>
          <Footer></Footer>
          <BottomNav></BottomNav>
          <OverlayContainer setLoginState={setLoginState}></OverlayContainer>
        </div>
      )
    }

  } catch (e) {console.log(e);}
  
}

export default AccountApp;