import { SolrContext } from "../../../searchPage";
import React from "react";
import { useState } from "react";
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";

const CatalogAuthorFilters = (props) => { 

    const solrData = React.useContext(SolrContext);

    const [hideExtra, updateHideExtra] = useState(true);

    const url = new URL(window.location.href);

    const updateSearchHandler = props.filterProps.functions.updateSearchData;

    let catalogFilterGrouping = props.catalogFilterGrouping;

    let count = 0;

    let filterLimit = 14;

    let sc = 0; // see counts
    
    try {
        sc = parseInt(localStorage.getItem("seeCountsVisible"));
    } catch {}

    const Pill = (props) => {
        count++;
        try {
            let isActivated = 'no';
            if (props.data.val !== '') {
                // active filter
                let filtersURL = url.searchParams.get('author');
                if (filtersURL !== null) {
                    const filters = filtersURL.split('||');
                    if (filters.indexOf(props.data.val) > -1) {
                    isActivated = 'yes';
                    }
                }
                let classes = 'filter-pill-button';
                if (count > filterLimit && hideExtra) {
                    classes = 'filter-pill-button hide';
                }

                let countPretty = props.data.count.toLocaleString();

                if (sc === 1) {
                    return (
                        <a onClick={updateSearchHandler} filtertype='catalog-author' filterval={props.data.val} activated={isActivated} className={classes} href="#">{props.data.val} <span className="filter-toggle-counts">{countPretty}</span></a>
                    )
                }

                return (
                    <a onClick={updateSearchHandler} filtertype='catalog-author' filterval={props.data.val} activated={isActivated} className={classes} href="#">{props.data.val} <span className="filter-toggle-counts hide">{countPretty}</span></a>
                )
            }
        } catch {}
    }

    const Authors = () => {
        try {
            return solrData.facets.author.buckets.map((item) => <Pill key={item.val} data={item}></Pill>);
        } catch {}
    }

    const showMore = (e) => {
        e.preventDefault();
        if (hideExtra) {
            updateHideExtra(false);
        } else {
            updateHideExtra(true);
            let height = window.scrollY + document.querySelector('#catalog-author-filters').getBoundingClientRect().top - 50;
            window.scrollTo({top: height, behavior: 'smooth'});
        }
    }

    const ShowMoreLink = () => {
        if (count > filterLimit) {
            if (hideExtra) {
                return (<a href="#" className="show-more-filters-link" onClick={showMore}><span className="show-more-text-span">Show more authors</span> <ThickCaretDown width='10px'></ThickCaretDown></a>)
            }
            return (<a href="#" className="show-more-filters-link" onClick={showMore}><span className="show-more-text-span">Show fewer authors</span> <ThickCaretDown width='10px' flip='flip'></ThickCaretDown></a>)
        }
    }

    try {
      if (solrData.facets.author.buckets.length > 0) {
        return (
          <div id="catalog-author-filters" className={catalogFilterGrouping}>
              <a href="#" className="toggle-filterset" onClick={toggleFilterset} aria-label="expand filter set"><h4>Authors</h4><ThickCaretDown width="15"></ThickCaretDown></a>
              <div className="filterset closed">
                <Authors></Authors>
                <ShowMoreLink></ShowMoreLink>
              </div>
              <hr></hr>
          </div>
        )
      }
    } catch {}

}

export default CatalogAuthorFilters;