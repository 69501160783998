import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import postData from "../../functions/postData";
import configs from "../../functions/configs";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import getCookie from "../../functions/getCookie";
import deleteCookie from "../../functions/deleteCookie";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";

const DeleteListOverlay = (props) => {

    const conf = configs();

    const url = new URL(window.location.href);

    const closeOverlay = (e) => {
        toggleOverlay("#delete-list-overlay", e);
        deleteCookie('deleteListId');
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    const deleteList = (e) => {
        toggleLoadingOverlay();

        const cookieVal = getCookie('deleteListId');
        deleteCookie('deleteListId');

        postData(conf.baseAPI + "/user/delete-list.php", "id=" + cookieVal).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            if (res === null) { // success
                try {
                    document.querySelector('#delete-list-overlay button.close-overlay').click();
                    if  (url.pathname === '/my-account') {
                        try {                    
                            document.querySelector('#update-my-account-booklists-state').click();
                        } catch {}
                    }
                    launchSuccessOverlay("List deleted", "");
                } catch {}
            } else if (typeof res.sessionExpired !== 'undefined') {
                document.querySelector('#delete-list-overlay button.close-overlay').click();
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                document.querySelector('#delete-list-overlay button.close-overlay').click();
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                document.querySelector('#delete-list-overlay button.close-overlay').click();
                launchErrorOverlay(res.message, '');
            }
        });
    }

    const darkRed = {color: "darkred", border: "1px solid darkred"};
    const addMargin = {marginTop: "50px", marginBottom: "50px"};

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="delete-list-overlay" className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Delete List</h3>

                <div style={addMargin}>Are you sure you want to delete this list? This action cannot be undone.</div>

                <div className="flex-small-gap">
                    <button style={darkRed} onClick={deleteList} className="default-white-button">Delete list</button>
                    <button onClick={closeOverlay} className="default-white-button">Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteListOverlay;