import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import { useContext } from "react";
import { UserContext } from "../..";
import IconShare from "../icons/iconShare";
import XIcon from "../icons/xIcon";
import IconFacebook from "../icons/iconFacebook";
import IconTwitter from "../icons/iconTwitter";
import IconAngleRight from "../icons/iconAngleRight";
import IconLink from "../icons/iconLink";

const ShareItemButton = (props) => {

  const user = useContext(UserContext);

  let title=props.title;
  let bib=props.bib;
  let btn="Share";
  let currentPageUrl="";

  try {
    currentPageUrl = window.location.href;
  } catch {}

  try {

    //currentPageUrl += props.type+"="+bib;

    title="Check out my booklist: "+props.solrData.original_list.title;
    bib=props.solrData.original_list.booklist_id;
    //currentPageUrl="https://discover.bklynlibrary.org/?";
    btn="Share list";

    const donothide=document.getElementById("share-list-text");
    donothide.classList.add("hide-share-list-text");
  } catch (error) {}

  function hideOptions(){
    const element=document.getElementById("share-options");
    element.classList.add("hide");
  }
  function showOptions(){
    const element=document.getElementById("share-options");
    element.classList.toggle("hide");
  }
  
  

  function copyText(e) {
    e.preventDefault();
    navigator.clipboard.writeText(currentPageUrl);
    launchSuccessOverlay("Link copied to your clipboard.");
    hideOptions();
    return false;
    // alert("Copied the text: " + currentPageUrl);
  }

  let str=title;
  let twttext;
  try {
    twttext=str.replace(" ", "%20");
  } catch (error) {
    
  }
  let encodedurl=encodeURIComponent(currentPageUrl); 
  
  const twittershare="https://twitter.com/intent/tweet?text=" + twttext + "%20via%20%40BKLYNLibrary&url="+encodedurl;
  let facebookshare="https://www.facebook.com/dialog/share?app_id=145634995501895&display=popup&href="+encodedurl;
  
  return (
    <div className="share">
        <button onClick={showOptions} className="share-item"><IconShare></IconShare><span id="share-list-text" > {btn}</span></button>
        <div className="share-options hide" id="share-options">
            <div className="share-arrow">
              <IconAngleRight></IconAngleRight>  
            </div>
            <button title="Hide share options" className="hide-share-options" onClick={hideOptions}><XIcon></XIcon></button>
            <div className="share-copy-link">
                <a className="share-copy-link-only" onClick={copyText}><span className="share-link-icon"><IconLink></IconLink> </span> Copy Link</a>
            </div>
            <div className="share-copy-link">
                <a target="_blank" className="twitter-share-button" 
  href={twittershare}><span className="share-link-icon"><IconTwitter></IconTwitter></span> X</a>
            </div>
            <div className="share-copy-link">
                <a target="_blank" href={facebookshare}><span className="share-link-icon"><IconFacebook></IconFacebook></span> Facebook</a>
            </div>
        </div>
    </div>
  )
  
};

export default ShareItemButton;