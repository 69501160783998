async function postData(url = '', data = '') {
   
  const response = await fetch(url, {
    method: "POST",
    cache: "no-cache", 
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
  if (!response.ok) {
    return new Error('Network error');
  }
  return response.json();
    
}

export default postData;