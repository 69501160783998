import toggleOverlay from "../../functions/toggleOverlay";
import setCookie from "../../functions/setCookie";
import { useContext } from "react";
import { UserContext } from "../..";

const AddToListButton = (props) => {

  const user = useContext(UserContext);

  const addToList = (e) => {

    if (user.data.isSession) {
      props.setSelectedBibId(props.bib);
      toggleOverlay('#add-to-list-overlay', e);
      document.querySelector('#add-to-list-overlay-force-state-update').click();
      setTimeout(() => {
        
        document.querySelector('#add-to-list-overlay button.format-overlay-select-button[bib="' + props.bib + '"]').click();
      }, 0 );
    } else {
        setCookie('opacLC','.add-to-list[bib="'+ e.currentTarget.attributes.bib.value +'"]', 1);
        toggleOverlay('#login-overlay', e);
    }

  }
  
  return (
    <button onClick={addToList} bib={props.bib} className="add-to-list"><img alt="heart" src="/heart.svg" /> Add to list</button>
  )
  
};

export default AddToListButton;