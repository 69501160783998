const LoadingOverlay = (props) => {


    return (
        <div id="loading-overlay" className="hide" role="dialog" aria-label="loading graphic">
            <div className="loading-body">
                <div className="bl loader"></div>
            </div>
        </div>
    )
}

export default LoadingOverlay;