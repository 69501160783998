import configs from "../../functions/configs";
import IconAngleLeft from "../icons/iconAngleLeft";
import IconAnglesLeft from "../icons/iconAnglesLeft";
import IconAngleRight from "../icons/iconAngleRight";
import IconAnglesRight from "../icons/iconAnglesRight";
import { useState } from "react";

const Pagination = (props) => {

    const solr = props.solrData;

    const conf = configs();

    const url = new URL(window.location.href);
    let currentPageDefault = url.searchParams.get('pagination');
    if (currentPageDefault === null) {
        currentPageDefault = 1;
    } else {
        currentPageDefault = parseInt(currentPageDefault);
    }

    try {

        let limit = conf.defaultPageLength;
        let total = 0;

        let pageLimit = 1;

        if (url.searchParams.get('pagination') !== null) {
            limit = parseInt(url.searchParams.get("pagination")) * conf.defaultPageLength;
        }

        if (typeof solr.grouped !== "undefined") {
            total = solr.facets.groupings.numBuckets;
        } else {
            total = solr.response.numFound;
        }

        if (total <= conf.defaultPageLength) {
            return '';
        }

        pageLimit = Math.ceil(total / conf.defaultPageLength);

        const ManualPaginationForm = (props) => {

            const [currentPage, setCurrentPage] = useState(currentPageDefault);

            const updatePaginationInput = (e) => {
                //console.log(e);
                setCurrentPage(e.currentTarget.value);
            }

            return (
                <form onSubmit={props.updateSearchData} id="pagination-form">
                    <label className="hide" htmlFor="pagination-manual-input">Enter page number</label>
                    <input title="Enter page number"  id="pagination-manual-input" type="text" onChange={updatePaginationInput} value={currentPage}></input>
                    <input className="hide" type="submit" value="submit"></input>
                </form>
            )
        }
        

        if (url.searchParams.get('pagination') === null) {
            
            return (
                <div className="pagination-container">
                    <button onClick={props.updateSearchData} className="disabled" id="first-page" title="First page"><span className="sr-only">first page</span><IconAnglesLeft></IconAnglesLeft></button>
                    <button onClick={props.updateSearchData} className="disabled" id="previous-page" title="Previous page"><span className="sr-only">previous page</span> <IconAngleLeft></IconAngleLeft></button>
                    <div className="jump-to-page-container">
                        Page 
                        <ManualPaginationForm updateSearchData={props.updateSearchData} />
                        of <span id="pagination-page-limit">{pageLimit}</span>
                    </div>
                    <button onClick={props.updateSearchData} id="next-page" title="Next page"><span className="sr-only">next page</span><IconAngleRight></IconAngleRight></button>
                    <button onClick={props.updateSearchData} id="last-page" title="Last page"><span className="sr-only">last page</span><IconAnglesRight></IconAnglesRight></button>
                </div>
            )
        } else if (limit >= total) {

            return (
                <div className="pagination-container">
                    <button onClick={props.updateSearchData} id="first-page" title="First page"><span className="sr-only">first page</span><IconAnglesLeft></IconAnglesLeft></button>
                    <button onClick={props.updateSearchData} id="previous-page" title="Previous page"><span className="sr-only">previous page</span><IconAngleLeft></IconAngleLeft></button>
                    <div className="jump-to-page-container">
                        Page 
                        <ManualPaginationForm updateSearchData={props.updateSearchData} />
                        of <span id="pagination-page-limit">{pageLimit}</span>
                    </div>
                    <button onClick={props.updateSearchData} className="disabled"  id="next-page" title="Next page"><span className="sr-only">next page</span><IconAngleRight></IconAngleRight></button>
                    <button onClick={props.updateSearchData} className="disabled"  id="last-page" title="Last page"><span className="sr-only">last page</span><IconAnglesRight></IconAnglesRight></button>
                </div>
            )
        } else {
            return (
                <div className="pagination-container">
                    <button onClick={props.updateSearchData} id="first-page" title="First page"><span className="sr-only">first page</span><IconAnglesLeft></IconAnglesLeft></button>
                    <button onClick={props.updateSearchData} id="previous-page" title="Previous page"><span className="sr-only">previous page</span><IconAngleLeft></IconAngleLeft></button>
                    <div className="jump-to-page-container">
                        Page 
                        <ManualPaginationForm updateSearchData={props.updateSearchData} />
                        of <span id="pagination-page-limit">{pageLimit}</span>
                    </div>
                    <button onClick={props.updateSearchData} id="next-page" title="Next page"><span className="sr-only">next page</span><IconAngleRight></IconAngleRight></button>
                    <button onClick={props.updateSearchData} id="last-page" title="Last page"><span className="sr-only">last page</span><IconAnglesRight></IconAnglesRight></button>
                </div>
            )
        }
        

    } catch { return ''}
    
}

export default Pagination;