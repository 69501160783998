import SearchResult from "./searchResult";

function SearchResults(props) {

  const center = {textAlign: "center"};

  if (props.solrData) {
  
    try {

      if (typeof props.solrData.grouped !== 'undefined' ) {
        if (props.solrData.grouped.ss_grouping.matches === 0) {
          return (<div className="no-result-container">Aw snap! No Results - try checking your active filters or search terms. Or <a href="https://www.bklynlibrary.org/contact">contact us</a> with a suggestion.</div>)
        } 
        return (props.solrData.grouped.ss_grouping.groups.map((item) => <SearchResult setSelectedBibId={props.setSelectedBibId} key={item.groupValue} updateSearchData={props.updateSearchData} data={item} />))
      } else if (typeof props.solrData.response !== 'undefined') {
        if (props.solrData.response.docs.length === 0) {
          return (
          <div className="no-result-container">Aw snap! No Results - try checking your active filters or search terms. Or <a href="https://www.bklynlibrary.org/contact">contact us</a> with a suggestion.</div>
          )
        }
        return (props.solrData.response.docs.map((item) => <SearchResult setSelectedBibId={props.setSelectedBibId} key={item.id} updateSearchData={props.updateSearchData} data={item} />))
      }
      return (<div className="no-result-container" style={center}>Beep Boop. Gathering data... &nbsp; <i className="fas fa-robot"></i></div>)
    } catch (err) {
      return (<div className="no-result-container" style={center}>Beep Boop. Gathering data... &nbsp; <i className="fas fa-robot"></i></div>)
    }
  } else {
    return (<div className="no-result-container" style={center}>Beep Boop. Gathering data... &nbsp; <i className="fas fa-robot"></i></div>)
  
  }
}

export default SearchResults;