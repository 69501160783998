import useFetch from "../../hooks/useFetch";
import configs from "../../functions/configs";
import ContentCarousel from "../shared/contentCarousel"

const YouMayAlsoLike = (props) => {

  const conf = configs();

  

  try {
    const [recs] = useFetch(conf.recommendAPI + props.data.id);

    
    if (props.data.material_type === 'Archival Collection') {
      return '';
    }
      
    
    if (recs.response.docs < 5) {
      return '';
    }
  
    return (
      <div className="you-may-also-like-container">
        <h3>You May Also Like</h3>
        <ContentCarousel data={recs.response.docs}></ContentCarousel>
      </div>
    )

  } catch {}


return ''

};

export default YouMayAlsoLike;
