import ThinX from "../icons/thinX";

const ActiveFilters = (props) => {

  const ActiveFilter = (props) => {

    let filterLabel = '';
  
    try {
  
      if (props.data.value === '') {
        filterLabel = props.data.key;
      } else {
        filterLabel = props.data.key + ": " + props.data.value;
      }
  
    } catch {}
    
    return (
      <button 
        onClick={props.filterProps.functions.updateSearchData} 
        urlkey={props.data.URLKey} urlvalue={props.data.URLValue} 
        filtertype='active-filter' 
        className="active-filter">
        {filterLabel} <ThinX width="12"></ThinX>
      </button>
    )
    
  };
  
  return (
      props.filters.map((item) =><ActiveFilter key={item.id} data={item} filterProps={props.filterProps}></ActiveFilter>)
  )
  
}

export default ActiveFilters;
