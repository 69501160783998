import IconUser from "../icons/iconUser";
import ThinX from "../icons/thinX";
import { useContext } from "react";
import { UserContext } from "../../";
import capitalizeFirstLetter from "../../functions/capitalizeFirstLetter";
import sideToggleOverlay from "../../functions/sideToggleOverlay";
import IconArrowRightArrowLeft from "../icons/iconArrowRightArrowLeft";
import IconRightFromBracket from "../icons/iconRightFromBracket";
import clearCache from "../../functions/clearCache";
import IconPlus from "../icons/iconPlus";
import IconX from "../icons/iconX";
import getFirstName from "../../functions/getFirstName";
import getInitials from "../../functions/getInitials";

let boundListener = false;

const AccountSideDrawer = (props) => {

    const closeOverlay = (e) => {
        sideToggleOverlay('#my-account-side-drawer', e);
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            sideToggleOverlay('#my-account-side-drawer', e);
        }
    }

    const user = useContext(UserContext);

    let firstName = '';
    let initials = '';
    let libraryCardNum = '';

    try {
        firstName = getFirstName(user.data.session.bpl_catalog.name);
        initials = getInitials(user.data.session.bpl_catalog.name);
        libraryCardNum = user.data.session.bpl_catalog.barcode;
    } catch {}

    if (firstName !== '') {
        const loginLogoutButton = document.querySelector('.login-logout-button');
        let firstNameTruncated = firstName;
        if (firstNameTruncated.length > 5) {
            firstNameTruncated = firstNameTruncated.substring(0, 5) + '..';
        }
        loginLogoutButton.innerHTML = firstNameTruncated + ' <svg style="position:relative;top:0px;left:5px;" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>';
        if (!boundListener) {
            boundListener = true;
            loginLogoutButton.addEventListener('click', (e) => {
                e.preventDefault();
                sideToggleOverlay('#my-account-side-drawer', e);
            });
        }
    } else {
        return '';
    }

    let hasMultipleAccounts = false;

    try {
        if (user.data.session.bpl_catalog.accounts.length > 0) {
            hasMultipleAccounts = true;
        }
    } catch {}
    
    const launchAddAccount = (e) => {
        e.preventDefault();
        sideToggleOverlay('#my-account-side-drawer', e);
        setTimeout(()=>{
            sideToggleOverlay('#add-account-side-drawer', e);
        }, 200);
    }

    const launchSwitchAccount = (e) => {
        e.preventDefault();
        sideToggleOverlay('#my-account-side-drawer', e);
        setTimeout(()=>{
            sideToggleOverlay('#switch-account-side-drawer', e);
        }, 200);
    }

    const SwitchAccountAddAccount = (props) => {
        
        if (hasMultipleAccounts) {
            return (
                <div>
                    <div className="switch-add-account-link-container"><a onClick={launchSwitchAccount} href="#"><IconArrowRightArrowLeft /> Switch Accounts</a></div>
                    <div className="switch-add-account-link-container"><a onClick={launchAddAccount} href="#"><IconPlus /> Add Account</a></div>
                </div>
                
            )
        }

        return (
            <div className="switch-add-account-link-container"><a onClick={launchAddAccount} href="#"><IconPlus /> Add Account</a></div>
        )
    }

    return (
        <div onClick={containerClick} id="my-account-side-drawer" className='overlay-container side-slide'>
            <div className='overlay-body side-slide-right'>
                <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='16'></ThinX></button>
                <div className="name-container">
                    <div className="initials-circle-profile bold eighteen-point">{initials}</div>
                    <div>
                        <div className="bold main-color eighteen-point">{firstName}</div>
                        <div className="main-color">{libraryCardNum}</div>
                    </div>
                </div>
                <hr></hr>
                <div className="my-account-link-container"><a href="/my-account"><IconUser /> My Account</a></div>
                <SwitchAccountAddAccount />
                <hr></hr>
                <div className="logout-link-container"><a onClick={clearCache} href="/logout"><IconRightFromBracket /> Logout</a></div>
            </div>
        </div>
    )
}

export default AccountSideDrawer;