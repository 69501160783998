// sorts bib group by format type and popularity
const sortBibGroup = (group) => {
    try {
        const sortedGroup = group.sort((a, b) => {
            if (a.material_type === 'Book' && b.material_type !== 'Book') {
                return -1;
            } else if (a.material_type !== 'Book' && b.material_type === 'Book') {
                return 1;
            } else {
                a.copyCount = 0;
                b.copyCount = 0;
                try {
                    if (a.eprovider === 'OverDrive, Inc.') {
                        a.copyCount = a.digital_copies_owned; 
                    } else {
                        a.copyCount = a.is_copies_non_deleted;
                    }
                    if (b.eprovider === 'OverDrive, Inc.') {
                        b.copyCount = b.digital_copies_owned; 
                    } else {
                        b.copyCount = b.is_copies_non_deleted;
                    }
                } catch {}
                
                if (a.copyCount > b.copyCount) {
                    return -1;
                } else if (a.copyCount < b.copyCount) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
    
        return sortedGroup;
    } catch (e) { console.log(e); return [] }
    
}

export default sortBibGroup;