import { useEffect, useState } from "react";
import configs from "../../functions/configs";
import postData from "../../functions/postData";
import fallbackImageHandler from "../../functions/fallbackImageHandler";

let grabbedData = false;
const RelatedBooklists = (props) => {

  const conf = configs();

  const url = new URL(window.location);

  let group_id = props.data.ss_grouping;

  const [booklists, setBooklists] = useState({});

  useEffect(()=> {
    if (typeof group_id === 'string') {
      if (!booklists.length) {
        if (grabbedData === false) {
          postData(conf.baseAPI + '/solr/get-related-booklists', 'grouping=' + group_id).then(res => {
            console.log(res);
            grabbedData = true;
            setBooklists(res.response.docs);
          });
        }
      }
    }
  }, [url]);

  const Booklist = (props) => {
    try {
      let link = '/?booklist=' + props.data.item_id;

      let booklistFirstImage = ''
      try {
        const firstItem = JSON.parse(props.data.sm_field_catalog_item[0]);
        booklistFirstImage  = firstItem.img;
      } catch {}
      
      let booklistSecondImage = '';
      try {
        const secondItem = JSON.parse(props.data.sm_field_catalog_item[1]);
        booklistSecondImage = secondItem.img;
      } catch {}
      
      let booklistThirdImage = '';
      try {
        const thirdItem = JSON.parse(props.data.sm_field_catalog_item[2]);
        booklistThirdImage = JSON.parse(thirdItem.img);
      } catch {}

      let summary = '';
      try {
        summary = props.data.ts_field_list_description.substring(0,200);
        if (summary.length === 200) {
          summary += '..';
        }
      } catch {}

      return (
        <a href={link} className="related-booklist">
          <div className="flex-small-gap">
            <div className="related-booklist-image-container">
              <img onError={fallbackImageHandler} alt="list cover art 1" className="booklist-second-image" src={booklistSecondImage}></img>
              <img onError={fallbackImageHandler} alt="list cover art 2" className="booklist-first-image" src={booklistFirstImage}></img>  
            </div>
            <div>
              <div className="dark-gray bold">STAFF SELECTIONS</div>
              <div className="related-booklist-title no-color">{props.data.ts_title}</div>
              <div className="margin-top-10 no-color related-booklist-summary">{summary}</div>
            </div>
          </div>
        </a>
      )
    } catch (err) {
      console.log(err);
    }
  }

  const Booklists = () => {
    try {
      return booklists.map(item => <Booklist key={item.id} data={item} />);
    } catch {}
  }

  try {
    if (booklists.length > 0) {
      return (
        <div className="order-information">
          <h3>Related Booklists</h3>
          <div className="related-booklists-container">
          <Booklists />
          </div>
        </div>
      )
    }
  } catch {}
};

export default RelatedBooklists;