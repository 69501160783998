const toggleFiltersOverlay = (event) => {

    const fixedFilterHeader = document.querySelector('.fixed-filters-header');
    const fixedFilterHeaderHR = document.getElementById('fixed-filter-header-hr');
    const fixedFilterFooterHR = document.querySelector('.fixed-filters-footer hr');
    const fixedFilterFooter = document.querySelector('.fixed-filters-footer');

    if (!document.querySelector('.filter-container').classList.contains('overlay-mode')) {
        
        document.querySelector('.filter-container').classList.add('overlay-mode');
        document.querySelector('.filter-container').style.height = window.innerHeight + 'px';
        fixedFilterFooter.style.position = 'fixed';
        document.querySelector('.filters-main-content').style.paddingTop = (fixedFilterHeader.clientHeight + 20) + 'px';

        setTimeout(() => {
            document.body.style.overflow = 'hidden';
            fixedFilterHeader.style.position = 'fixed';
            const scrollbarWidth = window.innerWidth - document.getElementsByClassName('filters')[0].clientWidth;
            const filtersMainContent = document.getElementsByClassName('filters-main-content')[0];
            const filtersMainContentStyles = window.getComputedStyle(filtersMainContent);
            const filtersMainContentPaddingLeft = filtersMainContentStyles.getPropertyValue('padding-left');
            const paddingLeftNum = parseInt(filtersMainContentPaddingLeft.replace('px', ''));
            fixedFilterHeaderHR.style.marginRight = (scrollbarWidth + paddingLeftNum) + 'px';
            fixedFilterFooterHR.style.width = (document.getElementsByClassName('filters-main-content')[0].clientWidth - (paddingLeftNum  * 2)) + 'px';
            document.querySelector('.filters button').focus();
        }, 100);
        
    } else {
        
        document.querySelector('.filter-container').classList.remove('overlay-mode');
        document.body.style.overflowY = 'scroll';
        fixedFilterFooter.style.position = 'unset';
        document.querySelector('.filter-container').style.height = '0px';
        document.querySelector('.fixed-filters-header').style.position = 'unset';
        document.querySelector('.filters-main-content').style.paddingTop = '0px';

        setTimeout(() => {
            document.querySelector('.searchbar-container button[type="submit"]').style.left = (document.querySelector('.searchbar-container input[type="text"]').clientWidth - 40) + 'px';
            const filtersMainContent = document.querySelector('.filters-main-content');
            const filtersMainContentStyles = window.getComputedStyle(filtersMainContent);
            const filtersMainContentPaddingLeft = filtersMainContentStyles.getPropertyValue('padding-left');
            const paddingLeftNum = parseInt(filtersMainContentPaddingLeft.replace('px', ''));
            fixedFilterHeaderHR.style.marginRight = (paddingLeftNum) + 'px';
            document.querySelector('.launch-filters-btn').focus();
        }, 0);
    }

}

export default toggleFiltersOverlay;