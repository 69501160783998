
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";
import IconTime from "../../icons/iconTime";
import IconTruck from "../../icons/iconTruck";
import IconSparkles from '../../icons/iconSparkles';
import { UserContext } from "../../..";
import { useContext } from "react";
import configs from "../../../functions/configs";

const CatalogAvailabilityFilters = (props) => { 

  const url = new URL(window.location.href);

  const user = useContext(UserContext);

  const conf = configs();

  let onOrderActive = 'no';
  if (url.searchParams.get('onorder') === 'true') {
      onOrderActive = 'yes';
  }

  let availableNowActive = 'no';
  if (url.searchParams.get('availablenow') === 'true') {
      availableNowActive = 'yes';
  }

  let rebalancingActive = '';
  if (url.searchParams.get('rebalancing') === 'true') {
    rebalancingActive = 'yes';
  }

  let rebalancingClasses = 'filter-square-btn hide';

  try {
    if (conf.rebalancingPtypes.indexOf(user.data.session.bpl_catalog.ptype) > -1) {
      rebalancingClasses = 'filter-square-btn';
    }
  } catch {}

  return (
      <div className={props.catalogFilterGrouping}>
        <a href="#" className="toggle-filterset availability" onClick={toggleFilterset} aria-label="expand filter set"><h4>Availability</h4><ThickCaretDown flip width="15"></ThickCaretDown></a>
        <div className="availability-filters filterset open">
          <button onClick={props.filterProps.functions.updateSearchData} activated={availableNowActive} filtertype="catalog-available-now" className="filter-square-btn"><IconTime></IconTime>Available<br/>Now</button>
          <button onClick={props.filterProps.functions.updateSearchData} activated={onOrderActive} filtertype="catalog-on-order" className="filter-square-btn"><IconTruck></IconTruck>On<br/>Order</button>
          <button onClick={props.filterProps.functions.updateSearchData} activated={rebalancingActive} filtertype="catalog-rebalancing" className={rebalancingClasses}><IconSparkles />Rebalancing<br/>Items</button>
        </div>
        <hr></hr>
      </div>
  )
}

export default CatalogAvailabilityFilters;