import { SolrContext } from "../../../searchPage";
import React from "react";
import { useState } from "react";
import ThickCaretDown from "../../icons/thickCaretDown";
import toggleFilterset from "../../../functions/toggleFilterset";
import FilterSearchbar from "../filterSearchbar";
import getData from "../../../functions/getData";
import configs from "../../../functions/configs";

let gotFullFacetData = false;

const CatalogTagFilters = (props) => { 

    const solrData = React.useContext(SolrContext);

    const [hideExtra, updateHideExtra] = useState(true);

    const url = new URL(window.location.href);

    const updateSearchHandler = props.filterProps.functions.updateSearchData;

    let catalogFilterGrouping = props.catalogFilterGrouping;

    let count = 0;

    let filterLimit = 14;

    let sc = 0; // see counts
    
    try {
        sc = parseInt(localStorage.getItem("seeCountsVisible"));
    } catch {}

    const Pill = (props) => {

        count++;
        try {
            let isActivated = 'no';
            if (props.data.val !== '') {
            // active filter
            let formatFiltersURL = url.searchParams.get('subjects');
            if (formatFiltersURL !== null) {
                const formatFilters = formatFiltersURL.split('||');
                if (formatFilters.indexOf(props.data.val) > -1) {
                isActivated = 'yes';
                }
            }
            let classes = 'filter-pill-button';
            if (count > filterLimit && hideExtra) {
                classes = 'filter-pill-button hide';
            }

            let countPretty = props.data.count.toLocaleString();
            if(sc === 1) {
                return(
                    <a onClick={updateSearchHandler} filtertype='catalog-tag' filterval={props.data.val} activated={isActivated} className={classes} href="#">{props.data.val} <span className="filter-toggle-counts">{countPretty}</span></a>
                )
            }
            return (
                <a onClick={updateSearchHandler} filtertype='catalog-tag' filterval={props.data.val} activated={isActivated} className={classes} href="#">{props.data.val} <span className="filter-toggle-counts hide">{countPretty}</span></a>
            )
            }
        } catch (err) {console.log(err)}
    }

    const Tags = () => {
        try {
            return solrData.facets.subjects.buckets.map((item) => <Pill key={item.val} data={item}></Pill>);
        } catch {}
    }

    const showMore = (e) => {
        e.preventDefault();
        if (hideExtra) {
            updateHideExtra(false);
        } else {
            updateHideExtra(true);
            let height = window.scrollY + document.querySelector('#catalog-tag-filters').getBoundingClientRect().top - 50;
            window.scrollTo({top: height, behavior: 'smooth'});
        }
    }

    const ShowMoreLink = () => {
        if (count > filterLimit) {
            if (hideExtra) {
                return (<a href="#" className="show-more-filters-link" onClick={showMore}><span className="show-more-text-span">Show more tags</span> <ThickCaretDown width='10px'></ThickCaretDown></a>)
            }
            return (<a href="#" className="show-more-filters-link" onClick={showMore}><span className="show-more-text-span">Show fewer tags</span> <ThickCaretDown width='10px' flip='flip'></ThickCaretDown></a>)
        }
    }


    const [autocompleteData, setAutocompleteData] = useState([]);

    try {
      if (solrData.facets.subjects.buckets.length > 0) {

        const conf = configs();
        try {
            if (gotFullFacetData === false) {
                gotFullFacetData = true;
                getData(conf.baseAPI + '/solr/subject-heading-cache.php').then(res => {
                    setAutocompleteData(res);
                });
            }
        } catch {}

        // 

        return (
          <div id="catalog-tag-filters" className={catalogFilterGrouping}>
              <a href="#" className="toggle-filterset" onClick={toggleFilterset} aria-label="expand filter set"><h4>Tags</h4><ThickCaretDown flip width="15"></ThickCaretDown></a>
              <div className="filterset open">
                <FilterSearchbar filterType="catalog-tag" inputid="catalog-tag-quick-lookup" updateSearchHandler={updateSearchHandler} inputData={autocompleteData} />
                <div className="filterset"><Tags></Tags></div>
                <ShowMoreLink></ShowMoreLink>
              </div>
              <hr></hr>
          </div>
        )
      }
    } catch {}

}

export default CatalogTagFilters;