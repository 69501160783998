import { useContext, useState } from "react";
import { UserContext } from "../..";
import toggleOverlay from "../../functions/toggleOverlay";
import configs from "../../functions/configs";
import postData from "../../functions/postData";

const EditContainer = (props) => {

    const user = useContext(UserContext);

    const conf = configs();

    const [editContainerState, setEditContainerState] = useState('');

    // console.log(user);

    const updateEditContainer = (e) => {
        postData(conf.baseAPI + "/user/check-session.php", "").then(data => {
            user.data = data;
            try {
                delete user.data.session.bpl_catalog.pin;
                delete user.data.session.bpl_catalog.overdrive_api_key;
            } catch { }
            setEditContainerState('updated ' + Date.now());
        });
    }

    let preferredName = 'None provided';
    let legalName = '';
    let userName = 'None provided';
    let noticePreference = '';
    let email = 'None provided';
    let phone = 'None provided';
    let address1 = '';
    let address2 = '';
    let defaultPickupLocation = '';


    try {

        if (user.data.session.bpl_catalog.legalname === '') {
            legalName = user.data.session.bpl_catalog.name;
        } else {
            preferredName = user.data.session.bpl_catalog.replace(/.+, /, '');
            legalName = user.data.session.bpl_catalog.legalname
        }

        if (user.data.session.bpl_catalog.altid !== '') {
            userName = user.data.session.bpl_catalog.altid;
        }

        if (user.data.session.bpl_catalog.notice_pref !== '') {
            noticePreference = user.data.session.bpl_catalog.notice_pref;

        }

        if (user.data.session.bpl_catalog.email !== '') {
            email = user.data.session.bpl_catalog.email;
        }

        if (user.data.session.bpl_catalog.phone !== '') {
            phone = user.data.session.bpl_catalog.phone;
        }

        if (user.data.session.bpl_catalog.address !== '') {
            let addressParts = user.data.session.bpl_catalog.address.split('$');
            address1 = addressParts[0];
            address2 = addressParts[1];
        }

        if (user.data.session.bpl_catalog.home_library) {
            defaultPickupLocation = user.data.session.bpl_catalog.home_library;
            for (let i = 0; i < conf.allHomeBranchLocations.length; i++) {
                if (defaultPickupLocation === conf.allHomeBranchLocations[i].code) {
                    defaultPickupLocation = conf.allHomeBranchLocations[i].name;
                }
            }
        }

    } catch { }

    const launchUpdatePin = (e) => {
        toggleOverlay("#update-pin-overlay");
    }


    return (

        <div className="my-account-content-container edit-container">

            <div>
                <button onClick={launchUpdatePin} className="default-blue-button">Change PIN</button>
            </div>


            <br></br>

            <div className="edit-account-tiles-container">


                <div className="white-tile-container">
                    <div className="my-account-edit-header-container">
                        <h3>Names</h3>
                        <button onClick={() => { toggleOverlay("#edit-names-overlay") }} className="my-account-edit-section-btn">Edit username</button>
                    </div>

                    <div className="edit-account-data-container">
                        <div className="edit-account-label">PREFERRED NAME</div>
                        <div>{preferredName}</div>
                    </div>
                    <div className="edit-account-data-container">
                        <div className="edit-account-label">LEGAL NAME</div>
                        <div>{legalName}</div>
                    </div>
                    <div className="edit-account-data-container">
                        <div className="edit-account-label">USERNAME</div>
                        <div>{userName}</div>
                    </div>
                </div>

                <div className="white-tile-container">

                    <div className="my-account-edit-header-container">
                        <h3>Contact</h3>
                        <button onClick={() => { toggleOverlay("#edit-contact-info-overlay") }} className="my-account-edit-section-btn">Edit contact info</button>
                    </div>

                    <div className="edit-account-data-container">
                        <div className="edit-account-label">NOTICE PREFERENCE</div>
                        <div className="capitalize">{noticePreference}</div>
                    </div>

                    <div className="edit-account-data-container">
                        <div className="edit-account-label">EMAIL</div>
                        <div>{email}</div>
                    </div>

                    <div className="edit-account-data-container">
                        <div className="edit-account-label">PHONE</div>
                        <div>{phone}</div>
                    </div>

                    <div className="edit-account-data-container">
                        <div className="edit-account-label">HOME LIBRARY (DEFAULT PICKUP LOCATION)</div>
                        <div>{defaultPickupLocation}</div>
                    </div>
                </div>

                <div className="white-tile-container">
                    <div className="my-account-edit-header-container">
                        <h3>Address</h3>
                        <button onClick={() => { toggleOverlay("#edit-address-overlay") }} className="my-account-edit-section-btn">Edit address</button>
                    </div>
                    <div className="edit-account-data-container">
                        <div className="edit-account-label">MAILING ADDRESS</div>
                        <div>{address1}<br></br>{address2}</div>
                    </div>
                </div>

            </div>


            <button onClick={updateEditContainer} className="hide" id="update-state-my-account-edit-container">update state</button>

        </div>

    )

}

export default EditContainer;