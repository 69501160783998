import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import postData from "../../functions/postData";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import configs from "../../functions/configs";
import detectUserAgent from "../../functions/detectUserAgent";

const UpdatePinOverlay = (props) => {

    const conf = configs();

    const updatePin = (e) => {

        e.preventDefault();
        document.querySelector('.pin-value-no-match-msg').classList.add('hide');
        const newPinVal = document.querySelector('#new-pin-value').value;
        const confirmPinVal = document.querySelector('#confirm-new-pin-value').value;
        document.querySelector('#update-pin-overlay button').focus();

        if (newPinVal !== confirmPinVal) {
            document.querySelector('.pin-value-no-match-msg').classList.remove('hide');
            return '';
        }

        let postString = 'pin=' + encodeURIComponent(newPinVal);
        postString += '&agent=' + detectUserAgent();

        toggleLoadingOverlay();

        postData(conf.baseAPI + "/sierra/sierra-update-pin.php", postString).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            document.querySelector('#update-pin-overlay button.close-overlay').click();
            if (res === null) { // success
                launchSuccessOverlay("Pin updated", "");
            } else if (typeof res.sessionExpired !== 'undefined') {
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            } else {
                launchErrorOverlay(res.message, '');
            }
        });
    }

    const closeOverlay = (e) => {
        toggleOverlay("#update-pin-overlay");
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="update-pin-overlay" className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Update your PIN</h3>
                <div className="margin-bottom-twenty">Your PIN is case sensitive and must be at least 4 digits, and not more than 8 digits. Your PIN can only contain letters, numbers, and select special characters <strong>. - _ : = ; @ / & ! ' , </strong></div>
                <div className="hide pin-value-no-match-msg">New pin value and confirm pin value do not match</div>
                <form onSubmit={updatePin} id="update-pin-form">
                    <div>
                        <div>
                            <label htmlFor="new-pin-value">New PIN</label>
                            <input id="new-pin-value" pattern="^[0-9a-zA-Z.\-_:\=;@\/&!',]{4,8}$" minLength="4" maxLength="8" autoComplete="off" placeholder="" type="password"></input>
                        </div>
                        <br></br>
                        <div>
                            <label htmlFor="confirm-new-pin-value">Confirm New PIN</label>
                            <input id="confirm-new-pin-value" pattern="^[0-9a-zA-Z.\-_:\=;@\/&!',]{4,8}$" minLength="4" maxLength="8" autoComplete="off" placeholder="" type="password"></input>
                        </div>
                        <div>
                            <button type="submit">Update PIN</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
} 

export default UpdatePinOverlay;